import React from 'react';
import cs from 'classnames';
import { bsClasses } from 'shared/bootstrap';


export interface FooterInfo {
  tradingName?: string | null
  privacyUrl: string
  termsOfUseUrl: string
  children?: React.ReactNode
}

interface Props extends FooterInfo {
  className?: string
}


export const Footer = React.forwardRef<HTMLElement, Props>((props, ref) => (
  <footer ref={ref} className={cs("bg-dark text-white py-4 small text-center", props.className)}>
    <div className={bsClasses.container}>
      &copy;&nbsp;{new Date().getFullYear()}&nbsp;The&nbsp;Polygon&nbsp;Group
      {props.tradingName && <> | {props.tradingName} is a trading name of The Polygon Group Pty Ltd.</>}
      <br/>
      <br/>
      ABN 98 066 641 325 | Australian Credit License No. 412456
      <br/>
      <br/>
      <a href={props.privacyUrl} target="_blank" rel="nofollow noreferrer" className="text-white"><u>Privacy</u></a>
      {' | '} 
      <a href={props.termsOfUseUrl} target="_blank" rel="nofollow noreferrer" className="text-white"><u>Website&nbsp;Terms &amp; Conditions&nbsp;of&nbsp;Use</u></a>
      {' | '} 
      Powered&nbsp;by&nbsp;<a href="http://www.datalive.com.au/" target="_blank" rel="nofollow noreferrer" className="text-white"><u>Datalive&nbsp;Software</u></a>
    </div>
    {props.children && <div className={bsClasses.container}>{props.children}</div>}
  </footer>
))
