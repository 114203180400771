import React from 'react';
import { ReactBootstrapModal } from 'shared/components/ReactModal';
import { Menu } from 'shared/steps/steps';
import { MobileMenuItem } from './MobileMenuItem';

interface Props<TSlug extends string> {
  onAfterClose?: () => void
  menu: Menu<TSlug> | null
}

export const MobileMenuModal = <TSlug extends string>(props: Props<TSlug>) => {
  if (!props.menu) return null

  return (
    <ReactBootstrapModal isOpen={true} overlayClassName={"mobile-menu-modal"} onAfterClose={props.onAfterClose}>
      <div className="list-group list-group-flush">
        {props.menu.items.map(m => props.menu && <MobileMenuItem key={m.title} menu={props.menu} item={m} onClick={props.onAfterClose} />)}
      </div>
    </ReactBootstrapModal>
  );
};
