import { useMedia } from 'react-media';
import { useRouteMatch } from 'react-router-dom';
import { GRID_MEDIA_QUERIES } from 'shared/media';


// USED 2x
export interface Step<TSlug extends string> {
  slug: TSlug;
  link: string;
  enabled: boolean;
}
interface AppStepItem<TSlug extends string> extends Step<TSlug> {
  
}


export interface AppStep<TSlug extends string> {
  slug: TSlug;
  enabled?: boolean;
}

// USED 2x
// A menu item for a particular state of an application
export interface AppMenuItem<TSlug extends string> {
  title: string
  active: boolean
  enabled: boolean
  slug: TSlug
  link: string
  number: number
}

export interface Menu<TSlug extends string> {
  items: AppMenuItem<TSlug>[]
  visible: boolean
  currentItem: AppMenuItem<TSlug> | null
}

export interface MenuItem<TSlug extends string, TState> {
  title: string
  // The first slug is what is linked there, therefore there must always be at least one slug
  // The menu item will show as active if any of the slugs are visible
  slugs: [TSlug, ...TSlug[]]
  // If true, the entire menu will not be shown when
  hide?: boolean | ((state: TState) => boolean)
  // If returns true, this menu item will be omitted from this application
  omit?: (state: TState) => boolean
  // enabled?: (app: Steps_ApplicationFragment) => boolean
}

const indexOfOrNull = (array: string[], searchElement: string | null): number | null => {
  if (searchElement) {
    const i = array.indexOf(searchElement);
    if (i >= 0) {
      return i
    }
  }
  return null;
}

interface SetupStepsConfig<TSlug extends string, TState> {
  slugs: TSlug[],
  mkLink: (slug: TSlug, state: TState) => string
  getAppSteps: (state: TState) => AppStep<TSlug>[]
  desktopMenu: MenuItem<TSlug, TState>[],
  mobileMenu: MenuItem<TSlug, TState>[],
}

/*
A factory method that creates everything needed to power steps and menus for a specify application.
Both Rental and tuition call this to create their customised menus.
*/
export const setupSteps = <TSlug extends string, TState>(config: SetupStepsConfig<TSlug, TState>) => {
  const {
    slugs,
    mkLink,
    getAppSteps,
    desktopMenu,
    mobileMenu,
  } = config


  // type ThisMenuItem = MenuItem<TSlug, TState>
  // USED 1x
  // Type guard for a Slug
  const isSlug = (value: string): value is TSlug => (slugs as string[]).includes(value)

  // Functions for each function to see whether this step is enabled (allowed to be visited)
  // The tests will be run for previous slugs


  const getNextAndPrevSteps = (state: TState, slug: string | null) => {
    const appSteps = getAppSteps(state);
    const appSlugs = appSteps.map(s => s.slug);
    const currentSlugIndex = indexOfOrNull(appSlugs, slug);
    const thisStep = currentSlugIndex !== null ? appSteps[currentSlugIndex] || null : null;
    const prevStep = currentSlugIndex !== null ? appSteps[currentSlugIndex - 1] || null : null;
    const nextStep = currentSlugIndex !== null ? appSteps[currentSlugIndex + 1] || null : null;

    const isStepEnabled = (slugToCheck: TSlug) => {
      for (let step of appSteps) {
        if (step.enabled === false) {
          return false;
        }
        if (step.slug === slugToCheck) {
          return true
        }
      }
      return true;
    }

    const items: AppStepItem<TSlug>[] = appSteps.map(s => ({
      ...s, 
      link: mkLink(s.slug, state),
      enabled: isStepEnabled(s.slug),
    }));


    const enabledSteps = items.filter(s => s.enabled);
    const upToStep = enabledSteps[Math.max(0, enabledSteps.length - 1)] || null;
    
    return {
      this: thisStep ? ({...thisStep, link: mkLink(thisStep.slug, state), enabled: isStepEnabled(thisStep.slug)} as AppStepItem<TSlug>) : null,
      prev: prevStep ? ({...prevStep, link: mkLink(prevStep.slug, state), enabled: isStepEnabled(prevStep.slug)} as AppStepItem<TSlug>) : null,
      next: nextStep ? ({...nextStep, link: mkLink(nextStep.slug, state), enabled: isStepEnabled(nextStep.slug)} as AppStepItem<TSlug>) : null,
      upTo: upToStep ? ({...nextStep, link: mkLink(upToStep.slug, state), enabled: isStepEnabled(upToStep.slug)} as AppStepItem<TSlug>) : null,
      items,
      bySlug: items.reduce((a, b) => ({...a, [b.slug]: b}), {} as {[key in TSlug]: AppStepItem<TSlug>}),
    }
  }

  const getMenu = (state: TState, slug: string | null, menuItems: MenuItem<TSlug, TState>[]) => {

    const isMenuItemActive = (step: MenuItem<TSlug, TState>) => {
      if (slug) {
        return (step.slugs as string[]).includes(slug);
      }
      return false;
    }

    const steps = getAppSteps(state);
    
    const isStepEnabled = (slugToCheck: TSlug) => {
      for (let step of steps) {
        if (step.enabled === false) {
          return false;
        }
        if (step.slug === slugToCheck) {
          return true
        }
      }
      return true;
    }

    // Filter down to the list of items
    const _items = menuItems
      .filter(m => !(m.omit && m.omit(state)))
      .filter(m => m.slugs[0]);

    const items: AppMenuItem<TSlug>[] = _items.map((m, i) => ({
        title: m.title, 
        slug: m.slugs[0] as TSlug, 
        active: isMenuItemActive(m),
        enabled: isStepEnabled(m.slugs[0]),
        link: mkLink(m.slugs[0] as TSlug, state),
        number: i + 1,
      }));
    
    const currentItem = menuItems.filter(m => isMenuItemActive(m))[0] || null;
    
    return {
      items: items,
      visible: currentItem ? 
        !(typeof currentItem.hide === "function" ? currentItem.hide(state) : currentItem.hide) 
        : 
        false,
      currentItem: items.filter(m => m.active)[0] || null,
    }
  }

  const useStepSlug = (pathPattern: string) => {
    const m = useRouteMatch<{slug: string, portal: string}>(pathPattern)
    return m?.params.slug || null;
  }

  const useMenu = (state: TState | null, pathPattern: string): Menu<TSlug> | null => {
    const slug = useStepSlug(pathPattern);
    const isDesktop = useMedia({query: GRID_MEDIA_QUERIES.mdGTE});
    if (state === null)
      return null;
    const menuItems = isDesktop ? desktopMenu : mobileMenu;
    return getMenu(state, slug, menuItems);
  }

  return { isSlug, useMenu, getNextAndPrevSteps };

};


export interface UseSteps<TSlug extends string> {
  this: AppStepItem<TSlug> | null
  prev: AppStepItem<TSlug> | null
  next: AppStepItem<TSlug> | null
  upTo: AppStepItem<TSlug> | null
  items: AppStepItem<TSlug>[]
  bySlug: { [key in TSlug]: AppStepItem<TSlug> }
}
