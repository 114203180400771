import { UrqlOptionOverrides } from "shared/urql";
import { acceptTuitionAgreementResolver } from "./resolvers";

export const tuitionUrqlOptions: UrqlOptionOverrides = {
  cacheKeys: {
    TuitionApplication: data => data.__typename, // All tuitation apps are the same app!
    TuitionSchoolState: () => null,
    TuitionApplicationValidation: () => null,
    Tuition: () => null,
  },
  optimistic: {
    acceptTuitionAgreement: acceptTuitionAgreementResolver,
  }
};