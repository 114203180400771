import cs from 'classnames';
import React from 'react';
import { CustomOption } from 'react-bootstrap-typeahead';
import { useValidateAddressMutation } from 'rental/generated.graphql';
import { yupRequiredMsg } from 'shared/yup';
import { TuitionAddressInput } from 'tuition/generated.graphql';
import fieldStyles from './AddressField.module.scss';
import styles from './AddressTypeahead.module.scss';
import { AddressTypeaheadEquifax } from './AddressTypeaheadEquifax';
import { FormikFourParkAddress, useFourPartAddress } from './FourPartAddress';
import { addressJoin } from './segments';


export type AddressComponentType = 'label' | 'typeahead' | 'custom';


interface UseAddressConfig extends React.InputHTMLAttributes<HTMLInputElement> {
  addressName: string;
  placeholderText?: string;
  error?: string;
  onChanged: (address: TuitionAddressInput) => void
  initialValue: string | null
  required?: boolean
}


type StateType = 'label' | 'custom' | 'typeahead'


interface State {
  type: StateType
  value: string // Selected typeahead valid value
  text: string  // Text typed into typeahead
  blurred: boolean
  submitted: boolean
}

function isCustomOption(value: string | CustomOption): value is CustomOption {
  return (value as CustomOption).customOption === true;
}


export const useAddressField = ({placeholderText, ...props}: UseAddressConfig) => {
  const [state, setState] = React.useState<State>({
    type: props.initialValue === null  ? 'typeahead' : 'label',
    value: props.initialValue || '',
    submitted: false,
    blurred: false,
    text: ''
  });

  const handleEnterManuallyBtn = async () => await switchToManual();

  const [, validateAddress] = useValidateAddressMutation();

  const fetchCustomFields = async (hint: string) => {
    if (hint.trim().length > 0) {
      const r = await validateAddress({input:{address: hint}});
      const a = r.data?.validateAddress?.address || null;
      return {
        street: a?.street || '',
        suburb: a?.suburb || '',
        state: a?.state || '',
        postcode: a?.postcode || '',
      }
    }
    return { street: '', suburb: '', state: '', postcode: '' };
  }

  const prefillCustomFields = async (hint: string) => {
    const custom = await fetchCustomFields(hint);
    if (custom) {
      manualAddress.setValues(custom);
    }
  }

  const switchToManual = async (hint?: string) => {
    prefillCustomFields(hint || '');
    setState({...state, type: 'custom', value: '', text: ''})
  }

  const handleTypeaheadInputChange = (text: string, isCustom: boolean) => {
    setState({...state, text});
  };

  const handleTypeaheadSelect = async (value: string | CustomOption) => {
    if (isCustomOption(value)) {
      setState({...state, type: 'custom', value: '', text: ''})
      await prefillCustomFields(value.label)
    } 
    else {
      setState({...state, type: 'label', value, text: ''})
    }
  }

  const handleTypeaheadBlur = async () => {
    if (state.text.trim()) {
      // To catch when the address is valid but not selected
      // on blur validate the address and set to label mode if it's valid
      const r = await validateAddress({input:{address: state.text}});
      const a = r.data?.validateAddress?.address || null;
      if (a?.validated && a.valid && a.fullAddress) {
        const newState = {...state, blurred: true, text: '', value: a.fullAddress, type: 'label' as 'label'};
        console.log('newState', newState)
        setState(newState)
      }
      return;
    }
    setState({...state, blurred: true})
  };

  const handleRestartTypeahead = () => {
    setState({...state, type: 'typeahead', value: '', text: ''})
    manualAddress.setValues(null);
  };

  const enterAutoBtn = (
    <button onClick={handleRestartTypeahead} type="button" className="btn btn-link d-inline-block pt-1 mt-md-2 mb-4">
      Enter {props.addressName} address automatically
    </button>
  );

  const enterManualBtn = (
    <button type="button" className="btn btn-link d-inline-block pt-1 mt-md-2 mb-4" onClick={handleEnterManuallyBtn}>
      Enter {props.addressName} address manually
    </button>
  );

  const manualAddress = useFourPartAddress();

  const typeaheadError = 
    (state.type === 'label' && props.required && state.value.trim().length === 0 && yupRequiredMsg)
    || (state.type === 'typeahead' && props.required && state.text.trim().length === 0 && yupRequiredMsg)
    || (state.type === 'typeahead' && <>Must select a suggested address or <a onClick={e => {switchToManual(); e.preventDefault();}} className="text-danger" href="#manual" style={{textDecoration:'underline'}}>enter address manually</a></>)
    || null;
  
  return {
    state,
    showErrors: (state.submitted || state.blurred) && typeaheadError !== null,
    error: typeaheadError,
    enterManualBtn,
    enterAutoBtn,
    manualAddress,
    placeholderText,
    resetTypeahead: handleRestartTypeahead,
    handleTypeaheadInputChange,
    handleTypeaheadBlur,
    handleTypeaheadSelect,
    trySubmit: async (): Promise<TuitionAddressInput | null> => {

      if (state.type === 'custom') {
        const values = await manualAddress.trySubmit()
        if (values) {
          return {
            fullAddress: addressJoin(values),
            manuallyEnteredValues: values,
          }
        }
      }

      if (state.type === 'label' && state.value.trim()) {
        return {
          fullAddress: state.value,
          manuallyEnteredValues: null,
        }
      }
      
      setState({...state, submitted: true});

      return null;
    }
  }
}


interface AddressComponentProps  {
  context: ReturnType<typeof useAddressField>
  error?: string;
}


export const AddressComponent = (props: AddressComponentProps) => {
  const context = props.context;
  const state = props.context.state;

  if (state.type === 'custom') return (
    <FormikFourParkAddress formik={context.manualAddress.formik}>{context.enterAutoBtn}</FormikFourParkAddress>
  )

  const coaleasedError = props.error || context.error || null;
  
  return (
    <div className="form-row">
      <div className="col-md-8 col-lg-9">

        {state.type === 'label' &&
          <div className={cs("form-control d-flex", fieldStyles.AddressLabel, (context.showErrors || !!coaleasedError) && "is-invalid")}>
            <span className={styles.simpleAddressText}>{state.value}</span>
            <button type="button" className={cs(styles.simpleAddressButton, "btn btn-sm btn-link")} onClick={context.resetTypeahead}>Change</button>
          </div>
        }

        {state.type === 'typeahead' &&  
          <div className="form-group mb-0">
            <AddressTypeaheadEquifax
              onInputChange={context.handleTypeaheadInputChange}
              onBlur={context.handleTypeaheadBlur}
              onSelect={context.handleTypeaheadSelect}
              placeholder={context.placeholderText}
              isInvalid={context.showErrors && !!coaleasedError}
            />
          </div>
        }
        {context.showErrors && coaleasedError && (
          <div className="text-danger small mt-1 mb-3">{coaleasedError}</div>
        )}

      </div>
      <div className="col-md-4 col-lg-3 text-md-right">
        {context.enterManualBtn}
      </div>
    </div>
)};
