import React from 'react';
import { NabCrnFragment } from 'shared/generated.graphql';
import { useElementId } from 'shared/utils/elementIds';


interface Card {
  cardNumber: string
  expiryMonth: string
  expiryYear: string
}
interface UseDirectPostConfig {
  // backendOrigin: string
  visible?: boolean
}


export const useDirectPostForm = (config: UseDirectPostConfig) => {
  // Have to use Ref's because we need to update the DOM then submit in an event handler,
  // which would be hacky or harder to debug to try and use regular react props.
  const formRef = React.useRef<HTMLFormElement>(null)
  const actionRef = React.useRef<HTMLInputElement>(null)
  const merchantRef = React.useRef<HTMLInputElement>(null)
  const timestampRef = React.useRef<HTMLInputElement>(null)
  const crnRef = React.useRef<HTMLInputElement>(null)
  const fingerprintRef = React.useRef<HTMLInputElement>(null)
  const resultUrlRef = React.useRef<HTMLInputElement>(null)
  const cardNumberRef = React.useRef<HTMLInputElement>(null)
  const expiryMonthRef = React.useRef<HTMLInputElement>(null)
  const expiryYearRef = React.useRef<HTMLInputElement>(null)

  const update = (formAction: string, values: NabCrnFragment & Card) => {
    if (formRef.current)        formRef.current.action =          formAction;
    if (actionRef.current)      actionRef.current.value =         values.action;
    if (merchantRef.current)    merchantRef.current.value =       values.merchant;
    if (timestampRef.current)   timestampRef.current.value =      values.timestamp;
    if (crnRef.current)         crnRef.current.value =            values.crn;
    if (fingerprintRef.current) fingerprintRef.current.value =    values.fingerprint;
    if (resultUrlRef.current)   resultUrlRef.current.value =      values.resultUrl;
    if (cardNumberRef.current)  cardNumberRef.current.value =     values.cardNumber.replace(/[^\d]/g, "");
    if (expiryMonthRef.current) expiryMonthRef.current.value =    values.expiryMonth;
    if (expiryYearRef.current)  expiryYearRef.current.value =     values.expiryYear;
  }

  const submit = () => {
    formRef.current?.submit()
  }

  const updateAndSubmit = (formAction: string, values: NabCrnFragment & Card) => {
    update(formAction, values)
    submit()
  }

  const elId = useElementId('nab-direct-post-form-')

  const element = (
    <form method="POST" id={elId} ref={formRef} style={{display: config.visible ? 'block' : 'none'}}>
      <input type="text" name="EPS_TYPE" value="CRN" readOnly />
      <input type="text" name="EPS_ACTION" ref={actionRef} />
      <input type="text" name="EPS_MERCHANT" ref={merchantRef} />
      <input type="text" name="EPS_TIMESTAMP" ref={timestampRef} />
      <input type="text" name="EPS_CRN"  ref={crnRef} />
      <input type="text" name="EPS_FINGERPRINT" ref={fingerprintRef} />
      <input type="text" name="EPS_RESULTURL" ref={resultUrlRef} />
      <input type="text" name="EPS_CARDNUMBER" ref={cardNumberRef} />
      <input type="text" name="EPS_EXPIRYMONTH" ref={expiryMonthRef} />
      <input type="text" name="EPS_EXPIRYYEAR" ref={expiryYearRef} />
      <input type="submit"/>
    </form>
  )

  return { element, update, submit, updateAndSubmit }
}
