import React from 'react';
import { Helmet } from 'react-helmet-async';


interface Props {
  children: string
}


export const TuitionPageTitleHelmet = (props: Props) => {
  return (
    <Helmet>
      <title>Tuition Application - {props.children}</title>
    </Helmet>
  )
}