import * as Yup from 'yup';


export const yupRequiredMsg = 'Required';

export const yupDateMsg = 'Must be a valid date';

export const yupPhoneNumber = Yup
  .string()
  .matches(
    /^ *(\(?[ -]*\d[ -]*\d[ -]*\)?|\+614)[ -]*\d[ -]*\d[ -]*\d[ -]*\d[ -]*\d[ -]*\d[ -]*\d[ -]*\d[ -]*$/g, 
    'Enter a valid Australian phone number. Mobiles must start with 04 or +614. Land lines must include area code.'
);

export const yupMobilePhoneNumber = Yup
  .string()
  .matches(
    /^\s*(\(?04\)?|\+61[ -]*4)[ -]*\d[ -]*\d[ -]*\d[ -]*\d[ -]*\d[ -]*\d[ -]*\d[ -]*\d[ -]*$/g,
    'Enter a valid Australian mobile phone number. Numbers must start with 04 or +614.'
  );