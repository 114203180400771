import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { MenuItemIcon } from './MenuItemIcon';
import { Menu } from 'shared/steps/steps';

interface Props<TSlug extends string> {
  menu: Menu<TSlug>
}

export const PillsMenu = <TSlug extends string>({menu}: Props<TSlug>) => {
  if (!menu.visible) return null;

  return (
    <ul className="nav nav-pills nav-fill">
      {menu.items.map((m, idx) => (
        <li className="nav-item" key={m.link}>
          <MenuItemIcon item={m} menu={menu} />
          <Link 
            key={m.title} 
            to={m.enabled ? m.link : menu.currentItem?.link || ''} 
            className={cs("nav-link", !m.enabled && 'disabled')} 
            aria-disabled={!m.enabled}
          >
            {m.title}
          </Link>
        </li>
      ))}
    </ul>
  )
};
