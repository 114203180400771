
import React from 'react';
import { TuitionApplicationFragment } from './generated.graphql';


export interface AppContextData {
  application: TuitionApplicationFragment | null
}

export const AppContext = React.createContext<AppContextData>({
  application: null,
});


export const useAppContext = () => {
  const value = React.useContext(AppContext);
  return value
}

