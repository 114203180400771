import cs from 'classnames';
import React, { Fragment } from 'react';
import { AsyncTypeahead, TypeaheadMenu, CustomOption } from 'react-bootstrap-typeahead';
import "react-bootstrap-typeahead/css/Typeahead.css";
import { newElementId } from '../utils/elementIds';
import styles from './AddressTypeahead.module.scss';

export interface ExternalProps {
  placeholder?: string;
  initialValue?: string;
  name?: string;
  debug?: boolean;
  className?: string;
  isInvalid?: boolean;
  onInputChange?: (value: string, isCustom: boolean) => void;
  onSelect?: (value: string | CustomOption) => void;
  onBlur?: (e: Event) => void;
}

interface Props extends ExternalProps {
  fetchSuggestions: (query: string) => Promise<string[]>;
}

interface State {
  id: string;
  isLoading: boolean;
  isCustom: boolean;
  options: string[];
  selected: string;
  value: string;
  autoFocus: boolean;   // To focus the element after "change" is clicked.
}

export class AddressTypeahead extends React.Component<Props, State> {
  
  state: State;

  constructor(props: Props) {
    super(props);
    this.state = {
      id: newElementId('typeahead'),
      isLoading: false,
      isCustom: false,
      options: props.initialValue ? [props.initialValue] : [],
      selected: props.initialValue || '',
      value: props.initialValue || '',
      autoFocus: false
    };
  }
  
  render() {
    return (
      <Fragment>
        {this.props.debug && 
          <div style={{background: '#fffaf0', padding: '10px 10px 1px 10px', margin: '0 0 20px 0'}}>
            <p className="small">Debug:</p>
            <p>Selected: {this.state.selected}</p>
            <p>Value: {this.state.value}</p>
            <p>Is Custom: {this.state.isCustom ? 'CUSTOM': 'SUGGESTION'}</p>
          </div>
        }
        {this.state.selected ?
          <div className="form-control d-flex" style={{backgroundColor: '#fafafa'}}>
            <span className={styles.simpleAddressText}>{this.state.selected}</span>
            <button type="button" className={cs(styles.simpleAddressButton, "btn btn-sm btn-link")} onClick={this._handleClear}>Change</button>
          </div> :
          <AsyncTypeahead<string>
            id={this.state.id}
            options={this.state.options}
            placeholder={this.props.placeholder}
            className={this.props.className}
            isInvalid={this.props.isInvalid}
            minLength={3}
            isLoading={this.state.isLoading}
            onSearch={this._handleSearch}
            onChange={this._handleChange}
            onInputChange={this._handleInputChange}
            onBlur={this.props.onBlur}
            allowNew
            flip
            autoFocus={this.state.autoFocus}
            filterBy={(() => true)}
            renderMenu={(results, menuProps) => (
              <TypeaheadMenu
                {...menuProps}
                newSelectionPrefix="I'll enter my address manually: "
                maxHeight="420px"
                options={results as string[]} 
                text={menuProps.text || this.state.value}  // Hack to avoid console prop-types warning
              />
            )}
          />
        }
      </Fragment>
    );
  }

  _handleSearch = (query: string) => {
    this.setState({...this.state, isLoading: true});
    this.props.fetchSuggestions(query).then(options => {
      this.setState({
        ...this.state, 
        isLoading: false, 
        options
      });
    }).catch(err => {
      console.warn('typeahead search error:', err);
    });
  };

  _handleChange = (selected: (string | CustomOption)[]) => {
    // The current type definitions are wrong and I gave up trying to fix them
    // If a custom option is selected, the value will be a CustomOption, not a string.
    // Therefore, I am force casting this to be able to handle that case.
    // TODO: Add a pull-request for the type definition, or wait for somebody else to fix it
    const value = selected[0] || "";
    if (typeof value === "string") {
      this.setState({...this.state, selected: value, value: value, isCustom: false});
      if (this.props.onInputChange) this.props.onInputChange(value, false);
    }
    else {
      this.setState({...this.state, selected: value.label, value: value.label, isCustom: true});
      if (this.props.onInputChange) {
        this.props.onInputChange(value.label, true);
      }
    }
    if (this.props.onSelect) this.props.onSelect(value);
  };

  _handleInputChange = (input: string, e: Event) => {
    this.setState({...this.state, value: input});
    if (this.props.onInputChange) this.props.onInputChange(input, false);
  }

  _handleClear = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (event) event.preventDefault();
    this.setState({
      ...this.state, 
      value: '', 
      selected: '', 
      autoFocus: true  // To focus the input when it it re-rendered.
    });
    if (this.props.onInputChange) this.props.onInputChange("", false);
    if (this.props.onSelect) this.props.onSelect("");
  }

}
