import React from 'react';
import { Link } from 'react-router-dom';
import { PageContainer } from 'shared/components/PageContainer';


interface Props {
  to?: string
}

export const StepNotAccessible = ({to}: Props) => (
  <PageContainer>
    <div className="py-5 text-center">
      <div className="pb-3">Looks like we have got a bit lost.</div>
      {to &&
        <div className="">
          <Link to={to} className="btn btn-primary">Jump to current step</Link>
        </div>
      }
    </div>
  </PageContainer>
);


