import { Form, Formik } from 'formik';
import React from 'react';
import { TextAreaGroup } from 'shared/formik/TextAreaGroup';
import { MutationErrors } from 'shared/components/MutationErrors';
import { PageContainer } from 'shared/components/PageContainer';
import { SubmitActions } from 'shared/components/SubmitActions';
import { PageHeading } from 'shared/components/typography';
import { getSubmitActionsProps } from 'shared/forms/helpers';
import { AdditionalInfoFragment, TuitionApplicationFragment, TuitionErrorField, useUpdateApplicationMutation } from 'tuition/generated.graphql';
import { TuitionStepProps } from 'tuition/steps';
import { TuitionPageTitleHelmet } from 'tuition/components/PageTitleHelmet';
import { createErrorFilter, labels } from '../fields';


interface Props extends TuitionStepProps {
  application: TuitionApplicationFragment
}


export const additionalErrorFilter = createErrorFilter([
  TuitionErrorField.Notes,
], []);

export const AdditionalInfo = (props: Props) => {
  const [, executeMutation] = useUpdateApplicationMutation();

  return (
    <PageContainer>
      <TuitionPageTitleHelmet>Anything else?</TuitionPageTitleHelmet>
      <PageHeading>Anything else we need to know?</PageHeading>
      <Formik<AdditionalInfoFragment>
        initialValues={{
          notes: props.application.notes,
        }}
        onSubmit={async (values, formikHelpers) => {

          const r = await executeMutation({input: {notes:values.notes}});

          if (r.data?.updateTuitionApplication?.application) {
            props.onSubmitSuccessful();
          }
          else {
            formikHelpers.setStatus(r.data?.updateTuitionApplication?.errors || null)
            props.onSubmitFailure();
          }
        }}
      >
        {formik => 
          <Form noValidate>
            {formik.status && <MutationErrors errors={formik.status}/>}
            <div className="my-4">
              <div className="form-row">
                <TextAreaGroup 
                  label={labels.notes}
                  placeholder={`${labels.notes}.  \n\nFor example: My child is unsure which instrument she prefers.`}
                  name="notes" 
                  className="col-sm" 
                  rows={8} 
                />
              </div>
            </div>

            <SubmitActions {...getSubmitActionsProps(formik, props)}/>

          </Form>
        }
      </Formik>
    </PageContainer>
  );
}