import { OptimisticMutationResolver } from '@urql/exchange-graphcache';
import { TermsAndConditionsFragment, TermsAndConditionsFragmentDoc } from 'rental/generated.graphql';
import { AcceptTuitionAgreementMutationVariables, TuitionAgreementFragmentDoc } from './generated.graphql';

// An optimistic update to makes accepting terms and conditions appear instant.
export const acceptTuitionAgreementResolver: OptimisticMutationResolver = (variables, cache, info) => {
  const { input: { accepted } } = variables as AcceptTuitionAgreementMutationVariables
  const application = cache.readFragment(TuitionAgreementFragmentDoc, {__typename: "TuitionApplication"}) as TermsAndConditionsFragment | null
  if (application) {
    const newValues = {
      application: {
        ...application,
        __typename: "TuitionApplication",
        acceptedAgreement: accepted ? (new Date()).toISOString() : null,
      },
      __typename: "AcceptTuitionAgreementPayload"
    };
    return newValues;
  }
  return null;
};
