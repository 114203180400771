import React from 'react';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import { AppMenuItem, Menu } from 'shared/steps/steps';


interface Props<TSlug extends string> {
  item: AppMenuItem<TSlug>
  menu: Menu<TSlug>
  className?: string
}

export const MenuItemIcon = <TSlug extends string>({ item, menu, className }: Props<TSlug>) => {
  const idx = menu.items.indexOf(item);
  // console.log(idx);
  // console.log(item);
  if (idx < 0) return null;

  if (item.active) {
    return (
      <div className={cs("fa-stack text-primary", className)}>
        <FontAwesomeIcon icon={faCircle} className="fa-stack-1x" />
      </div>
    )
  }
  
  if (item.enabled) {
    if (menu.items[idx + 1]?.enabled) {
      return (
        <div className={cs("fa-stack text-primary", className)}>
          <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
          <FontAwesomeIcon icon={faCheck} className="fa-stack-1x" inverse={true} />
        </div>
      )
    }

    // different style for "furthest available step that isn't active"?
    // return (
    //   <div className={cs("fa-stack text-primary", className)}>
    //     <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" />
    //   </div>
    // )
  }

  return (
    <div className={cs("fa-stack", className)}>
      <FontAwesomeIcon icon={faCircle} className="small fa-stack-1x" color="#ddd" />
    </div>
  );
};
