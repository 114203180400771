import { sentenceCase } from 'change-case';
import cs from 'classnames';
import React from 'react';
import { TuitionErrorField, TuitionErrorType, TuitionApplicationFieldError, TuitionStudentFieldError, TuitionTypeError } from 'tuition/generated.graphql';


export interface Props {
  field?: TuitionErrorField;
  fields?: TuitionErrorField[];
  data?: { [key: string]: any } | null;
  value?: any;
  label?: string;
  errorTypes?: TuitionErrorType[]
  errors?: ReadonlyArray<TuitionApplicationFieldError | TuitionStudentFieldError | TuitionTypeError> | null;
  optional?: boolean;
  uppercase?: boolean;
}

export const TuitionSummaryField = ({data, field, fields, value, label, errors, optional, uppercase, errorTypes}: Props) => {

  if (!fields && field) {
    fields = [field];
  }

  const matchedErrors = errors?.filter(e => 
    (e.__typename === 'TuitionApplicationFieldError' && fields?.includes(e.field))
    || (e.__typename === 'TuitionStudentFieldError' && fields?.includes(e.field))
    || (e.__typename === 'TuitionTypeError' && errorTypes &&  errorTypes?.includes(e.type))
  ) || [];


  if (fields && fields.length > 0) {
    field = field || fields[0];
    value = value || fields.map(f => data?.[f ?? '']).join(' ');
    // error = (errors?.filter((e) => e?.field && e?.message && fields.includes(e?.field || '')) as TuitionApplicationError[])
    //   .reduce<ErrorType | null>((e, c) => {
    //     if (c.messages.length > 0) {
    //       return {
    //         field: '',
    //         messages: e?.messages ?? c.messages,
    //       }
    //     }
    //     return e;
    //   }, null);
  }

  label = label ?? sentenceCase(field ?? '');
  value = value ?? data?.[field ?? ''];
  if (uppercase && typeof value === 'string') {
    value = value.toLocaleUpperCase();
  }
  optional = optional ?? false;
  // const e = error ?? errors?.find((e) => e?.field === field);

  if (optional && !value && !matchedErrors) return null;

  return (
    <>
      <div className={cs(matchedErrors.length > 0 && 'text-danger is-invalid')}>
        {label ? `${label}:` : null} {value}
      </div>

      {matchedErrors && (
        <div className='invalid-feedback mt-0 mb-2'>{matchedErrors.map((e, i) => (
          <React.Fragment key={i}>{e.message}<br/></React.Fragment>
        ))}</div>
      )}
    </>
  );
};

