import cs from 'classnames';
import React from 'react';
import styles from './SummarySection.module.scss';



interface SummaryProps {
  children: React.ReactNode | React.ReactNode[];
}

export const SummarySection = (props: SummaryProps) => {
  return (
    <div className={cs(styles.summary)}>
      {props.children}
    </div>
  )
}
