import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';


interface Props {
  alignment?: 'left' | 'center' | 'right';
}


export const LoadingLabel = ({ alignment }: Props) => (
  <div className={cs(`text-${alignment ?? 'center'}`)}>
    <FontAwesomeIcon icon={faSpinnerThird} spin className="text-muted" /> Loading...
  </div>
);
