import React from 'react';
import { FormErrors } from 'shared/components/FormErrors';
import { ErrorType } from 'tuition/generated.graphql';

interface Props {
  errors: ReadonlyArray<ErrorType & {label?: string} | null | undefined>
  hideFieldName?: boolean
}


export const MutationErrors = (props: Props) => (
  <FormErrors 
    errors={props.errors.map(field => ({
      label: props.hideFieldName ? undefined : field?.label || field?.field,
      message: field?.messages.map((e, index) => <React.Fragment key={index}>{e}<br/></React.Fragment>)
    }))}
  />
);
