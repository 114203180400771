import React from 'react';


export const PhoneNumber = () => {
  return <>1300&nbsp;858&nbsp;911</>
};


interface PhoneNumberLinkProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {}

export const PhoneNumberLink = ({href, target='_blank', ...rest}: PhoneNumberLinkProps) => (
  <a href={`tel:+611300858911`} target={target} {...rest}>
    <PhoneNumber/>
  </a>
)