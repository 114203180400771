import cs from 'classnames';
import React from 'react';
import { SummaryHeader } from 'shared/summary/SummaryHeader';
import { SummarySection } from 'shared/summary/SummarySection';
import { slugs, useTuitionSteps } from 'tuition/steps';
import { TuitionApplicationFragment, TuitionErrorField } from '../../generated.graphql';
import { studentErrorFilter } from '../student/StudentsForm';
import { TuitionSummaryField } from './TuitionSummaryField';


interface Props {
  application: TuitionApplicationFragment
}


export const StudentSummary = ({ application }: Props) => {
  const steps = useTuitionSteps({slug: slugs.student});
  const errors = application?.validationErrors.filter(studentErrorFilter) || [];
  const isInvalid = errors.length > 0;
  const common = {
    data: application,
    errors,
  };
  return (
    <SummarySection>
      <SummaryHeader step={steps.this} className={cs(isInvalid && 'text-danger')}>Student Details</SummaryHeader>
      <TuitionSummaryField {...common} field={TuitionErrorField.School} value={application.school && `${application.school?.name} (${application.school.state})`} />
      {application.students.map((s, i) => {
        const studentCommon = {
          data: s,
          errors: errors.filter(e => e.__typename === 'TuitionStudentFieldError' && e.index === i),
        }
        return (
          <React.Fragment key={s.id}>
            <TuitionSummaryField {...common} data={s} fields={[TuitionErrorField.FirstName, TuitionErrorField.LastName]} label={`Student ${i+1}`} errors={errors} uppercase />
            <div className='pl-2'>
              <TuitionSummaryField {...studentCommon} field={TuitionErrorField.YearLevel} label="Current year level" />
              <TuitionSummaryField {...studentCommon} field={TuitionErrorField.Instrument1} value={s.instrument1.name} label="First choice instrument" />
              <TuitionSummaryField {...studentCommon} field={TuitionErrorField.Instrument2} value={s.instrument2.name} label="Second choice instrument" />
            </div>
          </React.Fragment>
        );
      })}
    </SummarySection>
  );
}
