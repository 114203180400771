import React from 'react';
import { useMedia } from 'react-media';
import { Link, LinkProps } from 'react-router-dom';
import { ButtonProps } from './props';
import cs from 'classnames';
import { GRID_MEDIA_QUERIES } from 'shared/media';

interface Props {
  left?: React.ReactNode;
  centre?: React.ReactNode;
  right?: React.ReactNode;
  xsStack?: boolean;
}

export const SubmitButtons = (props: Props) => {
  // UPDATE: Looks like setting a single button to type="submit" is a better fix for this.
  // NOTE: Right comes first as it's intended to be the next/submit button and we want the default enter keypress in a form
  //       to submit the form and continue the application. By having 'left' first (usually the back button), enter takes you backwards.
  //       However consumers must ensure the 'right' component has an order specified for flexbox ordering.
  const isXS = !useMedia({query: GRID_MEDIA_QUERIES.smGTE});
  return (
    <div className={cs("d-flex justify-content-between justify-content-sm-end align-items-center mt-4 mt-lg-5", isXS && props.xsStack && "flex-column")} style={{gap:"1rem"}}>
      {isXS ? (
        <>
          {props.left}
          {props.centre}
          {props.right}
        </>
      ) : (
        <>
          {props.centre}
          <div className="ml-auto" />
          {props.left}
          {props.right}
        </>
      )}
    </div>
  );
}

export const defaultNextProps = {
  className: "btn btn-primary btn-submit btn-submit-next shadow-sm", 
  title: "Next step", 
  children: "Next",
};

export const defaultPrevProps = {
  className: "btn btn-outline-secondary btn-submit btn-submit-next shadow-sm", 
  title: "Previous step", 
  children: "Previous",
};

export const defaultCentreProps = {
  className: "btn btn-link",
};

const useMediaButtonClassName = () => {
  const isDesktop = useMedia({query: GRID_MEDIA_QUERIES.smGTE});
  return isDesktop && 'btn-wide';
}

export const NextButton = ({saving, ...props}: ButtonProps & {saving?: boolean}) => {
  const btnClassName = useMediaButtonClassName();
  const {className, ...defaultProps} = defaultNextProps;
  const passThruProps = {...{...defaultProps, ...props}}
  if (saving) {
    passThruProps.children = 'Saving...';
    passThruProps.disabled = props.disabled === undefined ? true : props.disabled;
  }
  return (
    <button {...passThruProps} className={cs(className, props.className, btnClassName)} type="submit"/>
  );
}

export const PrevButton = (props: ButtonProps) => {
  const btnClassName = useMediaButtonClassName();
  let {className, children, ...defaultProps} = defaultPrevProps;
  const isXS = !useMedia({query: GRID_MEDIA_QUERIES.smGTE});
  if (isXS) children = "Prev";
  return (
    <button {...{...defaultProps, children, ...props}} className={cs(className, props.className, btnClassName)} type="button"/>
  );
}

export const CentreButton = (props: ButtonProps) => (
  <button {...{...defaultCentreProps, ...props}} type="button"/>
);

type MyLinkProps = LinkProps & {disabled?: boolean}

const mergeLinkProps = (defaultProps: any, props: MyLinkProps) => {
  return {
    ...{
      ...defaultProps, 
      ...props, 
      className: cs(defaultProps.className, props.className, props.disabled && 'disabled'),
    }
  }
}

export const NextLink = (props: MyLinkProps) => {
  const btnClassName = useMediaButtonClassName();
  return (
    <Link {...mergeLinkProps({
      ...defaultNextProps,
      className: cs(defaultNextProps.className, btnClassName),
    }, props)}/>
  );
}

export const PrevLink = (props: MyLinkProps) => {
  const btnClassName = useMediaButtonClassName();
  const isXS = !useMedia({query: GRID_MEDIA_QUERIES.smGTE});
  let {className, children, ...defaultProps} = defaultPrevProps;
  if (isXS) children = "Prev";
  return (
    <Link {...mergeLinkProps({
      ...defaultProps,
      className: cs(className, btnClassName),
      children
    }, props)}/>
  );
}

export const CentreLink = (props: MyLinkProps) => (
  <Link {...mergeLinkProps(defaultCentreProps, props)}/>
);
