import { FieldArray, Form, FormikContext, useFormik } from 'formik';
import { Maybe } from 'graphql/jsutils/Maybe';
import { MutationErrors } from 'shared/components/MutationErrors';
import { PageContainer } from 'shared/components/PageContainer';
import { SubmitActions } from 'shared/components/SubmitActions';
import { PrevButton } from 'shared/components/SubmitButtons';
import { PageHeading } from 'shared/components/typography';
import { SelectFieldGroup } from 'shared/formik/SelectFieldGroup';
import { TextFieldGroup } from 'shared/formik/TextFieldGroup';
import { getSubmitActionsProps } from 'shared/forms/helpers';
import { yupRequiredMsg } from 'shared/yup';
import { SchoolState, StudentFragment, StudentsFragment, TuitionApplicationFieldError, TuitionApplicationFragment, TuitionErrorField, TuitionStudentFieldError, TuitionTypeError, useSchoolQuery, useSchoolsQuery, useSchoolStatesAndYearLevelsQuery, useUpdateApplicationMutation } from 'tuition/generated.graphql';
import { TuitionStepProps } from 'tuition/steps';
import * as Yup from 'yup';
import { TuitionPageTitleHelmet } from '../../components/PageTitleHelmet';
import { createErrorFilter, labels } from '../fields';


interface Props extends TuitionStepProps {
  application: TuitionApplicationFragment
  disableValidationSchema?: boolean
}

interface StudentValues {
  id: number | null
  firstName: string
  lastName: string
  yearLevel: string
  instrument1: string | null
  instrument2: string | null
}

interface Values {
  schoolState?: SchoolState | null
  school: string | null
  students: StudentValues[],
}

const validationSchema = Yup.object({
  schoolState: Yup.string().required(yupRequiredMsg),
  school: Yup.number().required(yupRequiredMsg),
  students: Yup.array().of(
    Yup.object({
      firstName: Yup.string().nullable().required(yupRequiredMsg),
      lastName: Yup.string().nullable().required(yupRequiredMsg),
      yearLevel: Yup.string().typeError(yupRequiredMsg).required(yupRequiredMsg),
      instrument1: Yup.number().typeError(yupRequiredMsg).required(yupRequiredMsg),
      instrument2: Yup.number().typeError(yupRequiredMsg).required(yupRequiredMsg),
    })
  ).required('Must have a student'),
});

const getStudentData = (s: StudentFragment | null): StudentValues => ({
  id: s?.id || null,
  firstName: s?.firstName || '',
  lastName: s?.lastName || '',
  instrument1: s?.instrument1?.id.toString() ?? null,
  instrument2: s?.instrument2?.id.toString() ?? null,
  yearLevel: s?.yearLevel || '',
});

const getInitialData = (f: Maybe<StudentsFragment>): Values => ({
  school: f?.school?.id.toString() || null,
  schoolState: f?.school?.state,
  students: 
    f?.students 
    && (f.students.length || 0) > 0 
    && f.students.map(s=> getStudentData(s)) 
    || [getStudentData(null)],
});


export const studentErrorFilter = createErrorFilter([
  TuitionErrorField.School,
  TuitionErrorField.Instrument1,
  TuitionErrorField.Instrument2,
  TuitionErrorField.YearLevel,
], []);


type Errors = ReadonlyArray<TuitionApplicationFieldError | TuitionStudentFieldError | TuitionTypeError>

const parseErrors = (validationErrors: Maybe<Errors>, mutationErrors: Maybe<Errors> = undefined) => [
  ...mutationErrors || [],
  ...validationErrors?.filter(studentErrorFilter) || [],
].map(e => {

  if (e.__typename === 'TuitionStudentFieldError') return {
    messages: [e.message],
    label: `Student ${e.index + 1} - ${e.label}`,
    field: `students.${e.index}.${e.field}`,
  }
  if (e.__typename === 'TuitionApplicationFieldError') return {
    messages: [e.message],
    label: e.label,
    field: e.field,
  }

  return { messages: [e.message] };
}) || null;


export const StudentsForm = (props: Props) => {

  const [statesAndYearLevels] = useSchoolStatesAndYearLevelsQuery();

  const [,executeMutation] = useUpdateApplicationMutation();

  const initialValues = getInitialData(props.application);

  const formik = useFormik<Values>({
    initialValues,
    validationSchema: props.disableValidationSchema ? undefined : validationSchema,
    validateOnChange: true,
    onSubmit: async (values, formikHelpers) => {
      const r = await executeMutation({
        input: {
          school: parseInt(values.school || '') ?? 0,
          students: values.students.map(s => ({
            ...s,
            yearLevel: s.yearLevel ?? '',
            instrument1: s.instrument1 ? parseInt(s.instrument1) : 0,
            instrument2: s.instrument2 ? parseInt(s.instrument2) : 0, 
          })),
        }
      });
      const m = r.data?.updateTuitionApplication;
      if (m?.application) {
        props.onSubmitSuccessful();
      }
      else {
        const status = parseErrors(m?.application?.validationErrors.filter(studentErrorFilter), m?.errors)
        formikHelpers.setStatus(status)
        props.onSubmitFailure();
        window.scrollTo(0, 0);
      }
      
    }
  });

  const vars = {
    pause: !formik.values.schoolState,
    variables: {filter: {state: formik.values.schoolState?.toLocaleUpperCase()}}
  }
  const [schoolsResult] = useSchoolsQuery(vars);
  const schools = (formik.values.schoolState && schoolsResult.data?.tuition.schools) || [];
  
  const schoolVars = {
    pause: !formik.values.schoolState || !formik.values.school,
    variables: {id: parseInt(formik.values.school ?? '') ?? 0},
  }
  const [schoolResult] = useSchoolQuery(schoolVars);
  const school = formik.values.schoolState && schools.length > 0 && formik.values.school ? schoolResult.data?.tuition.school : null;

  const InstrumentFees = ({instrumentId}: {instrumentId: number | null}) => {
    const instrument = school?.instruments.filter(inst => inst.id.toString() === (instrumentId || '').toString())[0] || null;
    return <div className='px-3 text-success'>{instrument ? `at $${instrument.termFee} per term` : <>&nbsp;</>}</div>;
  };

  return (
  
    <PageContainer>
      <TuitionPageTitleHelmet>Student Details</TuitionPageTitleHelmet>
      <PageHeading>Student Details</PageHeading>

      <FormikContext.Provider value={formik}>

        <Form noValidate>

          {formik.status && <MutationErrors errors={formik.status} />}

          <div className="my-4">

            <div className="form-row">
              <SelectFieldGroup name="schoolState" label={labels.schoolState} blank={true} custom className="col-sm-6">
                {statesAndYearLevels.data?.tuition.states.map(s => <option key={s.abbrev} value={s.abbrev}>{s.name}</option>)}
              </SelectFieldGroup>

              {schools.length > 0 && 
                <SelectFieldGroup name="school" label={labels.school} blank={true} custom className="col-sm-6" disabled={schools.length === 0}>
                  {schools?.map(s => <option key={s.id} value={s.id}>{s.name}</option>)}
                </SelectFieldGroup>
              }
            </div>
          </div> 


        </Form>

        <FieldArray 
          name="students" 
          render={({push, remove}) => (
            <>
              {school && school.instruments && formik.values.students.map((student, index) => {
                
                return (
                  <div className="py-3" key={index}>
        
                    <div className="d-flex mb-3">
                      <h3 className="my-0 py-1">Student {index + 1}</h3>
                      {formik.values.students.length !== 1 && 
                        <button className="btn btn-link" onClick={() => remove(index)}>(Remove)</button>
                      }
                    </div>
            
                    <div className="form-row">
                      <TextFieldGroup label={labels.firstName} name={`students.${index}.firstName`} className="col-sm-4"/>
                      <TextFieldGroup label={labels.lastName} name={`students.${index}.lastName`} className="col-sm-4"/>
                      {/* <TextFieldGroup label={labels.yearLevel} name={`students.${index}.yearLevel`} className="col-sm-4" pattern="\d+" whenNull="" /> */}
                      <div className="col-sm-4 mb-3">
                        <SelectFieldGroup name={`students.${index}.yearLevel`} label={labels.yearLevel} blank={true} custom className="mb-1">
                          {statesAndYearLevels.data?.tuition.yearLevels.map(opt => <option key={opt} value={opt}>{opt}</option>)}
                        </SelectFieldGroup>
                      </div>
                      <div className="col-sm-6 mb-3">
                        <SelectFieldGroup name={`students.${index}.instrument1`} label={labels.instrument1} blank={true} custom className="mb-1">
                          {school.instruments.map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
                        </SelectFieldGroup>
                        <InstrumentFees instrumentId={student.instrument1 ? parseInt(student.instrument1) : null}/>
                      </div>
                      <div className="col-sm-6">
                        <SelectFieldGroup name={`students.${index}.instrument2`} label={labels.instrument2} blank={true} custom className="mb-1">
                          {school.instruments.map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
                        </SelectFieldGroup>
                        <InstrumentFees instrumentId={student.instrument2 ? parseInt(student.instrument2): null}/>
                      </div>
                    </div>

                  </div>
                );
              })}

              <SubmitActions 
                {...getSubmitActionsProps(formik, props)} 
                centre={school?.instruments && <PrevButton onClick={() => push(getStudentData(null))}>Add Student</PrevButton>}
              />

            </>
          )}
        />

      </FormikContext.Provider>


    </PageContainer>
  )
};
