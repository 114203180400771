import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createClient, Provider } from 'urql';
import { AppExpiredInfo, getClientOptions, UrqlOptionOverrides } from 'shared/urql';
import { ScrollToTop } from '../components/ScrollToTop';
import { HelmetProvider } from 'react-helmet-async';


interface Props {
 children: React.ComponentType<{expiryInfo?: AppExpiredInfo}>
 urql?: UrqlOptionOverrides
}


export const AppWrapper = (props: Props) => {

  const [expiryInfo, setExpiryInfo] = useState<AppExpiredInfo>({
    expired: false,
    newId: null,
  });

  const handleAppExpired = (info: AppExpiredInfo) => {
    setExpiryInfo(info);
  }

  const urqlOptions = getClientOptions({
    ...props.urql,
    onAppExpired: handleAppExpired,
  });
  const urqlClient = createClient(urqlOptions);

  return (
    <Provider value={urqlClient}>
      <BrowserRouter>
        <ScrollToTop />
        <HelmetProvider>
          <props.children expiryInfo={expiryInfo} />
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  );
};
