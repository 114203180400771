import React from 'react';
import { LoadingLabel } from 'shared/components/Loading';
import { PageContainer } from 'shared/components/PageContainer';
import { PageHeading } from 'shared/components/typography';
import { TuitionPageTitleHelmet } from 'tuition/components/PageTitleHelmet';
import { useMyTuitionApplicationQuery } from 'tuition/generated.graphql';
import { slugs, TuitionStepProps, useTuitionSteps } from 'tuition/steps';
import { AdditionalSummary } from './AdditionalSummary';
import { AddressSummary } from './AddressSummary';
import { ParentSummary } from './ParentSummary';
import { PaymentSummary } from './PaymentSummary';
import { StudentSummary } from './StudentSummary';
import styles from './Review.module.scss';
import { SubmitActions } from 'shared/components/SubmitActions';




export const Review = (props: TuitionStepProps) => {
  const [result] = useMyTuitionApplicationQuery({requestPolicy: 'network-only'});
  const application = result.data?.tuition.myApplication;
  const step = useTuitionSteps({slug: slugs.review})
  if (application) {
    return (

    <PageContainer>
      <TuitionPageTitleHelmet>Review Application</TuitionPageTitleHelmet>
      <PageHeading>Review Application</PageHeading>
      <div className={styles.Grid}>
        <StudentSummary application={application}/>
        <ParentSummary application={application}/>
        <AddressSummary application={application}/>
        <AdditionalSummary application={application}/>
        <PaymentSummary application={application}/>
      </div>
      <SubmitActions
        actions={props.actions}
        disabled={!step.next?.enabled}
        submit={() => {
          props.onSubmitSuccessful();
        }}
      />
    </PageContainer>
    )
  }
  return <LoadingLabel/>
};
