import cs from 'classnames';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { getFormikFieldServerErrors } from 'shared/forms/helpers';
import styles from './TextField.module.scss';


interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label?: string;
  whenNull?: string;
  // Set this to true to show green "is-valid" feedback
  valid?: boolean
}
export const TextAreaGroup = ({label, className, ...props}: Props) => {

  const [field, meta] = useField(props)
  const context = useFormikContext();
  const serverErrors = getFormikFieldServerErrors(context.status, field.name)
  return (
    <div className={cs("form-group", className, styles.FormField)}>
      <textarea
        placeholder={label}
        // className={cs('form-control', meta.touched && (meta.error ? 'is-invalid' : ('is-valid')))}
        className={cs(
          'form-control', 
          meta.touched && (meta.error || serverErrors) && 'is-invalid', 
          props.valid && 'is-valid'
        )}
        {...field}
        {...props}
        value={(props.whenNull !== undefined && field.value === null) ? props.whenNull: field.value}
      />
      <label>{label}</label>
      {meta.touched && meta.error && 
        <div className="text-danger small mt-1">{meta.error}</div>
      }
    </div>
  )
}
