import React from 'react';
import { Banner } from 'shared/nav/Banner';
import { useTuitionMenu } from './steps';
import logo from './AppBannerLogoMusicorp.png';


export const AppBanner = () => {
  const menu = useTuitionMenu();
  return <Banner
    menu={menu}
    logoLink=""
    logoSrc={logo}
    logoTitle="Musicorp Australian Tuition Application"
    processingTimeMessage=""
  />
}