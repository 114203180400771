import React from 'react';
import { bsClasses } from 'shared/bootstrap';
import cs from 'classnames';

interface Props {
  children?: React.ReactNode;
  extraClassName?: string;
}

export const Container = (props: Props) => {
  return (
    <div className={cs(bsClasses.container, props.extraClassName)}>
      {props.children}
    </div>
  )
};
