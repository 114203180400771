import React from 'react';
import { bsClasses } from 'shared/bootstrap';
import cs from 'classnames';
import { Container } from './Container';

interface Props {
  children?: React.ReactNode;
  extraClassName?: string;
  flushTop?: boolean;
  flushBottom?: boolean
}


export const PageContainer = ({extraClassName, flushTop, flushBottom, ...passThru}: Props) => {
  return (
    <Container 
      extraClassName={cs(
        bsClasses.container, 
        extraClassName, 
        flushTop ? 'pt-0' : 'pt-3', flushBottom ? 'pb-0' : 'pb-3', 
      )}
      {...passThru}
    />
  )
};
