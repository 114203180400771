import cs from 'classnames';
import React, { ChangeEvent } from 'react';
import { useElementId } from 'shared/utils/elementIds';


interface CheckboxProps {
  elementId?: string;
  className?: string;
  checked?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode | React.ReactNode[];
  disabled?: boolean
}


export const AgreementCheckbox = (props: CheckboxProps) => {
  const _autoId = useElementId('agreement-checkbox');
  const id = props.elementId ?? _autoId;
  return (
    <>
      <div className={cs("custom-control custom-checkbox mb-3", props.className)}>
        <input 
          type="checkbox" 
          className="custom-control-input" 
          id={id}
          name={id}
          checked={props.checked}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        <label 
          className="custom-control-label" 
          htmlFor={id}
        >
          {props.children}
          </label>
      </div>
    </>
  );
};
