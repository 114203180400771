import React from 'react';
import cs from 'classnames';
import { Link } from 'react-router-dom';
import styles from './Banner.module.scss';
import { AppMenuItem, Menu } from 'shared/steps/steps';
import { MenuItemIcon } from './MenuItemIcon';


interface Props<TSlug extends string> {
  item: AppMenuItem<TSlug>
  menu: Menu<TSlug>
  onClick?: () => void
}


export const MobileMenuItem = <TSlug extends string>({item, menu, onClick}: Props<TSlug>) => {
  return (
    <Link
      className={cs("list-group-item border-0 d-flex align-items-center text-decoration-none", !item.enabled && "disabled")}
      to={item.enabled ? item.link : ''}
      aria-disabled={!item.enabled}
      onClick={onClick}
    >
      <MenuItemIcon item={item} menu={menu} className={styles.MenuItemStack} />
      <span className={cs("ml-3", !item.enabled && "text-muted")}>{item.number}. {item.title}</span>
    </Link>
  );
};
