import cs from 'classnames';
import { useField, useFormikContext } from 'formik';
import React, { FocusEvent, useState, useEffect } from 'react';
import { getFormikFieldServerErrors } from 'shared/forms/helpers';
import styles from './TextField.module.scss';


interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  whenNull?: string
  // Manually specify an error outside of Formik, eg, from a server query
  error?: string
  // Set this to true to show green "is-valid" feedback
  valid?: boolean
}

interface DateState {
  day: string;
  month: string;
  year: string;
}

interface OptionalDateState {
  day?: string;
  month?: string;
  year?: string;
}

const defaultDateState = { day: '', month: '', year: '' };

export const DateTimeField = ({label, className, whenNull, valid, ...props}: Props) => {
  // const dayInputRef = React.useRef(null);
  // const monthInputRef = React.useRef(null);
  // const yearInputRef = React.useRef(null);
  // const [processNextKeystroke, setProcessNextKeystroke] = useState(false);

  const [field, meta, helpers] = useField(props);
  const context = useFormikContext();
  const serverErrors = getFormikFieldServerErrors(context.status, field.name);

  const [errors, setErrors] = useState<DateState & { all: string }>({ ...defaultDateState, all: '' });
  const hasInternalError = Object.values(errors).some(e => !!e);
  const showError = hasInternalError || props.error || !!(meta.touched && meta.error);

  // store in local state the current date value in { day, month, year } strings
  // if the component re-renders and the original field.value has changed, overwrite our values with it
  // if the component re-renders due to our date value change, continue
  const [state, setState] = useState<DateState>(defaultDateState);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (field.value) {
      const [year, month, day] = (field.value as string).split('-');
      const newState = {
        day: day ?? '',
        month: month ?? '',
        year: year ?? ''
      };
      setState(newState);

      // if a new date was fully set, mark it as touched to ensure errors are shown immediately
      if (Object.values(newState).every(v => !!v)) {
        helpers.setTouched(true);
      }
    }
  }, [field.value]);
  /* eslint-enable */

  // update local state on change
  const handleChange = ({ day, month, year }: OptionalDateState) => {
    setState({
      day: day ?? state.day,
      month: month ?? state.month,
      year: year ?? state.year,
    });

    // // this really needs to go in an effect on state change to avoid blur being called prior to setState committing
    // if (day !== undefined && day.length == 2 && monthInputRef.current) {
    //   ((monthInputRef.current as unknown) as HTMLInputElement).focus();
    //   setProcessNextKeystroke(true);
    // } else if (month !== undefined && month.length == 2 && yearInputRef.current) {
    //   ((yearInputRef.current as unknown) as HTMLInputElement).focus();
    //   setProcessNextKeystroke(true);
    // }
  }

  // auto-focus behaviour as follows:
  // 1. day/month: on second character typed, change focus to next input, all text selected
  // 2a. if next keystroke is TAB, ignore
  // 2b. if next keystroke is Backspace, set focus to previous input, caret at column 3, then delete last char
  // 3. if any other input received to the window, ignore the next keystroke (e.g. mouse click into another field)

  // const handleKeyDownCapture = (e: KeyboardEvent<HTMLInputElement>) => {
  //   console.log(processNextKeystroke, e.code);
  //   if (processNextKeystroke) {
  //     if (e.code === 'Tab') {
  //       // simply consume the first extra tab char
  //       e.preventDefault();
  //       e.stopPropagation();
  //     } else if (e.code === 'Backspace') {
  //       // e.preventDefault();
  //       // e.stopPropagation();
  //       // don't delete text, move back to previous input and remove 1 char
  //       if (e.currentTarget === yearInputRef.current) {
  //         ((monthInputRef.current as unknown) as HTMLInputElement).focus();
  //       } else if (e.currentTarget === monthInputRef.current) {
  //         ((dayInputRef.current as unknown) as HTMLInputElement).focus();
  //       }
  //     }
  //     setProcessNextKeystroke(false);
  //   }
  // };

  // parse local state on blur
  const handleBlur = () => {
    const check = (i: number, min: number = 1, max: number) => i >= min && i <= max;
    
    // do we have all the date components now? if so, try parsing and committing value to formik field
    try {
      if (Object.values(state).every(v => !!v) || meta.touched) {
        const day = parseInt(state.day ?? '');
        const month = parseInt(state.month ?? '') - 1;
        let year = parseInt(state.year ?? '');

        // possibly dodgy 2-digit year conversion
        if (year < 100) {
          const curYear = new Date().getFullYear();
          year += (Math.floor(curYear / 100) + ((year < curYear % 100 + 1) ? 0 : -1)) * 100;
          setState({
            ...state,
            year: year.toString()
          });
        }

        const newErrors = { ...defaultDateState, all: '' };
        if (!check(day, 1, 31)) {
          newErrors.day = 'Day must be between 1 and 31.';
        }

        if (!check(month, 0, 11)) {
          newErrors.month = 'Month must be between 1 and 12.';
        }
        
        if (!check(year, 1900, new Date().getFullYear())) {
          // TODO: DOB rules should be relaxed for general dates
          newErrors.year = 'Year must be 4 digits before today.';
        }

        if (Object.values(newErrors).some(e => !!e)) {
          setErrors(newErrors);
          throw new Error();
        }

        // javascript dates allow invalid day/month/year values to roll over, so check that the date object maintained the values we passed in
        const result = new Date(year, month, day);
        if (!(result.getDate() === day && result.getMonth() === month && result.getFullYear() === year)) {
          throw new Error('Enter a valid date.');
        }


        const formatted = `${result.getFullYear().toString().padStart(4, '0')}-${(result.getMonth() + 1).toString().padStart(2, '0')}-${result.getDate().toString().padStart(2, '0')}`;
        setErrors(newErrors);
        helpers.setTouched(true);
        helpers.setValue(formatted);
      }
    } catch (err) {
      if ((err as Error).message) {
        setErrors({
          ...defaultDateState,
          all: (err as Error).message,
        });
      }
      helpers.setTouched(true);
      helpers.setValue('');
    }
  };


  const inputs = [
    {
      id: `${props.name}-day`,
      label: 'DD',
      value: state.day,
      error: errors.day,
      maxLength: 2,
      onChange: (e: FocusEvent<HTMLInputElement>) => handleChange({ day: e.target.value }),
      // ref: dayInputRef,
    },
    {
      id: `${props.name}-month`,
      label: 'MM',
      value: state.month,
      error: errors.month,
      maxLength: 2,
      onChange: (e: FocusEvent<HTMLInputElement>) => handleChange({ month: e.target.value }),
      // ref: monthInputRef,
    },
    {
      id: `${props.name}-year`,
      label: 'YYYY',
      value: state.year,
      error: errors.year,
      maxLength: 4,
      onChange: (e: FocusEvent<HTMLInputElement>) => handleChange({ year: e.target.value }),
      // ref: yearInputRef,
    },
  ];

  return (
    <div className={cs('form-group', className)}>
      <label>{label}</label>
      <div className="form-row">
        {inputs.map(({ id, label, error, ...fieldProps }) => (
          <div className={cs("col", styles.FormField)} key={id}>
            <input
              id={id}
              /*ref={props.ref}*/
              placeholder={label}
              className={cs(
                'form-control',
                (hasInternalError ? error || errors.all : props.error || !!(meta.touched && meta.error) || serverErrors) && 'is-invalid', 
                // TODO: Set 'is-valid' className based on what we want to do
                //       Currently this is once they have submitted the errors and server side errors have come back
                // meta.touched && !meta.error && field.value && !props.disabled && 'is-valid'
                valid && 'is-valid',
              )}
              pattern="[0-9]*"
              inputMode="numeric"
              onBlur={handleBlur}
              /*onKeyDownCapture={handleKeyDownCapture}*/
              {...fieldProps}
            />
            <label htmlFor={id}>{label}</label>
          </div>
        ))}
      </div>
      {showError && 
        <div className="text-danger small mt-1" style={{flexBasis:'100%'}}>{Object.values(errors).join(' ').trim() || props.error || meta.error}</div>
      }
    </div>
  )
}
