

export const range = (min: number, max: number) => {
  const r: number[] = []
  if (min <= max) {
    for (let i=min; i<=max; i++) {
      r.push(i)
    }
  }
  return r;
}

export const rangeForCount = (min: number, count: number) => range(min, min + count - 1);
