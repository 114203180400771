import React from 'react';
import { StepActionParams } from 'shared/steps/types';

interface Props extends StepActionParams {
  actions?: (actions: StepActionParams) => React.ReactNode
  left?: React.ReactNode
  centre?: React.ReactNode
  right?: React.ReactNode
}

export const SubmitActions = ({actions, ...props}: Props) => (
  <>{actions && actions(props)}</>
);

