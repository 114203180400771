/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import cs from 'classnames';

type HTMLHeadingProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>

interface PageHeadingProps extends HTMLHeadingProps {
  elementType?: keyof JSX.IntrinsicElements;
  subtitle?: string;
}

export const PageHeading = (props: PageHeadingProps) => {
  const {elementType, subtitle, className, ...otherProps} = props;
  return (
    <>
      <h2 className={className ?? cs(subtitle ? "mb-2" : "mb-4 pb-2")} {...otherProps} />
      {subtitle && (
        <p className="body-text text-muted mb-4 pb-2">{subtitle}</p>
      )}
    </>
  );
};
