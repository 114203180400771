
export interface AddressSegments {
  street: string;
  suburb: string;
  state: string;
  postcode: string
}

export const defaultAddressSegments: AddressSegments = {
  street: '',
  suburb: '',
  state: '',
  postcode: '',
};

export const addressJoin = (value: AddressSegments) => {
  return [
    value.street.trim(), 
    value.suburb.trim(), 
    value.state.trim(), 
    value.postcode.trim()
  ].filter(v => v.length > 0).join(' ');
}
