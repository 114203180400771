import { sentenceCase } from 'change-case';
import cs from 'classnames';
import React from 'react';
import { LoadingLabel } from 'shared/components/Loading';
import { SummaryField } from 'shared/summary/SummaryField';
import { SummaryHeader } from 'shared/summary/SummaryHeader';
import { SummarySection } from 'shared/summary/SummarySection';
import { slugs, useTuitionSteps } from 'tuition/steps';
import { TuitionApplicationFragment } from '../../generated.graphql';
import { paymentErrorFilter } from '../payment/Payment';


interface Props {
  application: TuitionApplicationFragment
}


export const PaymentSummary = ({ application }: Props) => {
  const steps = useTuitionSteps({slug: slugs.payment});
  const errors = application?.validationErrors.filter(paymentErrorFilter) || [];
  const isInvalid = errors.length > 0;
  const payment = application?.payment;
  return (
    <SummarySection>
      <SummaryHeader step={steps.this} className={cs(isInvalid && 'text-danger')}>Payment Method - Credit Card</SummaryHeader>
      {!application ? <LoadingLabel alignment="left" /> : (
        <div className={cs(isInvalid && 'text-danger')}>
          {!payment ? <div>Card details not provided.</div> : (
            <>
              <div>Card ending in: {payment.maskedCardNumber}</div>
              <div>Expiry: {payment.expiryMM}/{payment.expiryYYYY}</div>
            </>
          )}
          {isInvalid && (
            <div className="small">
              {errors?.map(e => e?.message)}
            </div>
          )}
        </div>
      )}
    </SummarySection>
  );
}
