import { FormikContext, useFormik } from 'formik';
import React from 'react';
import { AddressComponent, useAddressField } from 'shared/address/AddressField';
import { MutationErrors } from 'shared/components/MutationErrors';
import { PageContainer } from 'shared/components/PageContainer';
import { SubmitActions } from 'shared/components/SubmitActions';
import { PageHeading } from 'shared/components/typography';
import { RadioField } from 'shared/formik/RadioFieldGroup';
import { getSubmitActionsProps } from 'shared/forms/helpers';
import { TuitionPageTitleHelmet } from 'tuition/components/PageTitleHelmet';
import { AddressesFragment, TuitionErrorField, useUpdateApplicationMutation } from 'tuition/generated.graphql';
import { TuitionStepProps } from 'tuition/steps';
import { createErrorFilter } from '../fields';


interface Props extends TuitionStepProps {
  application: AddressesFragment
}


interface Values {
  delivery: 'different' | 'same'
}

export const addressErrorFilter = createErrorFilter([
  TuitionErrorField.ResidentialAddress,
  TuitionErrorField.DeliveryAddress,
], []);

export const AddressForm = (props: Props) => {
  const { residentialAddress, deliveryAddress } = props.application;
  const [, executeMutation] = useUpdateApplicationMutation();

  const formik = useFormik<Values>({
    initialValues: {
      delivery: deliveryAddress ? 'different' : 'same',
    },
    onSubmit: async () => {}
  });

  const residential = useAddressField({
    addressName: "residential",
    placeholderText: "Start typing your residential address...",
    initialValue: residentialAddress?.fullAddress || null,
    onChanged: a => { },
    required: true,
  });

  const delivery = useAddressField({
    addressName: "delivery",
    placeholderText: "Start typing your delivery address...",
    initialValue: deliveryAddress?.fullAddress || null,
    onChanged: a => { },
    required: true,
  });

  return (
    <PageContainer>
      <TuitionPageTitleHelmet>Address</TuitionPageTitleHelmet>
      <PageHeading subtitle="What is your current address?">Your Address</PageHeading>

      {formik.status && <MutationErrors errors={formik.status}/>}
          
      {/* <AddressComponent/> Uses formik under the hood, so don't wrap this in our instance of formik */}
      <AddressComponent context={residential}/>

      <h3 className="mt-3">Delivery</h3>

      <FormikContext.Provider value={formik}>
        <RadioField
          name="delivery"
          className="mb-1"
          label='Delivery address is the same as my residential address'
          option='same'
          custom
        />
        <RadioField
          name="delivery"
          className="mb-1"
          label='Deliver to a different address'
          option='different'
          custom
        />
      </FormikContext.Provider>
      
      <div className="py-3"></div>
      {formik.values.delivery === 'different' &&
        <AddressComponent context={delivery}/>
      }

      <FormikContext.Provider value={formik}>
        <SubmitActions {...getSubmitActionsProps(formik, props)}
          submit={async () => {
            const r = await residential.trySubmit()
            const d = await delivery.trySubmit()
            await formik.submitForm(); 
            
            function getInput() {

              if (!formik.errors.delivery) {

                if (formik.values.delivery === 'same' && r) return {
                  residentialAddress: r,
                  deliveryAddress: null,
                };
                
                if (formik.values.delivery === 'different' && r && d) return {
                  residentialAddress: r,
                  deliveryAddress: d,
                };

              }
            }

            const input = getInput();
            if (!input) return;
              
            const m = await executeMutation({input});
            if (m.data?.updateTuitionApplication?.application) {
              props.onSubmitSuccessful();
            }
            props.onSubmitFailure();

          }}
        />
      </FormikContext.Provider>

      
    </PageContainer>
  );
};
