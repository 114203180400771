import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Menu } from 'shared/steps/steps';
import { MobileMenuModal } from './MobileMenuModal';
import { PillsMenu } from './PillsMenu';


interface Props<TSlug extends string> {
  menu: Menu<TSlug> | null
}

export const StepsMenu = <TSlug extends string>({menu}: Props<TSlug>) => {
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false);
  if (!menu || !menu.visible) {
    return (<div className="ml-auto" />);
  }

  return (
    <>
      <div className="d-md-none ml-auto">
        <button className="btn btn-sm" type="button" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
          {menu.currentItem ? `Step ${menu.currentItem.number} of ${menu.items.length}`  : 'Steps'} <FontAwesomeIcon icon={faAngleDown} size={'2x'} className="ml-1" style={{verticalAlign: 'middle'}} />
        </button>
      </div>
      <div className="d-none d-md-block ml-auto mr-auto">
        <PillsMenu menu={menu}/>
      </div>
      {mobileMenuOpen && 
        <MobileMenuModal menu={menu} onAfterClose={() => setMobileMenuOpen(false)} />
      }
    </>
  )
};
