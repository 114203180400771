import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
};

export type AcceptTuitionAgreementInput = {
  readonly accepted: Scalars['Boolean'];
};

/** Call this mutation to indicate agreement acceptance and marketing consent. */
export type AcceptTuitionAgreementPayload = {
  readonly __typename?: 'AcceptTuitionAgreementPayload';
  readonly application?: Maybe<TuitionApplication>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type AddSuggestedItemInput = {
  /** The ID of the item */
  readonly id: Scalars['Int'];
  readonly portalSlug: Scalars['String'];
};

/** Adds a suggested item into your cart by changing the quantity to 1 if it is 0.  There is no effect if the item is already in your cart.  Although intended to only be used for suggested items, this is not checked. */
export type AddSuggestedItemPayload = {
  readonly __typename?: 'AddSuggestedItemPayload';
  readonly application?: Maybe<Application>;
  readonly item?: Maybe<Item>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type Address = {
  readonly __typename?: 'Address';
  /** The actual stored address. Used to infer street/suburb/state/postcode. */
  readonly fullAddress: Scalars['String'];
  /** Indicates if this address is considered valid */
  readonly valid: Scalars['Boolean'];
  /** Indicates if this address has been validated */
  readonly validated: Scalars['Boolean'];
  readonly street?: Maybe<Scalars['String']>;
  readonly suburb?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly postcode?: Maybe<Scalars['String']>;
};

export type AppVersion = {
  readonly __typename?: 'AppVersion';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  /** Used for the sub-domain of this version and for the code folder. */
  readonly slug: Scalars['String'];
};

export type Application = {
  readonly __typename?: 'Application';
  readonly id: Scalars['Int'];
  readonly portal: Portal;
  readonly appVersion?: Maybe<AppVersion>;
  /** Link to send customers to if they wish to add another item. Use this for portals that have different urls for different customers.  Leave blank to use portal default. */
  readonly storeLink: Scalars['String'];
  readonly residentStatus?: Maybe<ApplicationResidentStatus>;
  readonly marketingConsent: Scalars['Boolean'];
  /** Date to indicate all T&Cs, approval processes, and communication have been accepted by the customer */
  readonly acceptedAgreement?: Maybe<Scalars['DateTime']>;
  readonly importantInfoRead?: Maybe<Scalars['DateTime']>;
  readonly dateSessionExpires?: Maybe<Scalars['DateTime']>;
  readonly pricing: ApplicationPricing;
  readonly items?: Maybe<ReadonlyArray<Item>>;
  readonly suggestedItems?: Maybe<ReadonlyArray<SuggestedItem>>;
  readonly customer: Customer;
  readonly alternativeContactRequired: Scalars['Boolean'];
  readonly address: ApplicationAddress;
  readonly livingSituation: ApplicationLivingSituation;
  readonly income: Income;
  readonly inStore?: Maybe<InStore>;
  readonly includesProtectionPlan: Scalars['Boolean'];
  readonly bankStatements: BankStatements;
  readonly bankStatementsRequired: Scalars['Boolean'];
  readonly payment?: Maybe<CreditCard>;
  readonly hasDirectPostFailed: Scalars['Boolean'];
  readonly paymentSkipped?: Maybe<Scalars['DateTime']>;
  readonly validation: ApplicationValidation;
  readonly rateOptions: ReadonlyArray<RateOption>;
  readonly rebateMonths: Scalars['Int'];
  /** If a value is present the application has been successfully submitted. */
  readonly dateSubmitted?: Maybe<Scalars['DateTime']>;
  readonly remainingSmsSignatureCount: Scalars['Int'];
  readonly digitalSignature: Scalars['String'];
  readonly digitalSignatureRequired: Scalars['Boolean'];
  readonly householdFieldsRequired: Scalars['Boolean'];
  readonly testMode: TestMode;
  readonly importantInfo: ApplicationImportantInfo;
  readonly remarketingScript?: Maybe<Scalars['String']>;
  readonly conversionScript?: Maybe<Scalars['String']>;
  readonly hasMixedRates: Scalars['Boolean'];
};

export type ApplicationAddress = {
  readonly __typename?: 'ApplicationAddress';
  readonly residentialAddress?: Maybe<Address>;
  readonly deliveryAddressType: DeliveryAddressType;
  readonly deliveryAddress?: Maybe<Address>;
  readonly dealer?: Maybe<Dealer>;
  readonly deliveryInstructions?: Maybe<Scalars['String']>;
};

export type ApplicationImportantInfo = {
  readonly __typename?: 'ApplicationImportantInfo';
  readonly freightHeading: Scalars['String'];
  readonly freightHtml: Scalars['String'];
  /** An alternative version to split test on. */
  readonly freightHtmlAlt: Scalars['String'];
  readonly ageAndResidencyHtml: Scalars['String'];
  readonly rentalProcessHtml: Scalars['String'];
  /** An alternative version to split test on. */
  readonly rentalProcessHtmlAlt: Scalars['String'];
  readonly minPeriodText: Scalars['String'];
  readonly minPeriodTextAlt: Scalars['String'];
  readonly namedApplicantsText: Scalars['String'];
  readonly productTypeInfo: ReadonlyArray<ProductTypeInfo>;
};

/** An enumeration. */
export enum ApplicationLivingExpensesPercentage {
  /** Less than 50% */
  A_0_50 = 'A_0_50',
  /** Between 50% and 75% */
  A_50_75 = 'A_50_75',
  /** More than 75% */
  A_75_100 = 'A_75_100',
  /** I don't know */
  Unknown = 'UNKNOWN'
}

export type ApplicationLivingSituation = {
  readonly __typename?: 'ApplicationLivingSituation';
  readonly homeType?: Maybe<HomeTypeSource>;
  readonly homeDurationYears?: Maybe<Scalars['Int']>;
  readonly homeDurationMonths?: Maybe<Scalars['Int']>;
};

export type ApplicationPricing = {
  readonly __typename?: 'ApplicationPricing';
  readonly initialPayment: Scalars['Decimal'];
  readonly ongoingPayment: Scalars['Decimal'];
  readonly setupFee: Scalars['Decimal'];
  readonly totalFreight: Scalars['Decimal'];
  /** How much protection plan would cost.  If selected, this amount is included in the initialPayment and ongoingPayment. */
  readonly protectionPlan: Scalars['Decimal'];
};

/** An enumeration. */
export enum ApplicationResidentStatus {
  /** I am an Australian citizen or permanent resident */
  C = 'C',
  /** I am in Australia on a visa */
  V = 'V'
}

export type ApplicationValidation = {
  readonly __typename?: 'ApplicationValidation';
  readonly isValid: Scalars['Boolean'];
  readonly payment?: Maybe<ReadonlyArray<ErrorType>>;
  readonly cart?: Maybe<ReadonlyArray<ErrorType>>;
  readonly customer?: Maybe<ReadonlyArray<ErrorType>>;
  readonly address?: Maybe<ReadonlyArray<ErrorType>>;
  readonly livingSituation?: Maybe<ReadonlyArray<ErrorType>>;
  readonly income?: Maybe<ReadonlyArray<ErrorType>>;
  readonly bankStatements?: Maybe<ReadonlyArray<ErrorType>>;
  readonly agreement?: Maybe<ReadonlyArray<ErrorType>>;
};

/** An enumeration. */
export enum ApplicationWorkBasis {
  /** Full time employment */
  Full = 'FULL',
  /** Part time employment */
  Part = 'PART',
  /** Casual employment */
  Casual = 'CASUAL',
  /** Government benefits */
  Government = 'GOVERNMENT',
  /** Other */
  Other = 'OTHER',
  /** No income */
  None = 'NONE'
}

export type BankStatements = {
  readonly __typename?: 'BankStatements';
  readonly embedURL: Scalars['String'];
  readonly result?: Maybe<BankStatementsResult>;
  readonly reference?: Maybe<Scalars['String']>;
  readonly errorCode: Scalars['String'];
  readonly errorMessage: Scalars['String'];
};

/** An enumeration. */
export enum BankStatementsResult {
  /** Skipped - Contact me instead */
  Skip = 'SKIP',
  /** Successfully submitted */
  Complete = 'COMPLETE',
  /** Login to bank failed - We may contact you later */
  LoginFail = 'LOGIN_FAIL',
  /** Error - We may contact you later */
  Error = 'ERROR'
}

export type CancelApplicationInput = {
  readonly portalSlug?: Maybe<Scalars['String']>;
};

/** Cancels all current applications by flushing the user session. */
export type CancelApplicationPayload = {
  readonly __typename?: 'CancelApplicationPayload';
  readonly portal?: Maybe<Portal>;
};

/** Cancels the current tuition application by removing the app id from the session. */
export type CancelTuitionApplicationPayload = {
  readonly __typename?: 'CancelTuitionApplicationPayload';
  readonly application: TuitionApplication;
};

export type ClearApplicationWarningsInput = {
  readonly portalSlug: Scalars['String'];
};

export type ClearApplicationWarningsPayload = {
  readonly __typename?: 'ClearApplicationWarningsPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export enum Condition {
  New = 'NEW',
  Preloved = 'PRELOVED'
}

export type CreditCard = {
  readonly __typename?: 'CreditCard';
  readonly maskedCardNumber: Scalars['String'];
  readonly expiryMMYY: Scalars['String'];
  readonly expiryMM: Scalars['String'];
  readonly expiryYY: Scalars['String'];
  readonly expiryYYYY: Scalars['String'];
};

export type Customer = {
  readonly __typename?: 'Customer';
  readonly title?: Maybe<SalutationSource>;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly email: Scalars['String'];
  readonly dob?: Maybe<Scalars['Date']>;
  readonly phoneMobile: Scalars['String'];
  readonly licenceNo: Scalars['String'];
  readonly altContactName: Scalars['String'];
  readonly altContactPhone: Scalars['String'];
  readonly workComments: Scalars['String'];
};



/** Used to populate the dealer selector in an application */
export type Dealer = {
  readonly __typename?: 'Dealer';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
};


export enum DeliveryAddressType {
  Dealer = 'DEALER',
  Residential = 'RESIDENTIAL',
  Custom = 'CUSTOM'
}

export type ErrorType = {
  readonly __typename?: 'ErrorType';
  readonly field: Scalars['String'];
  readonly messages: ReadonlyArray<Scalars['String']>;
};

/** An enumeration. */
export enum HomeTypeSource {
  /** Own Home */
  Own = 'OWN',
  /** Renting */
  Rent = 'RENT',
  /** Boarding */
  Boar = 'BOAR',
  /** Live with Parents */
  Pare = 'PARE',
  /** Government Housing */
  Gove = 'GOVE'
}

export type InStore = {
  readonly __typename?: 'InStore';
  readonly storeName: Scalars['String'];
  /** Polygon store this in our database with the application */
  readonly salesPersonName: Scalars['String'];
  readonly storeLogo?: Maybe<Scalars['String']>;
};

export type Income = {
  readonly __typename?: 'Income';
  readonly occupation: Scalars['String'];
  readonly employer: Scalars['String'];
  readonly phoneWork: Scalars['String'];
  readonly workTimeYears?: Maybe<Scalars['Int']>;
  readonly workTimeMonths?: Maybe<Scalars['Int']>;
  readonly workBasis?: Maybe<ApplicationWorkBasis>;
  readonly livingExpensesPercentage?: Maybe<ApplicationLivingExpensesPercentage>;
};

export type InitiateNabCustomerRecordInput = {
  readonly portalSlug: Scalars['String'];
  /** Whether the user has also submitted the form. */
  readonly submitted?: Maybe<Scalars['Boolean']>;
  readonly nameOnCard: Scalars['String'];
};

export type InitiateNabCustomerRecordPayload = {
  readonly __typename?: 'InitiateNABCustomerRecordPayload';
  readonly application?: Maybe<Application>;
  readonly nabCustomerRecord?: Maybe<NabCustomerRecord>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type InitiateRentalDirectPostInput = {
  /** Whether the user has also submitted the form. */
  readonly submitted?: Maybe<Scalars['Boolean']>;
  readonly nameOnCard: Scalars['String'];
  readonly portalSlug: Scalars['String'];
};

export type InitiateRentalDirectPostPayload = {
  readonly __typename?: 'InitiateRentalDirectPostPayload';
  readonly nabCrn?: Maybe<NabCrn>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type InitiateTuitionDirectPostInput = {
  /** Whether the user has also submitted the form. */
  readonly submitted?: Maybe<Scalars['Boolean']>;
  readonly nameOnCard: Scalars['String'];
  readonly applicationId: Scalars['Int'];
};

export type InitiateTuitionDirectPostPayload = {
  readonly __typename?: 'InitiateTuitionDirectPostPayload';
  readonly nabCrn?: Maybe<NabCrn>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type Item = {
  readonly __typename?: 'Item';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly modelNumber: Scalars['String'];
  readonly url: Scalars['String'];
  readonly maxQty?: Maybe<Scalars['Int']>;
  /** The number the user has selected */
  readonly qty: Scalars['Int'];
  readonly selectedRate: Rate;
  readonly availableRates?: Maybe<ReadonlyArray<Rate>>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly condition: Condition;
  readonly prelovedConditionReport?: Maybe<Scalars['String']>;
  readonly newProductDescription?: Maybe<Scalars['String']>;
  readonly serialNumber?: Maybe<Scalars['String']>;
  /** The main title of the products */
  readonly title: Scalars['String'];
  /** Specific information about the variant or configuration */
  readonly subtitle?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly startValue: Scalars['Decimal'];
  readonly conversionId: Scalars['String'];
  readonly conversionValue: Scalars['Decimal'];
};

export type LogBankStatementsEventInput = {
  /** Used to link to the customers application (leave null for ad-hoc). */
  readonly portalSlug?: Maybe<Scalars['String']>;
  readonly reference: Scalars['String'];
  readonly event: Scalars['String'];
  readonly status?: Maybe<Scalars['String']>;
  readonly data?: Maybe<Scalars['String']>;
  readonly errorCode?: Maybe<Scalars['String']>;
  readonly errorMessage?: Maybe<Scalars['String']>;
};

/** A log of the users interaction with the bank statements portal. */
export type LogBankStatementsEventPayload = {
  readonly __typename?: 'LogBankStatementsEventPayload';
  readonly eventId?: Maybe<Scalars['Int']>;
};

export type Mutations = {
  readonly __typename?: 'Mutations';
  /** Adds a suggested item into your cart by changing the quantity to 1 if it is 0.  There is no effect if the item is already in your cart.  Although intended to only be used for suggested items, this is not checked. */
  readonly addSuggestedItem?: Maybe<AddSuggestedItemPayload>;
  /** Cancels all current applications by flushing the user session. */
  readonly cancelApplication?: Maybe<CancelApplicationPayload>;
  readonly initiateNabCustomerRecord?: Maybe<InitiateNabCustomerRecordPayload>;
  readonly initiateRentalDirectPost?: Maybe<InitiateRentalDirectPostPayload>;
  /** A log of the users interaction with the bank statements portal. */
  readonly logBankStatementsEvent?: Maybe<LogBankStatementsEventPayload>;
  /** After the user has completed the BankStatements process, the iframe will use window.postMessage to send the result to the parent frame.  Call this mutation with that result. */
  readonly recordBankStatementsResult?: Maybe<RecordBankStatementsResultPayload>;
  /** Remove an item from the cart.  If this item was a suggested item, it will be be reverted to a suggested item by changing the quantity to 0.  Otherwise this item will be deleted. */
  readonly removeItem?: Maybe<RemoveItemPayload>;
  /** Call this mutation to indicate agreement acceptance and marketing consent. */
  readonly setAcceptedAgreement?: Maybe<SetAcceptedAgreementPayload>;
  readonly setImportantInfoRead?: Maybe<SetImportantInfoReadPayload>;
  readonly setRebateMonths?: Maybe<SetRebateMonthsPayload>;
  /** Sets up the application with test data for specific scenarios. */
  readonly setTestDataState?: Maybe<SetTestDataStatePayload>;
  /** Call this mutation if the user does not want to supply their bank statements as part of the application. */
  readonly skipBankStatements?: Maybe<SkipBankStatementsPayload>;
  /** Call this mutation if the user is having trouble completing their payment details. */
  readonly skipPayment?: Maybe<SkipPaymentPayload>;
  readonly submitApplication?: Maybe<SubmitApplicationPayload>;
  readonly updateIncome?: Maybe<UpdateIncomePayload>;
  readonly updateItem?: Maybe<UpdateItemPayload>;
  readonly updateProtectionPlan?: Maybe<UpdateProtectionPlanPayload>;
  readonly updateCustomer?: Maybe<UpdateCustomerPayload>;
  /** Starts a new application with the same cart items updated with the latest prices and availability. All personal information will be discarded. */
  readonly restartApplication?: Maybe<RestartApplicationPayload>;
  readonly setLivingSituation?: Maybe<SetLivingSituationPayload>;
  readonly setResidentialAddress?: Maybe<SetResidentialAddressPayload>;
  readonly setDeliveryAddress?: Maybe<SetDeliveryAddressPayload>;
  readonly setDeliveryDealer?: Maybe<SetDeliveryDealerPayload>;
  readonly setResidentStatus?: Maybe<SetResidentStatusPayload>;
  readonly recordInteraction?: Maybe<RecordInteractionPayload>;
  readonly clearApplicationWarnings?: Maybe<ClearApplicationWarningsPayload>;
  /** Call this mutation to send an SMS signature for an application. */
  readonly sendSmsSignature?: Maybe<SendSmsSignature>;
  readonly validateAddress?: Maybe<ValidateAddressPayload>;
  /** Only used by an ecommerce sites register items that will be added to a customers rental cart. Returns a redirect URL that if navigated to by a customer, will add these items to their cart. */
  readonly registerRentalItems?: Maybe<RegisterRentalItemsPayload>;
  /** Cancels the current tuition application by removing the app id from the session. */
  readonly cancelTuitionApplication?: Maybe<CancelTuitionApplicationPayload>;
  readonly initiateTuitionDirectPost?: Maybe<InitiateTuitionDirectPostPayload>;
  /** Call this mutation if the user is having trouble completing their payment details. */
  readonly skipTuitionPayment?: Maybe<SkipTuitionPaymentPayload>;
  readonly updateTuitionApplication?: Maybe<UpdateTuitionApplicationPayload>;
  /** Call this mutation to indicate agreement acceptance and marketing consent. */
  readonly acceptTuitionAgreement?: Maybe<AcceptTuitionAgreementPayload>;
  readonly submitTuitionApplication?: Maybe<SubmitTuitionApplicationPayload>;
};


export type MutationsAddSuggestedItemArgs = {
  input: AddSuggestedItemInput;
};


export type MutationsCancelApplicationArgs = {
  input: CancelApplicationInput;
};


export type MutationsInitiateNabCustomerRecordArgs = {
  input: InitiateNabCustomerRecordInput;
};


export type MutationsInitiateRentalDirectPostArgs = {
  input: InitiateRentalDirectPostInput;
};


export type MutationsLogBankStatementsEventArgs = {
  input: LogBankStatementsEventInput;
};


export type MutationsRecordBankStatementsResultArgs = {
  input: RecordBankStatementsResultInput;
};


export type MutationsRemoveItemArgs = {
  input: RemoveItemInput;
};


export type MutationsSetAcceptedAgreementArgs = {
  input: SetAcceptedAgreementInput;
};


export type MutationsSetImportantInfoReadArgs = {
  input: SetImportantInfoReadInput;
};


export type MutationsSetRebateMonthsArgs = {
  input: SetRebateMonthsInput;
};


export type MutationsSetTestDataStateArgs = {
  input: SetTestDataStateInput;
};


export type MutationsSkipBankStatementsArgs = {
  input: SkipBankStatementsInput;
};


export type MutationsSkipPaymentArgs = {
  input: SkipPaymentInput;
};


export type MutationsSubmitApplicationArgs = {
  input: SubmitApplicationInput;
};


export type MutationsUpdateIncomeArgs = {
  input: UpdateIncomeInput;
};


export type MutationsUpdateItemArgs = {
  input: UpdateItemInput;
};


export type MutationsUpdateProtectionPlanArgs = {
  input: UpdateProtectionPlanInput;
  portalSlug: Scalars['String'];
};


export type MutationsUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationsRestartApplicationArgs = {
  input: RestartApplicationInput;
};


export type MutationsSetLivingSituationArgs = {
  input: SetLivingSituationInput;
};


export type MutationsSetResidentialAddressArgs = {
  input: SetResidentialAddressInput;
};


export type MutationsSetDeliveryAddressArgs = {
  input: SetDeliveryAddressInput;
};


export type MutationsSetDeliveryDealerArgs = {
  input: SetDeliveryDealerInput;
};


export type MutationsSetResidentStatusArgs = {
  input: SetResidentStatusInput;
};


export type MutationsRecordInteractionArgs = {
  input: RecordInteractionInput;
};


export type MutationsClearApplicationWarningsArgs = {
  input: ClearApplicationWarningsInput;
};


export type MutationsSendSmsSignatureArgs = {
  input: SendSmsSignatureInput;
};


export type MutationsValidateAddressArgs = {
  input: ValidateAddressInput;
};


export type MutationsRegisterRentalItemsArgs = {
  input: RegisterRentalItemsInput;
};


export type MutationsInitiateTuitionDirectPostArgs = {
  input: InitiateTuitionDirectPostInput;
};


export type MutationsUpdateTuitionApplicationArgs = {
  input: UpdateTuitionApplicationInput;
};


export type MutationsAcceptTuitionAgreementArgs = {
  input: AcceptTuitionAgreementInput;
};

export type NabCrn = {
  readonly __typename?: 'NabCrn';
  /** Used by NAB to identify the customer. Field of up to 20 characters. */
  readonly crn: Scalars['ID'];
  /** A timestamp of the format "YYYYMMDDHHMMSS" in GMT. The hour component must be specified in 24-hour format. This same value must be used in both the fingerprint operation request and the subsequent customer management request. */
  readonly timestamp: Scalars['String'];
  /** value returned after submitting information to the fingerprint generation system on NAB Transact. It is a Base64-encoded string and must be included in all transaction attempts. */
  readonly fingerprint: Scalars['String'];
  readonly merchant: Scalars['String'];
  readonly resultUrl: Scalars['String'];
  readonly nabUrl: Scalars['String'];
  readonly action: Scalars['String'];
};

export type NabCustomerRecord = {
  readonly __typename?: 'NabCustomerRecord';
  readonly id: Scalars['ID'];
  /** A timestamp of the format "YYYYMMDDHHMMSS" in GMT. The hour component must be specified in 24-hour format. This same value must be used in both the fingerprint operation request and the subsequent customer management request. */
  readonly timestamp: Scalars['String'];
  /** Used to identify the customer. Field of up to 20 characters. */
  readonly crn: Scalars['String'];
  /** value returned after submitting information to the fingerprint generation system on NAB Transact. It is a Base64-encoded string and must be included in all transaction attempts. */
  readonly fingerprint: Scalars['String'];
  readonly merchant: Scalars['String'];
  readonly resultUrl: Scalars['String'];
  readonly nabUrl: Scalars['String'];
  readonly action: Scalars['String'];
};

export type PolicyContent = {
  readonly __typename?: 'PolicyContent';
  readonly title: Scalars['String'];
  readonly content: Scalars['String'];
  readonly url?: Maybe<Scalars['String']>;
};

export type Portal = {
  readonly __typename?: 'Portal';
  /** Displayed throughout the application process. */
  readonly title: Scalars['String'];
  /** Used in portal URL and API authentication. Only chars, NO DASHES. Eg. https://secure.polygongroup.com.au/apply/PORTAL-SLUG/ */
  readonly slug: Scalars['String'];
  readonly requireDealer: Scalars['Boolean'];
  /** Link to dealer site, Musicorp, etc.  NOTE: Its also critical that this matches studiot19rentals netloc as CORS fails if they differ (http is OK). */
  readonly portalUrl: Scalars['String'];
  /** Link to Polygon, Studio19, etc. */
  readonly companyUrl: Scalars['String'];
  /** Where "Add new item" buttons link to. Defaults to Portal URL */
  readonly addProductUrl: Scalars['String'];
  readonly portalLogoUrl?: Maybe<Scalars['String']>;
  readonly companyLogoUrl?: Maybe<Scalars['String']>;
  readonly tradingName: Scalars['String'];
  /** e.g. Musicorp Australia (a trading name of Polygon Group ABN 00000) */
  readonly fullTradingName: Scalars['String'];
  readonly isStudio19: Scalars['Boolean'];
  readonly policies: PortalPolicies;
  readonly analytics: PortalAnalytics;
  readonly myApplication?: Maybe<Application>;
  /** The last completed application id for this user on this portal */
  readonly myLastApplicationId?: Maybe<Scalars['Int']>;
  readonly dealers?: Maybe<ReadonlyArray<Dealer>>;
  readonly themeSlug: Scalars['String'];
  readonly willRaiseAnUnexpectedError?: Maybe<Scalars['String']>;
  readonly willRaiseAnExpiredError?: Maybe<Scalars['String']>;
};


export type PortalWillRaiseAnUnexpectedErrorArgs = {
  name?: Maybe<Scalars['String']>;
};

export type PortalAnalytics = {
  readonly __typename?: 'PortalAnalytics';
  readonly trackingId?: Maybe<Scalars['String']>;
  readonly linkedDomain?: Maybe<Scalars['String']>;
  readonly sendAllEvents: Scalars['Boolean'];
  readonly adwordsConversionId?: Maybe<Scalars['String']>;
  readonly conversionScript?: Maybe<Scalars['String']>;
};

/** The collection of policies relating to a portal */
export type PortalPolicies = {
  readonly __typename?: 'PortalPolicies';
  readonly terms?: Maybe<PolicyContent>;
  readonly privacy?: Maybe<PolicyContent>;
  readonly protectionPlan?: Maybe<PolicyContent>;
  readonly creditGuideUrl?: Maybe<Scalars['String']>;
  readonly privacyPolicyUrl: Scalars['String'];
  readonly websiteTermsUrl: Scalars['String'];
};

export type ProductTypeInfo = {
  readonly __typename?: 'ProductTypeInfo';
  readonly title: Scalars['String'];
  readonly content: Scalars['String'];
};

/** Represents the estimated price to purchase this item outright after renting for this number of months. */
export type PurchasePrice = {
  readonly __typename?: 'PurchasePrice';
  /** The number of months elapsed on the rental contract. */
  readonly month: Scalars['Int'];
  /** The estimated price to purchase this item outright. */
  readonly amount: Scalars['Decimal'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly tuition: Tuition;
  readonly portal?: Maybe<Portal>;
  readonly occupations: ReadonlyArray<Scalars['String']>;
};


export type QueryPortalArgs = {
  slug: Scalars['String'];
};

export type Rate = {
  readonly __typename?: 'Rate';
  readonly rentalMonthly?: Maybe<Scalars['Decimal']>;
  readonly protectionPlan?: Maybe<Scalars['Decimal']>;
  readonly minMonths?: Maybe<Scalars['Int']>;
  readonly rebateMonths?: Maybe<Scalars['Int']>;
  readonly purchasePricing: ReadonlyArray<PurchasePrice>;
  readonly freight?: Maybe<Scalars['Decimal']>;
  readonly bond?: Maybe<Scalars['Decimal']>;
  readonly selected?: Maybe<Scalars['Boolean']>;
};

export type RateOption = {
  readonly __typename?: 'RateOption';
  readonly rentalMonthly: Scalars['Decimal'];
  readonly minMonths: Scalars['Int'];
  readonly rebateMonths: Scalars['Int'];
};

export type RecordBankStatementsResultInput = {
  readonly portalSlug: Scalars['String'];
  readonly result: BankStatementsResult;
  readonly reference: Scalars['String'];
  readonly errorCode?: Maybe<Scalars['String']>;
  readonly errorMessage?: Maybe<Scalars['String']>;
};

/** After the user has completed the BankStatements process, the iframe will use window.postMessage to send the result to the parent frame.  Call this mutation with that result. */
export type RecordBankStatementsResultPayload = {
  readonly __typename?: 'RecordBankStatementsResultPayload';
  readonly application?: Maybe<Application>;
};

export type RecordInteractionInput = {
  readonly portalSlug: Scalars['String'];
  readonly stepSlug: Scalars['String'];
  readonly stepName?: Maybe<Scalars['String']>;
};

export type RecordInteractionPayload = {
  readonly __typename?: 'RecordInteractionPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly interactionId?: Maybe<Scalars['Int']>;
};

export type RegisterRentalItemInput = {
  readonly modelNumber?: Maybe<Scalars['String']>;
  readonly serialNo?: Maybe<Scalars['String']>;
  /** Manufacturers Part Number */
  readonly mpn?: Maybe<Scalars['String']>;
  /** Global Trade Item Number (usually a barcode) */
  readonly gtin?: Maybe<Scalars['String']>;
  /** Stock-Keeping Unit */
  readonly sku?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  /** The number the user has selected */
  readonly qty: Scalars['Int'];
  readonly maxQty?: Maybe<Scalars['Int']>;
  readonly isAccessory?: Maybe<Scalars['Boolean']>;
  readonly requireDealer?: Maybe<Scalars['Boolean']>;
  readonly expires?: Maybe<Scalars['DateTime']>;
  /** String that is sent to the backend */
  readonly xmlCategory?: Maybe<Scalars['String']>;
  /** String that is sent to the backend */
  readonly xmlParentCategory?: Maybe<Scalars['String']>;
  /** An optional tag which can be used for reporting.  Eg "PrelovedSale" */
  readonly tag?: Maybe<Scalars['String']>;
  /** The ID of the product in your system/database.  Probably a primary key or SKU.  Something that that won't change and relates to the value specified for 'source'. */
  readonly id: Scalars['String'];
  /** The name of the product. */
  readonly name: Scalars['String'];
  /** The sub-title of the product.  Will be shown in braces after the product name.  Eg. 'Product name (sub name)' */
  readonly subName?: Maybe<Scalars['String']>;
  /** The full starting price value of this item which the rental rates and purchase pricing were calculated from. */
  readonly rrp: Scalars['Decimal'];
  readonly rate: RentalRateInput;
  readonly alternativeRates?: Maybe<ReadonlyArray<RentalRateInput>>;
  readonly condition: Condition;
  /** Only used for new items (but a future update might this for pre-loved). */
  readonly newProductDescription?: Maybe<Scalars['String']>;
  /** Only used for pre-loved items */
  readonly prelovedConditionReport?: Maybe<Scalars['String']>;
  /** Thumbnail image to show next to item in the cart. During the API call, the application server will fetch this, and resize it down to thumbnail size. Therefore, the size & network speed of the image will directly affect the speed of this API call. So, specify a medium size image.  Small enough to be fast, but large enough to not regrade too much if resized again. */
  readonly image?: Maybe<Scalars['String']>;
  readonly suggestions?: Maybe<ReadonlyArray<RentalSuggestedItemInput>>;
  readonly subItems?: Maybe<ReadonlyArray<RentalSubItemInput>>;
};

export type RegisterRentalItemsInput = {
  readonly originatingReferer?: Maybe<Scalars['String']>;
  /** Used to show the "sub-store" of site.  For example, yourgolfpro.net has a lot of different stores on the same domain */
  readonly storeName?: Maybe<Scalars['String']>;
  readonly storeLogo?: Maybe<Scalars['String']>;
  /** An optional UUIDv4 that is generated on the clients browser that is sent with every add items and cart ajax request so that the cart items can be linked without the use of third-party cookies. */
  readonly thirdPartyCartUuid?: Maybe<Scalars['String']>;
  readonly portalSlug: Scalars['String'];
  /** The database/system that the "id" related to.  We will have told you what to set this value to.  If you only have one product database, then you will have a single value for everything.  If you have multiple product databases, then you will one per database, eg. "studio19:mybikeshop".  If we were to give you this "source" value and an items "id" you should theoretically be able to look up the product for us. */
  readonly source: Scalars['String'];
  /** A list of the items to add.  Usually this will be one, but it can be any number. */
  readonly items: ReadonlyArray<RegisterRentalItemInput>;
};

/** Only used by an ecommerce sites register items that will be added to a customers rental cart. Returns a redirect URL that if navigated to by a customer, will add these items to their cart. */
export type RegisterRentalItemsPayload = {
  readonly __typename?: 'RegisterRentalItemsPayload';
  readonly items?: Maybe<ReadonlyArray<Item>>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly redirectUrl?: Maybe<Scalars['String']>;
  readonly redirectUrlHash?: Maybe<Scalars['String']>;
  readonly redirectUrlItemIds?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type RemoveItemInput = {
  /** The ID of the item */
  readonly id: Scalars['Int'];
};

/** Remove an item from the cart.  If this item was a suggested item, it will be be reverted to a suggested item by changing the quantity to 0.  Otherwise this item will be deleted. */
export type RemoveItemPayload = {
  readonly __typename?: 'RemoveItemPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

/** Represents the estimated price to purchase this item outright after renting for this number of months. */
export type RentalPurchasePriceInput = {
  /** The number of months elapsed on the rental contract. */
  readonly month: Scalars['Int'];
  /** The estimated price to purchase this item outright. */
  readonly amount: Scalars['Decimal'];
};

export type RentalRateInput = {
  readonly rentalMonthly: Scalars['Decimal'];
  readonly minMonths: Scalars['Int'];
  readonly rebateMonths: Scalars['Int'];
  readonly purchasePricing: ReadonlyArray<RentalPurchasePriceInput>;
  /** Admin, handling and/or shipping fee */
  readonly freight: Scalars['Decimal'];
  /** Omit if protection plan is not available for this item. */
  readonly protectionPlan?: Maybe<Scalars['Decimal']>;
  /** Sometimes an item such as an expensive grand piano also has a bond fee. */
  readonly bond?: Maybe<Scalars['Decimal']>;
};

export type RentalSubItemInput = {
  readonly modelNumber?: Maybe<Scalars['String']>;
  readonly serialNo?: Maybe<Scalars['String']>;
  /** Manufacturers Part Number */
  readonly mpn?: Maybe<Scalars['String']>;
  /** Global Trade Item Number (usually a barcode) */
  readonly gtin?: Maybe<Scalars['String']>;
  /** Stock-Keeping Unit */
  readonly sku?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  /** The number the user has selected */
  readonly qty: Scalars['Int'];
  readonly maxQty?: Maybe<Scalars['Int']>;
  readonly isAccessory?: Maybe<Scalars['Boolean']>;
  readonly requireDealer?: Maybe<Scalars['Boolean']>;
  readonly expires?: Maybe<Scalars['DateTime']>;
  /** String that is sent to the backend */
  readonly xmlCategory?: Maybe<Scalars['String']>;
  /** String that is sent to the backend */
  readonly xmlParentCategory?: Maybe<Scalars['String']>;
  /** An optional tag which can be used for reporting.  Eg "PrelovedSale" */
  readonly tag?: Maybe<Scalars['String']>;
  /** The ID of the product in your system/database.  Probably a primary key or SKU.  Something that that won't change and relates to the value specified for 'source'. */
  readonly id: Scalars['String'];
  /** The name of the product. */
  readonly name: Scalars['String'];
  /** The sub-title of the product.  Will be shown in braces after the product name.  Eg. 'Product name (sub name)' */
  readonly subName?: Maybe<Scalars['String']>;
  /** The full starting price value of this item which the rental rates and purchase pricing were calculated from. */
  readonly rrp: Scalars['Decimal'];
  readonly rate: RentalRateInput;
  readonly alternativeRates?: Maybe<ReadonlyArray<RentalRateInput>>;
  readonly condition: Condition;
  /** Only used for new items (but a future update might this for pre-loved). */
  readonly newProductDescription?: Maybe<Scalars['String']>;
  /** Only used for pre-loved items */
  readonly prelovedConditionReport?: Maybe<Scalars['String']>;
  /** Thumbnail image to show next to item in the cart. During the API call, the application server will fetch this, and resize it down to thumbnail size. Therefore, the size & network speed of the image will directly affect the speed of this API call. So, specify a medium size image.  Small enough to be fast, but large enough to not regrade too much if resized again. */
  readonly image?: Maybe<Scalars['String']>;
};

export type RentalSuggestedItemInput = {
  readonly modelNumber?: Maybe<Scalars['String']>;
  readonly serialNo?: Maybe<Scalars['String']>;
  /** Manufacturers Part Number */
  readonly mpn?: Maybe<Scalars['String']>;
  /** Global Trade Item Number (usually a barcode) */
  readonly gtin?: Maybe<Scalars['String']>;
  /** Stock-Keeping Unit */
  readonly sku?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly maxQty?: Maybe<Scalars['Int']>;
  readonly isAccessory?: Maybe<Scalars['Boolean']>;
  readonly requireDealer?: Maybe<Scalars['Boolean']>;
  readonly expires?: Maybe<Scalars['DateTime']>;
  /** String that is sent to the backend */
  readonly xmlCategory?: Maybe<Scalars['String']>;
  /** String that is sent to the backend */
  readonly xmlParentCategory?: Maybe<Scalars['String']>;
  /** An optional tag which can be used for reporting.  Eg "PrelovedSale" */
  readonly tag?: Maybe<Scalars['String']>;
  /** The ID of the product in your system/database.  Probably a primary key or SKU.  Something that that won't change and relates to the value specified for 'source'. */
  readonly id: Scalars['String'];
  /** The name of the product. */
  readonly name: Scalars['String'];
  /** The sub-title of the product.  Will be shown in braces after the product name.  Eg. 'Product name (sub name)' */
  readonly subName?: Maybe<Scalars['String']>;
  /** The full starting price value of this item which the rental rates and purchase pricing were calculated from. */
  readonly rrp: Scalars['Decimal'];
  readonly rate: RentalRateInput;
  readonly alternativeRates?: Maybe<ReadonlyArray<RentalRateInput>>;
  readonly condition: Condition;
  /** Only used for new items (but a future update might this for pre-loved). */
  readonly newProductDescription?: Maybe<Scalars['String']>;
  /** Only used for pre-loved items */
  readonly prelovedConditionReport?: Maybe<Scalars['String']>;
  /** Thumbnail image to show next to item in the cart. During the API call, the application server will fetch this, and resize it down to thumbnail size. Therefore, the size & network speed of the image will directly affect the speed of this API call. So, specify a medium size image.  Small enough to be fast, but large enough to not regrade too much if resized again. */
  readonly image?: Maybe<Scalars['String']>;
};

export type RestartApplicationInput = {
  readonly portalSlug: Scalars['String'];
};

/** Starts a new application with the same cart items updated with the latest prices and availability. All personal information will be discarded. */
export type RestartApplicationPayload = {
  readonly __typename?: 'RestartApplicationPayload';
  readonly portal?: Maybe<Portal>;
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

/** An enumeration. */
export enum SalutationSource {
  /** Mr. */
  Mr = 'MR',
  /** Mrs. */
  Mrs = 'MRS',
  /** Miss */
  Miss = 'MISS',
  /** Other */
  Other = 'OTHER'
}

/** An enumeration. */
export enum SchoolState {
  /** Australian Capital Territory */
  Act = 'ACT',
  /** New South Wales */
  Nsw = 'NSW',
  /** Northern Territory */
  Nt = 'NT',
  /** Queensland */
  Qld = 'QLD',
  /** South Australia */
  Sa = 'SA',
  /** Tasmania */
  Tas = 'TAS',
  /** Victoria */
  Vic = 'VIC',
  /** Western Australia */
  Wa = 'WA'
}

/** Call this mutation to send an SMS signature for an application. */
export type SendSmsSignature = {
  readonly __typename?: 'SendSmsSignature';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SendSmsSignatureInput = {
  readonly portalSlug: Scalars['String'];
  readonly number: Scalars['String'];
};

export type SetAcceptedAgreementInput = {
  readonly portalSlug: Scalars['String'];
  readonly accepted: Scalars['Boolean'];
  readonly marketingConsent: Scalars['Boolean'];
};

/** Call this mutation to indicate agreement acceptance and marketing consent. */
export type SetAcceptedAgreementPayload = {
  readonly __typename?: 'SetAcceptedAgreementPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SetDeliveryAddressInput = {
  readonly street?: Maybe<Scalars['String']>;
  readonly suburb?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly postcode?: Maybe<Scalars['String']>;
  readonly portalSlug: Scalars['String'];
  readonly fullAddress?: Maybe<Scalars['String']>;
  readonly deliveryUseHomeAddress?: Maybe<Scalars['Boolean']>;
  readonly deliveryInstructions?: Maybe<Scalars['String']>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetDeliveryAddressPayload = {
  readonly __typename?: 'SetDeliveryAddressPayload';
  readonly street?: Maybe<Scalars['String']>;
  readonly suburb?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly postcode?: Maybe<Scalars['String']>;
  readonly portalSlug: Scalars['String'];
  readonly fullAddress?: Maybe<Scalars['String']>;
  readonly deliveryUseHomeAddress?: Maybe<Scalars['Boolean']>;
  readonly deliveryInstructions?: Maybe<Scalars['String']>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly application?: Maybe<Application>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetDeliveryDealerInput = {
  readonly dealer: Scalars['ID'];
  readonly portalSlug: Scalars['String'];
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetDeliveryDealerPayload = {
  readonly __typename?: 'SetDeliveryDealerPayload';
  readonly dealer: Scalars['ID'];
  readonly portalSlug: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly application?: Maybe<Application>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetImportantInfoReadInput = {
  readonly portalSlug: Scalars['String'];
};

export type SetImportantInfoReadPayload = {
  readonly __typename?: 'SetImportantInfoReadPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SetLivingSituationInput = {
  readonly homeType: HomeTypeSource;
  readonly homeDurationYears: Scalars['Int'];
  readonly homeDurationMonths?: Maybe<Scalars['Int']>;
  readonly portalSlug: Scalars['String'];
  readonly id?: Maybe<Scalars['ID']>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetLivingSituationPayload = {
  readonly __typename?: 'SetLivingSituationPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetRebateMonthsInput = {
  readonly portalSlug: Scalars['String'];
  readonly rebateMonths: Scalars['Int'];
};

export type SetRebateMonthsPayload = {
  readonly __typename?: 'SetRebateMonthsPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SetResidentStatusInput = {
  readonly residentStatus?: Maybe<Scalars['String']>;
  readonly portalSlug: Scalars['String'];
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetResidentStatusPayload = {
  readonly __typename?: 'SetResidentStatusPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetResidentialAddressInput = {
  readonly street?: Maybe<Scalars['String']>;
  readonly suburb?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly postcode?: Maybe<Scalars['String']>;
  readonly portalSlug: Scalars['String'];
  readonly fullAddress: Scalars['String'];
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetResidentialAddressPayload = {
  readonly __typename?: 'SetResidentialAddressPayload';
  readonly street?: Maybe<Scalars['String']>;
  readonly suburb?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly postcode?: Maybe<Scalars['String']>;
  readonly portalSlug: Scalars['String'];
  readonly fullAddress: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly application?: Maybe<Application>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetTestDataStateInput = {
  readonly action: TestDataState;
  readonly portalSlug: Scalars['String'];
};

/** Sets up the application with test data for specific scenarios. */
export type SetTestDataStatePayload = {
  readonly __typename?: 'SetTestDataStatePayload';
  readonly application?: Maybe<Application>;
  readonly portal?: Maybe<Portal>;
};

export type SkipBankStatementsInput = {
  readonly portalSlug: Scalars['String'];
};

/** Call this mutation if the user does not want to supply their bank statements as part of the application. */
export type SkipBankStatementsPayload = {
  readonly __typename?: 'SkipBankStatementsPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SkipPaymentInput = {
  readonly portalSlug: Scalars['String'];
};

/** Call this mutation if the user is having trouble completing their payment details. */
export type SkipPaymentPayload = {
  readonly __typename?: 'SkipPaymentPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

/** Call this mutation if the user is having trouble completing their payment details. */
export type SkipTuitionPaymentPayload = {
  readonly __typename?: 'SkipTuitionPaymentPayload';
  readonly application?: Maybe<TuitionApplication>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SubmitApplicationInput = {
  readonly portalSlug: Scalars['String'];
  readonly signature: Scalars['String'];
};

export type SubmitApplicationPayload = {
  readonly __typename?: 'SubmitApplicationPayload';
  readonly portal?: Maybe<Portal>;
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SubmitTuitionApplicationPayload = {
  readonly __typename?: 'SubmitTuitionApplicationPayload';
  readonly application?: Maybe<TuitionApplication>;
  readonly tuition?: Maybe<Tuition>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SuggestedItem = {
  readonly __typename?: 'SuggestedItem';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly url: Scalars['String'];
  readonly rentalMonthly?: Maybe<Scalars['Decimal']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  /** The main title of the products */
  readonly title: Scalars['String'];
  /** Specific information about the variant or configuration */
  readonly subtitle?: Maybe<Scalars['String']>;
};

export enum TestDataState {
  AddPersonalDetails = 'ADD_PERSONAL_DETAILS',
  AddSimplePrelovedItem = 'ADD_SIMPLE_PRELOVED_ITEM',
  AddSimpleNewItem = 'ADD_SIMPLE_NEW_ITEM',
  SetupS19InstoreApp = 'SETUP_S19_INSTORE_APP'
}

export type TestMode = {
  readonly __typename?: 'TestMode';
  readonly application: Scalars['Boolean'];
  readonly bankStatements: Scalars['Boolean'];
};

export type Tuition = {
  readonly __typename?: 'Tuition';
  readonly myApplication: TuitionApplication;
  readonly schools?: Maybe<ReadonlyArray<TuitionSchool>>;
  readonly school?: Maybe<TuitionSchool>;
  readonly states: ReadonlyArray<TuitionSchoolState>;
  readonly yearLevels: ReadonlyArray<Scalars['String']>;
  readonly tuitionTerms?: Maybe<PolicyContent>;
};


export type TuitionSchoolsArgs = {
  filter?: Maybe<TuitionSchoolsFilter>;
};


export type TuitionSchoolArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type TuitionAddressInput = {
  /** The full address. Usually the typeahead suggestion or the manual address parts concatenated */
  readonly fullAddress: Scalars['String'];
  /** If address was manually entered, pass these values to assist in debugging issues. */
  readonly manuallyEnteredValues?: Maybe<TuitionManualAddressInput>;
};

export type TuitionApplication = {
  readonly __typename?: 'TuitionApplication';
  /** The pk of the tuition application.  Will be null is there is not an application yet (or it has expired). */
  readonly id?: Maybe<Scalars['Int']>;
  readonly school?: Maybe<TuitionSchool>;
  readonly parentTitle?: Maybe<Scalars['String']>;
  readonly parentFirstName: Scalars['String'];
  readonly parentLastName: Scalars['String'];
  readonly parentEmail: Scalars['String'];
  readonly parentPhoneMobile: Scalars['String'];
  readonly parentDob?: Maybe<Scalars['Date']>;
  readonly notes: Scalars['String'];
  /** Whether the user has agreed to the agreement on the final step of the application. */
  readonly acceptedAgreement?: Maybe<Scalars['DateTime']>;
  readonly submitted?: Maybe<Scalars['DateTime']>;
  readonly validationErrors: ReadonlyArray<TuitionApplicationError>;
  readonly residentialAddress?: Maybe<Address>;
  readonly deliveryAddress?: Maybe<Address>;
  readonly payment?: Maybe<CreditCard>;
  readonly hasDirectPostFailed: Scalars['Boolean'];
  readonly paymentSkipped?: Maybe<Scalars['Boolean']>;
  /** The last completed tuition application id for this user */
  readonly myLastApplicationId?: Maybe<Scalars['Int']>;
  readonly students: ReadonlyArray<TuitionStudent>;
};

export type TuitionApplicationError = {
  readonly message: Scalars['String'];
};

export type TuitionApplicationFieldError = TuitionApplicationError & {
  readonly __typename?: 'TuitionApplicationFieldError';
  readonly message: Scalars['String'];
  readonly field: TuitionErrorField;
  readonly label?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum TuitionErrorField {
  AcceptedAgreement = 'acceptedAgreement',
  DeliveryAddress = 'deliveryAddress',
  FirstName = 'firstName',
  Instrument1 = 'instrument1',
  Instrument2 = 'instrument2',
  LastName = 'lastName',
  Notes = 'notes',
  ParentDob = 'parentDob',
  ParentEmail = 'parentEmail',
  ParentFirstName = 'parentFirstName',
  ParentLastName = 'parentLastName',
  ParentPhoneMobile = 'parentPhoneMobile',
  ParentTitle = 'parentTitle',
  ResidentialAddress = 'residentialAddress',
  School = 'school',
  YearLevel = 'yearLevel'
}

export enum TuitionErrorType {
  NoActiveApplication = 'NO_ACTIVE_APPLICATION',
  PaymentMissing = 'PAYMENT_MISSING',
  PaymentDeclined = 'PAYMENT_DECLINED'
}

export type TuitionInstrument = {
  readonly __typename?: 'TuitionInstrument';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly termFee: Scalars['Decimal'];
};

export type TuitionManualAddressInput = {
  readonly street?: Maybe<Scalars['String']>;
  readonly suburb?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly postcode?: Maybe<Scalars['String']>;
};

export type TuitionSchool = {
  readonly __typename?: 'TuitionSchool';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly state: SchoolState;
  readonly isActive: Scalars['Boolean'];
  readonly instruments: ReadonlyArray<TuitionInstrument>;
};

export type TuitionSchoolState = {
  readonly __typename?: 'TuitionSchoolState';
  readonly abbrev: Scalars['String'];
  readonly name: Scalars['String'];
};

export type TuitionSchoolsFilter = {
  readonly state?: Maybe<Scalars['String']>;
  readonly search?: Maybe<Scalars['String']>;
};

export type TuitionStudent = {
  readonly __typename?: 'TuitionStudent';
  readonly id: Scalars['Int'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly yearLevel: Scalars['String'];
  readonly instrument1: TuitionInstrument;
  readonly instrument2: TuitionInstrument;
};

export type TuitionStudentFieldError = TuitionApplicationError & {
  readonly __typename?: 'TuitionStudentFieldError';
  readonly message: Scalars['String'];
  readonly index: Scalars['Int'];
  readonly field: TuitionErrorField;
  readonly label?: Maybe<Scalars['String']>;
};

export type TuitionStudentInput = {
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly yearLevel: Scalars['String'];
  readonly instrument1: Scalars['Int'];
  readonly instrument2: Scalars['Int'];
  /** Set this to update a student.  Leaving it blank will create a new student. */
  readonly id?: Maybe<Scalars['Int']>;
};

export type TuitionTypeError = TuitionApplicationError & {
  readonly __typename?: 'TuitionTypeError';
  readonly message: Scalars['String'];
  readonly type: TuitionErrorType;
};

export type UpdateCustomerInput = {
  readonly title?: Maybe<SalutationSource>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phoneMobile?: Maybe<Scalars['String']>;
  readonly dob?: Maybe<Scalars['Date']>;
  readonly licenceNo?: Maybe<Scalars['String']>;
  readonly altContactName?: Maybe<Scalars['String']>;
  readonly altContactPhone?: Maybe<Scalars['String']>;
  readonly workComments?: Maybe<Scalars['String']>;
  readonly marketingConsent?: Maybe<Scalars['Boolean']>;
  readonly portalSlug: Scalars['ID'];
  /** Only validate the data. No changes will be made. */
  readonly validateOnly?: Maybe<Scalars['Boolean']>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCustomerPayload = {
  readonly __typename?: 'UpdateCustomerPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<ErrorType>>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateIncomeInput = {
  readonly workBasis?: Maybe<ApplicationWorkBasis>;
  readonly employer?: Maybe<Scalars['String']>;
  readonly phoneWork?: Maybe<Scalars['String']>;
  readonly workTimeYears?: Maybe<Scalars['Int']>;
  readonly workTimeMonths?: Maybe<Scalars['Int']>;
  readonly occupation?: Maybe<Scalars['String']>;
  readonly livingExpensesPercentage?: Maybe<ApplicationLivingExpensesPercentage>;
  readonly portalSlug: Scalars['ID'];
  /** Only validate the data. No changes will be made. */
  readonly validateOnly?: Maybe<Scalars['Boolean']>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateIncomePayload = {
  readonly __typename?: 'UpdateIncomePayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<ErrorType>>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateItemInput = {
  /** The ID of the item */
  readonly id: Scalars['Int'];
  /** Quantity */
  readonly qty: Scalars['Int'];
};

export type UpdateItemPayload = {
  readonly __typename?: 'UpdateItemPayload';
  readonly application?: Maybe<Application>;
  readonly item?: Maybe<Item>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type UpdateProtectionPlanInput = {
  /** Whether to include the purchase plan on this application. */
  readonly protectionPlan: Scalars['Boolean'];
};

export type UpdateProtectionPlanPayload = {
  readonly __typename?: 'UpdateProtectionPlanPayload';
  readonly application?: Maybe<Application>;
};

export type UpdateTuitionApplicationInput = {
  readonly school?: Maybe<Scalars['Int']>;
  readonly parentTitle?: Maybe<Scalars['String']>;
  readonly parentFirstName?: Maybe<Scalars['String']>;
  readonly parentLastName?: Maybe<Scalars['String']>;
  readonly parentEmail?: Maybe<Scalars['String']>;
  readonly parentPhoneMobile?: Maybe<Scalars['String']>;
  readonly parentDob?: Maybe<Scalars['Date']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly residentialAddress?: Maybe<TuitionAddressInput>;
  /** Use null to specify delivery to residential address. */
  readonly deliveryAddress?: Maybe<TuitionAddressInput>;
  /** If provided, this is the entire set of students.  Students with id's will be updated, with no id's deleted, and not specified will be deleted. */
  readonly students?: Maybe<ReadonlyArray<TuitionStudentInput>>;
};

export type UpdateTuitionApplicationPayload = {
  readonly __typename?: 'UpdateTuitionApplicationPayload';
  readonly application?: Maybe<TuitionApplication>;
  readonly errors?: Maybe<ReadonlyArray<TuitionApplicationError>>;
};

export type ValidateAddressInput = {
  readonly address: Scalars['String'];
};

export type ValidateAddressPayload = {
  readonly __typename?: 'ValidateAddressPayload';
  readonly address?: Maybe<Address>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  readonly __typename?: '__Type';
  readonly kind: __TypeKind;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly specifiedByUrl?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<__Field>>;
  readonly interfaces?: Maybe<ReadonlyArray<__Type>>;
  readonly possibleTypes?: Maybe<ReadonlyArray<__Type>>;
  readonly enumValues?: Maybe<ReadonlyArray<__EnumValue>>;
  readonly inputFields?: Maybe<ReadonlyArray<__InputValue>>;
  readonly ofType?: Maybe<__Type>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  Scalar = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  Object = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  Interface = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  Union = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  Enum = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  InputObject = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  List = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NonNull = 'NON_NULL'
}

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  readonly __typename?: '__Field';
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly args: ReadonlyArray<__InputValue>;
  readonly type: __Type;
  readonly isDeprecated: Scalars['Boolean'];
  readonly deprecationReason?: Maybe<Scalars['String']>;
};


/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  readonly __typename?: '__InputValue';
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly type: __Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  readonly defaultValue?: Maybe<Scalars['String']>;
  readonly isDeprecated: Scalars['Boolean'];
  readonly deprecationReason?: Maybe<Scalars['String']>;
};

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  readonly __typename?: '__EnumValue';
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly isDeprecated: Scalars['Boolean'];
  readonly deprecationReason?: Maybe<Scalars['String']>;
};

export type AppQueryVariables = Exact<{
  portalSlug: Scalars['String'];
}>;


export type AppQuery = (
  { readonly __typename?: 'Query' }
  & { readonly portal?: Maybe<(
    { readonly __typename?: 'Portal' }
    & App_PortalFragment
    & Welcome_PortalFragment
    & Steps_PortalFragment
  )> }
);

export type Tracking_ApplicationFragment = (
  { readonly __typename?: 'Application' }
  & Pick<Application, 'remarketingScript' | 'conversionScript'>
);

export type App_ApplicationFragment = (
  { readonly __typename?: 'Application' }
  & Pick<Application, 'id'>
  & { readonly testMode: (
    { readonly __typename?: 'TestMode' }
    & Pick<TestMode, 'application' | 'bankStatements'>
  ) }
  & Cart_ApplicationFragment
  & Payment_ApplicationFragment
  & ImportantInfo_ApplicationFragment
  & Agreement_ApplicationFragment
  & Signature_ApplicationFragment
  & Steps_ApplicationFragment
  & Welcome_ApplicationFragment
  & Tracking_ApplicationFragment
);

export type App_PortalFragment = (
  { readonly __typename?: 'Portal' }
  & Pick<Portal, 'slug' | 'tradingName' | 'fullTradingName' | 'isStudio19' | 'requireDealer' | 'myLastApplicationId' | 'themeSlug'>
  & { readonly dealers?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'Dealer' }
    & DealerFragment
  )>>, readonly myApplication?: Maybe<(
    { readonly __typename?: 'Application' }
    & App_ApplicationFragment
  )> }
  & PortalBanner_PortalFragment
  & Footer_PortalFragment
  & Steps_PortalFragment
  & PortalAnalytics_PortalFragment
);

export type DealerFragment = (
  { readonly __typename?: 'Dealer' }
  & Pick<Dealer, 'id' | 'name' | 'city' | 'state'>
);

export type CancelApplicationMutationVariables = Exact<{
  input: CancelApplicationInput;
}>;


export type CancelApplicationMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly cancelApplication?: Maybe<(
    { readonly __typename?: 'CancelApplicationPayload' }
    & { readonly portal?: Maybe<(
      { readonly __typename?: 'Portal' }
      & Pick<Portal, 'slug'>
      & { readonly myApplication?: Maybe<(
        { readonly __typename?: 'Application' }
        & Pick<Application, 'id'>
      )> }
    )> }
  )> }
);

export type RecordInteractionMutationVariables = Exact<{
  input: RecordInteractionInput;
}>;


export type RecordInteractionMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly recordInteraction?: Maybe<(
    { readonly __typename?: 'RecordInteractionPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
    )>, readonly errors?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type RestartApplicationMutationVariables = Exact<{
  input: RestartApplicationInput;
}>;


export type RestartApplicationMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly restartApplication?: Maybe<(
    { readonly __typename?: 'RestartApplicationPayload' }
    & { readonly portal?: Maybe<(
      { readonly __typename?: 'Portal' }
      & Pick<Portal, 'slug'>
      & { readonly myApplication?: Maybe<(
        { readonly __typename?: 'Application' }
        & CartChangedApplicationFragment
        & App_ApplicationFragment
      )> }
      & App_PortalFragment
    )>, readonly errors?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type PrivacyPolicyQueryVariables = Exact<{
  portalSlug: Scalars['String'];
}>;


export type PrivacyPolicyQuery = (
  { readonly __typename?: 'Query' }
  & { readonly portal?: Maybe<(
    { readonly __typename?: 'Portal' }
    & Pick<Portal, 'slug'>
    & PrivacyPolicyFragment
  )> }
);

export type PrivacyPolicyFragment = (
  { readonly __typename?: 'Portal' }
  & { readonly policies: (
    { readonly __typename?: 'PortalPolicies' }
    & { readonly privacy?: Maybe<(
      { readonly __typename?: 'PolicyContent' }
      & Pick<PolicyContent, 'title' | 'content'>
    )> }
  ) }
);

export type ProtectionPlanInformationQueryVariables = Exact<{
  portalSlug: Scalars['String'];
}>;


export type ProtectionPlanInformationQuery = (
  { readonly __typename?: 'Query' }
  & { readonly portal?: Maybe<(
    { readonly __typename?: 'Portal' }
    & Pick<Portal, 'slug'>
    & ProtectionPlanInformationFragment
  )> }
);

export type ProtectionPlanInformationFragment = (
  { readonly __typename?: 'Portal' }
  & { readonly policies: (
    { readonly __typename?: 'PortalPolicies' }
    & { readonly protectionPlan?: Maybe<(
      { readonly __typename?: 'PolicyContent' }
      & Pick<PolicyContent, 'title' | 'content'>
    )> }
  ) }
);

export type TermsAndConditionsQueryVariables = Exact<{
  portalSlug: Scalars['String'];
}>;


export type TermsAndConditionsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly portal?: Maybe<(
    { readonly __typename?: 'Portal' }
    & Pick<Portal, 'slug'>
    & TermsAndConditionsFragment
  )> }
);

export type TermsAndConditionsFragment = (
  { readonly __typename?: 'Portal' }
  & { readonly policies: (
    { readonly __typename?: 'PortalPolicies' }
    & { readonly terms?: Maybe<(
      { readonly __typename?: 'PolicyContent' }
      & Pick<PolicyContent, 'title' | 'content'>
    )> }
  ) }
);

export type Steps_PortalFragment = (
  { readonly __typename?: 'Portal' }
  & Pick<Portal, 'slug' | 'myLastApplicationId'>
  & { readonly myApplication?: Maybe<(
    { readonly __typename?: 'Application' }
    & Steps_ApplicationFragment
  )> }
);

export type Steps_ApplicationFragment = (
  { readonly __typename?: 'Application' }
  & Pick<Application, 'id' | 'alternativeContactRequired' | 'bankStatementsRequired' | 'dateSubmitted' | 'importantInfoRead'>
  & { readonly inStore?: Maybe<(
    { readonly __typename?: 'InStore' }
    & Pick<InStore, 'storeName'>
  )>, readonly validation: (
    { readonly __typename?: 'ApplicationValidation' }
    & { readonly cart?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'messages'>
    )>>, readonly customer?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'messages'>
    )>>, readonly address?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'messages'>
    )>>, readonly bankStatements?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'messages'>
    )>>, readonly payment?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'messages'>
    )>>, readonly agreement?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'messages'>
    )>> }
  ), readonly customer: (
    { readonly __typename?: 'Customer' }
    & Pick<Customer, 'email' | 'altContactName'>
  ), readonly address: (
    { readonly __typename?: 'ApplicationAddress' }
    & { readonly residentialAddress?: Maybe<(
      { readonly __typename?: 'Address' }
      & Pick<Address, 'validated'>
    )> }
  ), readonly income: (
    { readonly __typename?: 'Income' }
    & Pick<Income, 'workBasis'>
  ), readonly bankStatements: (
    { readonly __typename?: 'BankStatements' }
    & Pick<BankStatements, 'result'>
  ) }
);

export type SetTestDataStateMutationVariables = Exact<{
  input: SetTestDataStateInput;
}>;


export type SetTestDataStateMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly setTestDataState?: Maybe<(
    { readonly __typename?: 'SetTestDataStatePayload' }
    & { readonly portal?: Maybe<(
      { readonly __typename?: 'Portal' }
      & Pick<Portal, 'slug'>
      & { readonly myApplication?: Maybe<(
        { readonly __typename?: 'Application' }
        & { readonly customer: (
          { readonly __typename?: 'Customer' }
          & AboutMeForm_CustomerFragment
        ) }
        & App_ApplicationFragment
      )> }
    )> }
  )> }
);

export type DevFooter_ApplicationFragment = (
  { readonly __typename?: 'Application' }
  & { readonly testMode: (
    { readonly __typename?: 'TestMode' }
    & Pick<TestMode, 'application' | 'bankStatements'>
  ) }
);

export type Footer_PortalFragment = (
  { readonly __typename?: 'Portal' }
  & Pick<Portal, 'tradingName'>
  & { readonly policies: (
    { readonly __typename?: 'PortalPolicies' }
    & Pick<PortalPolicies, 'privacyPolicyUrl' | 'websiteTermsUrl'>
  ) }
);

export type PortalBanner_ApplicationFragment = (
  { readonly __typename?: 'Application' }
  & { readonly inStore?: Maybe<(
    { readonly __typename?: 'InStore' }
    & Pick<InStore, 'storeName' | 'storeLogo'>
  )> }
);

export type PortalBanner_PortalFragment = (
  { readonly __typename?: 'Portal' }
  & Pick<Portal, 'title' | 'portalUrl' | 'addProductUrl' | 'portalLogoUrl'>
);

export type PortalAnalytics_PortalFragment = (
  { readonly __typename?: 'Portal' }
  & { readonly analytics: (
    { readonly __typename?: 'PortalAnalytics' }
    & Pick<PortalAnalytics, 'trackingId' | 'linkedDomain' | 'sendAllEvents' | 'adwordsConversionId' | 'conversionScript'>
  ) }
);

export type AboutMeQueryVariables = Exact<{
  portalSlug: Scalars['String'];
}>;


export type AboutMeQuery = (
  { readonly __typename?: 'Query' }
  & { readonly portal?: Maybe<(
    { readonly __typename?: 'Portal' }
    & Pick<Portal, 'slug'>
    & { readonly myApplication?: Maybe<(
      { readonly __typename?: 'Application' }
      & AboutMeFragment
    )> }
  )>, readonly salutationSourceEnum?: Maybe<(
    { readonly __typename?: '__Type' }
    & EnumChoicesTypeInfoFragment
  )> }
);

export type UpdateCustomerMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;


export type UpdateCustomerMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly updateCustomer?: Maybe<(
    { readonly __typename?: 'UpdateCustomerPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { readonly portal: (
        { readonly __typename?: 'Portal' }
        & Pick<Portal, 'slug'>
      ), readonly customer: (
        { readonly __typename?: 'Customer' }
        & CustomerFragment
      ) }
      & AboutMeFragment
    )>, readonly errors?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>> }
  )> }
);

export type AboutMeFragment = (
  { readonly __typename?: 'Application' }
  & Pick<Application, 'id' | 'marketingConsent'>
  & { readonly inStore?: Maybe<(
    { readonly __typename?: 'InStore' }
    & Pick<InStore, 'storeName'>
  )>, readonly customer: (
    { readonly __typename?: 'Customer' }
    & AboutMeForm_CustomerFragment
  ), readonly validation: (
    { readonly __typename?: 'ApplicationValidation' }
    & AboutMeForm_ValidationFragment
  ) }
);

export type AboutMeForm_CustomerFragment = (
  { readonly __typename?: 'Customer' }
  & Pick<Customer, 'title' | 'firstName' | 'lastName' | 'email' | 'phoneMobile' | 'dob' | 'licenceNo'>
);

export type AboutMeForm_ValidationFragment = (
  { readonly __typename?: 'ApplicationValidation' }
  & { readonly customer?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'ErrorType' }
    & Pick<ErrorType, 'field' | 'messages'>
  )>> }
);

export type CustomerFragment = (
  { readonly __typename?: 'Customer' }
  & Pick<Customer, 'title' | 'firstName' | 'lastName' | 'email' | 'phoneMobile' | 'dob' | 'licenceNo' | 'altContactName' | 'altContactPhone'>
);

export type AdditionalInfoQueryVariables = Exact<{
  portalSlug: Scalars['String'];
}>;


export type AdditionalInfoQuery = (
  { readonly __typename?: 'Query' }
  & { readonly portal?: Maybe<(
    { readonly __typename?: 'Portal' }
    & { readonly myApplication?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { readonly customer: (
        { readonly __typename?: 'Customer' }
        & AdditionalInfoFragment
      ) }
    )> }
  )> }
);

export type AdditionalInfoFragment = (
  { readonly __typename?: 'Customer' }
  & Pick<Customer, 'workComments'>
);

export type AddressQueryVariables = Exact<{
  portalSlug: Scalars['String'];
}>;


export type AddressQuery = (
  { readonly __typename?: 'Query' }
  & { readonly portal?: Maybe<(
    { readonly __typename?: 'Portal' }
    & Pick<Portal, 'slug'>
    & { readonly myApplication?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & ApplicationAddressFragment
    )> }
  )>, readonly homeTypeSourceEnum?: Maybe<(
    { readonly __typename?: '__Type' }
    & EnumChoicesTypeInfoFragment
  )> }
);

export type ApplicationAddressFragment = (
  { readonly __typename?: 'Application' }
  & { readonly address: (
    { readonly __typename?: 'ApplicationAddress' }
    & ApplicationAddressFieldsFragment
    & ApplicationDeliveryAddressFieldsFragment
  ), readonly livingSituation: (
    { readonly __typename?: 'ApplicationLivingSituation' }
    & ApplicationLivingSituationFieldsFragment
  ) }
);

export type ApplicationAddressFieldsFragment = (
  { readonly __typename?: 'ApplicationAddress' }
  & { readonly residentialAddress?: Maybe<(
    { readonly __typename?: 'Address' }
    & AddressFieldsFragment
  )> }
);

export type ApplicationDeliveryAddressFieldsFragment = (
  { readonly __typename?: 'ApplicationAddress' }
  & Pick<ApplicationAddress, 'deliveryAddressType'>
  & { readonly deliveryAddress?: Maybe<(
    { readonly __typename?: 'Address' }
    & AddressFieldsFragment
  )>, readonly dealer?: Maybe<(
    { readonly __typename?: 'Dealer' }
    & DealerFragment
  )> }
);

export type AddressFieldsFragment = (
  { readonly __typename?: 'Address' }
  & Pick<Address, 'fullAddress' | 'valid' | 'validated' | 'street' | 'suburb' | 'state' | 'postcode'>
);

export type ApplicationLivingSituationFieldsFragment = (
  { readonly __typename?: 'ApplicationLivingSituation' }
  & Pick<ApplicationLivingSituation, 'homeType' | 'homeDurationYears' | 'homeDurationMonths'>
);

export type EnumChoicesTypeInfoFragment = (
  { readonly __typename?: '__Type' }
  & Pick<__Type, 'kind' | 'name'>
  & { readonly enumValues?: Maybe<ReadonlyArray<(
    { readonly __typename?: '__EnumValue' }
    & Pick<__EnumValue, 'name' | 'description' | 'isDeprecated'>
  )>> }
);

export type UpdateApplicationAddressMutationVariables = Exact<{
  residential: SetResidentialAddressInput;
  situation: SetLivingSituationInput;
}>;


export type UpdateApplicationAddressMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly setResidentialAddress?: Maybe<(
    { readonly __typename?: 'SetResidentialAddressPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { readonly portal: (
        { readonly __typename?: 'Portal' }
        & Pick<Portal, 'slug'>
      ), readonly address: (
        { readonly __typename?: 'ApplicationAddress' }
        & ApplicationAddressFieldsFragment
      ) }
    )>, readonly errors?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )>, readonly setLivingSituation?: Maybe<(
    { readonly __typename?: 'SetLivingSituationPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { readonly portal: (
        { readonly __typename?: 'Portal' }
        & Pick<Portal, 'slug'>
      ), readonly livingSituation: (
        { readonly __typename?: 'ApplicationLivingSituation' }
        & ApplicationLivingSituationFieldsFragment
      ) }
    )>, readonly errors?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateApplicationDeliveryAddressMutationVariables = Exact<{
  delivery: SetDeliveryAddressInput;
}>;


export type UpdateApplicationDeliveryAddressMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly setDeliveryAddress?: Maybe<(
    { readonly __typename?: 'SetDeliveryAddressPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { readonly portal: (
        { readonly __typename?: 'Portal' }
        & Pick<Portal, 'slug'>
      ), readonly address: (
        { readonly __typename?: 'ApplicationAddress' }
        & ApplicationDeliveryAddressFieldsFragment
      ) }
    )>, readonly errors?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type UpdateApplicationDealerMutationVariables = Exact<{
  dealer: SetDeliveryDealerInput;
}>;


export type UpdateApplicationDealerMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly setDeliveryDealer?: Maybe<(
    { readonly __typename?: 'SetDeliveryDealerPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { readonly portal: (
        { readonly __typename?: 'Portal' }
        & Pick<Portal, 'slug'>
      ), readonly address: (
        { readonly __typename?: 'ApplicationAddress' }
        & ApplicationDeliveryAddressFieldsFragment
      ) }
    )>, readonly errors?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type ValidateAddressMutationVariables = Exact<{
  input: ValidateAddressInput;
}>;


export type ValidateAddressMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly validateAddress?: Maybe<(
    { readonly __typename?: 'ValidateAddressPayload' }
    & { readonly address?: Maybe<(
      { readonly __typename?: 'Address' }
      & Pick<Address, 'fullAddress' | 'valid' | 'validated' | 'street' | 'suburb' | 'state' | 'postcode'>
    )> }
  )> }
);

export type Agreement_ApplicationFragment = (
  { readonly __typename?: 'Application' }
  & Pick<Application, 'marketingConsent' | 'acceptedAgreement'>
  & { readonly validation: (
    { readonly __typename?: 'ApplicationValidation' }
    & { readonly agreement?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>> }
  ) }
);

export type PortalTermsQueryVariables = Exact<{
  portalSlug: Scalars['String'];
}>;


export type PortalTermsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly portal?: Maybe<(
    { readonly __typename?: 'Portal' }
    & Pick<Portal, 'slug'>
    & { readonly policies: (
      { readonly __typename?: 'PortalPolicies' }
      & { readonly terms?: Maybe<(
        { readonly __typename?: 'PolicyContent' }
        & Pick<PolicyContent, 'content'>
      )> }
    ) }
  )> }
);

export type Agreement_PortalpoliciesFragment = (
  { readonly __typename?: 'PortalPolicies' }
  & { readonly terms?: Maybe<(
    { readonly __typename?: 'PolicyContent' }
    & Pick<PolicyContent, 'content'>
  )> }
);

export type SetAcceptedAgreementMutationVariables = Exact<{
  input: SetAcceptedAgreementInput;
}>;


export type SetAcceptedAgreementMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly setAcceptedAgreement?: Maybe<(
    { readonly __typename?: 'SetAcceptedAgreementPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & Agreement_ApplicationFragment
    )> }
  )> }
);

export type AltContactQueryVariables = Exact<{
  portalSlug: Scalars['String'];
}>;


export type AltContactQuery = (
  { readonly __typename?: 'Query' }
  & { readonly portal?: Maybe<(
    { readonly __typename?: 'Portal' }
    & { readonly myApplication?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { readonly customer: (
        { readonly __typename?: 'Customer' }
        & AltContactFragment
      ) }
    )> }
  )> }
);

export type AltContactFragment = (
  { readonly __typename?: 'Customer' }
  & Pick<Customer, 'altContactName' | 'altContactPhone'>
);

export type BankStatementsQueryVariables = Exact<{
  portalSlug: Scalars['String'];
}>;


export type BankStatementsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly portal?: Maybe<(
    { readonly __typename?: 'Portal' }
    & { readonly myApplication?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { readonly bankStatements: (
        { readonly __typename?: 'BankStatements' }
        & BankStatementsFragment
      ) }
    )> }
  )> }
);

export type BankStatements_ApplicationFragment = (
  { readonly __typename?: 'Application' }
  & { readonly validation: (
    { readonly __typename?: 'ApplicationValidation' }
    & { readonly bankStatements?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>> }
  ) }
);

export type BankStatementsFragment = (
  { readonly __typename?: 'BankStatements' }
  & Pick<BankStatements, 'embedURL' | 'result' | 'reference' | 'errorCode' | 'errorMessage'>
);

export type SkipBankStatementsMutationVariables = Exact<{
  input: SkipBankStatementsInput;
}>;


export type SkipBankStatementsMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly skipBankStatements?: Maybe<(
    { readonly __typename?: 'SkipBankStatementsPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { readonly bankStatements: (
        { readonly __typename?: 'BankStatements' }
        & BankStatementsFragment
      ) }
      & BankStatements_ApplicationFragment
    )> }
  )> }
);

export type RecordBankStatementsResultMutationVariables = Exact<{
  resultInput: RecordBankStatementsResultInput;
  eventInput: LogBankStatementsEventInput;
}>;


export type RecordBankStatementsResultMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly recordBankStatementsResult?: Maybe<(
    { readonly __typename?: 'RecordBankStatementsResultPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { readonly bankStatements: (
        { readonly __typename?: 'BankStatements' }
        & BankStatementsFragment
      ) }
      & BankStatements_ApplicationFragment
    )> }
  )>, readonly logBankStatementsEvent?: Maybe<(
    { readonly __typename?: 'LogBankStatementsEventPayload' }
    & Pick<LogBankStatementsEventPayload, 'eventId'>
  )> }
);

export type LogBankStatementsEventMutationVariables = Exact<{
  input: LogBankStatementsEventInput;
}>;


export type LogBankStatementsEventMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly logBankStatementsEvent?: Maybe<(
    { readonly __typename?: 'LogBankStatementsEventPayload' }
    & Pick<LogBankStatementsEventPayload, 'eventId'>
  )> }
);

export type Cart_ApplicationFragment = (
  { readonly __typename?: 'Application' }
  & Pick<Application, 'hasMixedRates' | 'rebateMonths' | 'importantInfoRead'>
  & { readonly portal: (
    { readonly __typename?: 'Portal' }
    & Pick<Portal, 'slug' | 'portalUrl'>
  ), readonly pricing: (
    { readonly __typename?: 'ApplicationPricing' }
    & Pick<ApplicationPricing, 'initialPayment' | 'ongoingPayment' | 'setupFee' | 'totalFreight'>
  ), readonly rateOptions: ReadonlyArray<(
    { readonly __typename?: 'RateOption' }
    & RateOptionFragment
  )>, readonly inStore?: Maybe<(
    { readonly __typename?: 'InStore' }
    & Pick<InStore, 'storeName'>
  )>, readonly items?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'Item' }
    & CartItemFragment
  )>>, readonly suggestedItems?: Maybe<ReadonlyArray<(
    { readonly __typename?: 'SuggestedItem' }
    & SuggestedItemFragment
  )>>, readonly validation: (
    { readonly __typename?: 'ApplicationValidation' }
    & { readonly cart?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>> }
  ), readonly importantInfo: (
    { readonly __typename?: 'ApplicationImportantInfo' }
    & Pick<ApplicationImportantInfo, 'freightHeading'>
  ) }
  & ProtectionPlan_ApplicationFragment
);

export type CartItemFragment = (
  { readonly __typename?: 'Item' }
  & Pick<Item, 'id' | 'qty' | 'title' | 'subtitle' | 'imageUrl' | 'maxQty' | 'condition' | 'category' | 'conversionId' | 'conversionValue' | 'startValue'>
  & { readonly selectedRate: (
    { readonly __typename?: 'Rate' }
    & Pick<Rate, 'rentalMonthly'>
    & PurchasePricing_RateFragment
  ) }
  & PrelovedItemFragment
  & NewItemFragment
);

export type NewItemFragment = (
  { readonly __typename?: 'Item' }
  & Pick<Item, 'newProductDescription' | 'modelNumber'>
);

export type PrelovedItemFragment = (
  { readonly __typename?: 'Item' }
  & Pick<Item, 'prelovedConditionReport' | 'modelNumber' | 'serialNumber'>
);

export type SuggestedItemFragment = (
  { readonly __typename?: 'SuggestedItem' }
  & Pick<SuggestedItem, 'id' | 'title' | 'subtitle' | 'imageUrl' | 'url' | 'rentalMonthly'>
);

export type ProtectionPlan_ApplicationFragment = (
  { readonly __typename?: 'Application' }
  & Pick<Application, 'id' | 'includesProtectionPlan'>
  & { readonly pricing: (
    { readonly __typename?: 'ApplicationPricing' }
    & Pick<ApplicationPricing, 'protectionPlan' | 'initialPayment' | 'ongoingPayment'>
  ) }
);

export type PurchasePricing_RateFragment = (
  { readonly __typename?: 'Rate' }
  & Pick<Rate, 'rentalMonthly'>
  & { readonly purchasePricing: ReadonlyArray<(
    { readonly __typename?: 'PurchasePrice' }
    & PurchasePriceFragment
  )> }
);

export type PurchasePriceFragment = (
  { readonly __typename?: 'PurchasePrice' }
  & Pick<PurchasePrice, 'month' | 'amount'>
);

export type RateOptionFragment = (
  { readonly __typename?: 'RateOption' }
  & Pick<RateOption, 'rentalMonthly' | 'minMonths' | 'rebateMonths'>
);

export type CartChangedApplicationFragment = (
  { readonly __typename?: 'Application' }
  & Pick<Application, 'id' | 'rebateMonths' | 'hasMixedRates'>
  & { readonly portal: (
    { readonly __typename?: 'Portal' }
    & Pick<Portal, 'slug'>
  ) }
  & Cart_ApplicationFragment
  & Steps_ApplicationFragment
  & ProtectionPlan_ApplicationFragment
  & Signature_ApplicationFragment
);

export type UpdateProtectionPlanMutationVariables = Exact<{
  portalSlug: Scalars['String'];
  input: UpdateProtectionPlanInput;
}>;


export type UpdateProtectionPlanMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly updateProtectionPlan?: Maybe<(
    { readonly __typename?: 'UpdateProtectionPlanPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & CartChangedApplicationFragment
    )> }
  )> }
);

export type UpdateItemMutationVariables = Exact<{
  input: UpdateItemInput;
}>;


export type UpdateItemMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly updateItem?: Maybe<(
    { readonly __typename?: 'UpdateItemPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & CartChangedApplicationFragment
    )>, readonly errors?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type RemoveItemMutationVariables = Exact<{
  input: RemoveItemInput;
}>;


export type RemoveItemMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly removeItem?: Maybe<(
    { readonly __typename?: 'RemoveItemPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & CartChangedApplicationFragment
    )>, readonly errors?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type AddSuggestedItemMutationVariables = Exact<{
  input: AddSuggestedItemInput;
}>;


export type AddSuggestedItemMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly addSuggestedItem?: Maybe<(
    { readonly __typename?: 'AddSuggestedItemPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & CartChangedApplicationFragment
    )> }
  )> }
);

export type SetRebateMonthsMutationVariables = Exact<{
  input: SetRebateMonthsInput;
}>;


export type SetRebateMonthsMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly setRebateMonths?: Maybe<(
    { readonly __typename?: 'SetRebateMonthsPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & CartChangedApplicationFragment
    )> }
  )> }
);

export type ClearApplicationWarningsMutationVariables = Exact<{
  input: ClearApplicationWarningsInput;
}>;


export type ClearApplicationWarningsMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly clearApplicationWarnings?: Maybe<(
    { readonly __typename?: 'ClearApplicationWarningsPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id' | 'hasMixedRates'>
    )> }
  )> }
);

export type ImportantInfoQueryVariables = Exact<{
  portalSlug: Scalars['String'];
}>;


export type ImportantInfoQuery = (
  { readonly __typename?: 'Query' }
  & { readonly portal?: Maybe<(
    { readonly __typename?: 'Portal' }
    & Pick<Portal, 'slug'>
    & { readonly myApplication?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & ImportantInfoConditionsFragment
    )> }
  )>, readonly residentStatusEnum?: Maybe<(
    { readonly __typename?: '__Type' }
    & EnumChoicesTypeInfoFragment
  )> }
);

export type ImportantInfoConditionsFragment = (
  { readonly __typename?: 'Application' }
  & Pick<Application, 'residentStatus'>
  & { readonly importantInfo: (
    { readonly __typename?: 'ApplicationImportantInfo' }
    & ImportantInfoFragment
  ) }
);

export type ImportantInfoFragment = (
  { readonly __typename?: 'ApplicationImportantInfo' }
  & Pick<ApplicationImportantInfo, 'freightHeading' | 'freightHtml' | 'rentalProcessHtml' | 'ageAndResidencyHtml' | 'minPeriodText' | 'namedApplicantsText'>
  & { readonly productTypeInfo: ReadonlyArray<(
    { readonly __typename?: 'ProductTypeInfo' }
    & Pick<ProductTypeInfo, 'title' | 'content'>
  )> }
);

export type SetImportantInfoReadMutationVariables = Exact<{
  input: SetImportantInfoReadInput;
}>;


export type SetImportantInfoReadMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly setImportantInfoRead?: Maybe<(
    { readonly __typename?: 'SetImportantInfoReadPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & ImportantInfo_ApplicationFragment
    )> }
  )> }
);

export type ImportantInfo_ApplicationFragment = (
  { readonly __typename?: 'Application' }
  & Pick<Application, 'id' | 'importantInfoRead'>
  & { readonly portal: (
    { readonly __typename?: 'Portal' }
    & Pick<Portal, 'slug'>
  ) }
);

export type SetResidentStatusMutationVariables = Exact<{
  input: SetResidentStatusInput;
}>;


export type SetResidentStatusMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly setResidentStatus?: Maybe<(
    { readonly __typename?: 'SetResidentStatusPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id' | 'residentStatus'>
    )> }
  )> }
);

export type IncomeQueryVariables = Exact<{
  portalSlug: Scalars['String'];
}>;


export type IncomeQuery = (
  { readonly __typename?: 'Query' }
  & Pick<Query, 'occupations'>
  & { readonly portal?: Maybe<(
    { readonly __typename?: 'Portal' }
    & Pick<Portal, 'slug'>
    & { readonly myApplication?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id' | 'householdFieldsRequired'>
      & { readonly income: (
        { readonly __typename?: 'Income' }
        & IncomeFragment
      ) }
    )> }
  )>, readonly workBasisEnum?: Maybe<(
    { readonly __typename?: '__Type' }
    & EnumChoicesTypeInfoFragment
  )>, readonly livingExpensesEnum?: Maybe<(
    { readonly __typename?: '__Type' }
    & EnumChoicesTypeInfoFragment
  )> }
);

export type IncomeFragment = (
  { readonly __typename?: 'Income' }
  & Pick<Income, 'workBasis' | 'occupation' | 'employer' | 'phoneWork' | 'workTimeYears' | 'workTimeMonths' | 'livingExpensesPercentage'>
);

export type UpdateIncomeMutationVariables = Exact<{
  input: UpdateIncomeInput;
}>;


export type UpdateIncomeMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly updateIncome?: Maybe<(
    { readonly __typename?: 'UpdateIncomePayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & { readonly portal: (
        { readonly __typename?: 'Portal' }
        & Pick<Portal, 'slug'>
      ), readonly income: (
        { readonly __typename?: 'Income' }
        & IncomeFragment
      ) }
    )>, readonly errors?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>> }
  )> }
);

export type Payment_ApplicationFragment = (
  { readonly __typename?: 'Application' }
  & Pick<Application, 'hasDirectPostFailed' | 'paymentSkipped'>
  & { readonly portal: (
    { readonly __typename?: 'Portal' }
    & Pick<Portal, 'slug'>
  ), readonly validation: (
    { readonly __typename?: 'ApplicationValidation' }
    & { readonly payment?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'messages'>
    )>> }
  ), readonly payment?: Maybe<(
    { readonly __typename?: 'CreditCard' }
    & CreditCardFragment
  )> }
);

export type InitiateRentalDirectPostMutationVariables = Exact<{
  input: InitiateRentalDirectPostInput;
}>;


export type InitiateRentalDirectPostMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly initiateRentalDirectPost?: Maybe<(
    { readonly __typename?: 'InitiateRentalDirectPostPayload' }
    & { readonly nabCrn?: Maybe<(
      { readonly __typename?: 'NabCrn' }
      & NabCrnFragment
    )>, readonly errors?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type SkipPaymentMutationVariables = Exact<{
  input: SkipPaymentInput;
}>;


export type SkipPaymentMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly skipPayment?: Maybe<(
    { readonly __typename?: 'SkipPaymentPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & Payment_ApplicationFragment
    )> }
  )> }
);

export type PortalCreditGuideQueryVariables = Exact<{
  portalSlug: Scalars['String'];
}>;


export type PortalCreditGuideQuery = (
  { readonly __typename?: 'Query' }
  & { readonly portal?: Maybe<(
    { readonly __typename?: 'Portal' }
    & Pick<Portal, 'slug'>
    & { readonly policies: (
      { readonly __typename?: 'PortalPolicies' }
      & Pick<PortalPolicies, 'creditGuideUrl'>
    ) }
  )> }
);

export type SignatureQueryVariables = Exact<{
  portalSlug: Scalars['String'];
}>;


export type SignatureQuery = (
  { readonly __typename?: 'Query' }
  & { readonly portal?: Maybe<(
    { readonly __typename?: 'Portal' }
    & { readonly myApplication?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & Signature_ApplicationFragment
    )> }
  )> }
);

export type Signature_ApplicationFragment = (
  { readonly __typename?: 'Application' }
  & Pick<Application, 'dateSubmitted' | 'digitalSignature' | 'digitalSignatureRequired' | 'remainingSmsSignatureCount'>
  & { readonly customer: (
    { readonly __typename?: 'Customer' }
    & CustomerFragment
  ), readonly address: (
    { readonly __typename?: 'ApplicationAddress' }
    & ApplicationAddressFieldsFragment
  ) }
);

export type SubmitApplicationMutationVariables = Exact<{
  input: SubmitApplicationInput;
}>;


export type SubmitApplicationMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly submitApplication?: Maybe<(
    { readonly __typename?: 'SubmitApplicationPayload' }
    & { readonly portal?: Maybe<(
      { readonly __typename?: 'Portal' }
      & Pick<Portal, 'slug' | 'myLastApplicationId'>
    )>, readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id' | 'dateSubmitted'>
    )>, readonly errors?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type RequestSmsCodeMutationVariables = Exact<{
  input: SendSmsSignatureInput;
}>;


export type RequestSmsCodeMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly sendSmsSignature?: Maybe<(
    { readonly __typename?: 'SendSmsSignature' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'Application' }
      & Pick<Application, 'id'>
      & Signature_ApplicationFragment
    )>, readonly errors?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type ApplicationSummaryQueryVariables = Exact<{
  portalSlug: Scalars['String'];
}>;


export type ApplicationSummaryQuery = (
  { readonly __typename?: 'Query' }
  & { readonly portal?: Maybe<(
    { readonly __typename?: 'Portal' }
    & { readonly myApplication?: Maybe<(
      { readonly __typename?: 'Application' }
      & Summary_ApplicationFragment
    )> }
  )>, readonly salutationSourceEnum?: Maybe<(
    { readonly __typename?: '__Type' }
    & EnumChoicesTypeInfoFragment
  )>, readonly residentStatusEnum?: Maybe<(
    { readonly __typename?: '__Type' }
    & EnumChoicesTypeInfoFragment
  )>, readonly homeTypeSourceEnum?: Maybe<(
    { readonly __typename?: '__Type' }
    & EnumChoicesTypeInfoFragment
  )>, readonly workBasisEnum?: Maybe<(
    { readonly __typename?: '__Type' }
    & EnumChoicesTypeInfoFragment
  )>, readonly livingExpensesEnum?: Maybe<(
    { readonly __typename?: '__Type' }
    & EnumChoicesTypeInfoFragment
  )>, readonly bankStatementsResultEnum?: Maybe<(
    { readonly __typename?: '__Type' }
    & EnumChoicesTypeInfoFragment
  )> }
);

export type Summary_ApplicationFragment = (
  { readonly __typename?: 'Application' }
  & Pick<Application, 'id' | 'residentStatus' | 'alternativeContactRequired' | 'bankStatementsRequired'>
  & { readonly customer: (
    { readonly __typename?: 'Customer' }
    & CustomerFragment
    & AdditionalInfoFragment
  ), readonly income: (
    { readonly __typename?: 'Income' }
    & IncomeFragment
  ), readonly bankStatements: (
    { readonly __typename?: 'BankStatements' }
    & BankStatementsFragment
  ), readonly validation: (
    { readonly __typename?: 'ApplicationValidation' }
    & Pick<ApplicationValidation, 'isValid'>
    & { readonly cart?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>, readonly customer?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>, readonly address?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>, readonly livingSituation?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>, readonly income?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>, readonly bankStatements?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>, readonly payment?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>, readonly agreement?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>> }
  ) }
  & Cart_ApplicationFragment
  & AboutMeFragment
  & ApplicationAddressFragment
  & Payment_ApplicationFragment
  & Agreement_ApplicationFragment
);

export type Welcome_PortalFragment = (
  { readonly __typename?: 'Portal' }
  & Pick<Portal, 'slug' | 'title' | 'portalLogoUrl'>
  & { readonly myApplication?: Maybe<(
    { readonly __typename?: 'Application' }
    & Welcome_ApplicationFragment
  )> }
);

export type Welcome_ApplicationFragment = (
  { readonly __typename?: 'Application' }
  & { readonly inStore?: Maybe<(
    { readonly __typename?: 'InStore' }
    & Pick<InStore, 'storeName' | 'storeLogo' | 'salesPersonName'>
  )> }
);

export type CreditCardFragment = (
  { readonly __typename?: 'CreditCard' }
  & Pick<CreditCard, 'maskedCardNumber' | 'expiryMM' | 'expiryYYYY'>
);

export type NabCrnFragment = (
  { readonly __typename?: 'NabCrn' }
  & Pick<NabCrn, 'crn' | 'timestamp' | 'fingerprint' | 'resultUrl' | 'nabUrl' | 'action' | 'merchant'>
);

export type NabCustomerRecordFragment = (
  { readonly __typename?: 'NabCustomerRecord' }
  & Pick<NabCustomerRecord, 'id' | 'timestamp' | 'crn' | 'fingerprint' | 'merchant' | 'resultUrl' | 'nabUrl' | 'action'>
);

export const PortalBanner_PortalFragmentDoc = gql`
    fragment PortalBanner_portal on Portal {
  title
  portalUrl
  addProductUrl
  portalLogoUrl
}
    `;
export const Footer_PortalFragmentDoc = gql`
    fragment Footer_portal on Portal {
  tradingName
  policies {
    privacyPolicyUrl
    websiteTermsUrl
  }
}
    `;
export const Steps_ApplicationFragmentDoc = gql`
    fragment Steps_application on Application {
  id
  alternativeContactRequired
  bankStatementsRequired
  inStore {
    storeName
  }
  validation {
    cart {
      messages
    }
    customer {
      messages
    }
    address {
      messages
    }
    bankStatements {
      messages
    }
    payment {
      messages
    }
    agreement {
      messages
    }
  }
  dateSubmitted
  importantInfoRead
  customer {
    email
    altContactName
  }
  address {
    residentialAddress {
      validated
    }
  }
  income {
    workBasis
  }
  bankStatements {
    result
  }
}
    `;
export const Steps_PortalFragmentDoc = gql`
    fragment Steps_portal on Portal {
  slug
  myLastApplicationId
  myApplication {
    ...Steps_application
  }
}
    ${Steps_ApplicationFragmentDoc}`;
export const PortalAnalytics_PortalFragmentDoc = gql`
    fragment PortalAnalytics_portal on Portal {
  analytics {
    trackingId
    linkedDomain
    sendAllEvents
    adwordsConversionId
    conversionScript
  }
}
    `;
export const DealerFragmentDoc = gql`
    fragment Dealer on Dealer {
  id
  name
  city
  state
}
    `;
export const RateOptionFragmentDoc = gql`
    fragment RateOption on RateOption {
  rentalMonthly
  minMonths
  rebateMonths
}
    `;
export const ProtectionPlan_ApplicationFragmentDoc = gql`
    fragment ProtectionPlan_application on Application {
  id
  includesProtectionPlan
  pricing {
    protectionPlan
    initialPayment
    ongoingPayment
  }
}
    `;
export const PurchasePriceFragmentDoc = gql`
    fragment PurchasePrice on PurchasePrice {
  month
  amount
}
    `;
export const PurchasePricing_RateFragmentDoc = gql`
    fragment PurchasePricing_rate on Rate {
  rentalMonthly
  purchasePricing {
    ...PurchasePrice
  }
}
    ${PurchasePriceFragmentDoc}`;
export const PrelovedItemFragmentDoc = gql`
    fragment PrelovedItem on Item {
  prelovedConditionReport
  modelNumber
  serialNumber
}
    `;
export const NewItemFragmentDoc = gql`
    fragment NewItem on Item {
  newProductDescription
  modelNumber
}
    `;
export const CartItemFragmentDoc = gql`
    fragment CartItem on Item {
  id
  qty
  title
  subtitle
  imageUrl
  maxQty
  condition
  category
  conversionId
  conversionValue
  selectedRate {
    rentalMonthly
    ...PurchasePricing_rate
  }
  startValue
  ...PrelovedItem
  ...NewItem
}
    ${PurchasePricing_RateFragmentDoc}
${PrelovedItemFragmentDoc}
${NewItemFragmentDoc}`;
export const SuggestedItemFragmentDoc = gql`
    fragment SuggestedItem on SuggestedItem {
  id
  title
  subtitle
  imageUrl
  url
  rentalMonthly
}
    `;
export const Cart_ApplicationFragmentDoc = gql`
    fragment Cart_application on Application {
  portal {
    slug
    portalUrl
  }
  pricing {
    initialPayment
    ongoingPayment
    setupFee
    totalFreight
  }
  hasMixedRates
  rebateMonths
  rateOptions {
    ...RateOption
  }
  ...ProtectionPlan_application
  inStore {
    storeName
  }
  items {
    ...CartItem
  }
  suggestedItems {
    ...SuggestedItem
  }
  validation {
    cart {
      field
      messages
    }
  }
  importantInfoRead
  importantInfo {
    freightHeading
  }
}
    ${RateOptionFragmentDoc}
${ProtectionPlan_ApplicationFragmentDoc}
${CartItemFragmentDoc}
${SuggestedItemFragmentDoc}`;
export const CreditCardFragmentDoc = gql`
    fragment CreditCard on CreditCard {
  maskedCardNumber
  expiryMM
  expiryYYYY
}
    `;
export const Payment_ApplicationFragmentDoc = gql`
    fragment Payment_application on Application {
  portal {
    slug
  }
  validation {
    payment {
      messages
    }
  }
  payment {
    ...CreditCard
  }
  hasDirectPostFailed
  paymentSkipped
}
    ${CreditCardFragmentDoc}`;
export const ImportantInfo_ApplicationFragmentDoc = gql`
    fragment ImportantInfo_application on Application {
  id
  importantInfoRead
  portal {
    slug
  }
}
    `;
export const Agreement_ApplicationFragmentDoc = gql`
    fragment Agreement_application on Application {
  marketingConsent
  acceptedAgreement
  validation {
    agreement {
      field
      messages
    }
  }
}
    `;
export const CustomerFragmentDoc = gql`
    fragment Customer on Customer {
  title
  firstName
  lastName
  email
  phoneMobile
  dob
  licenceNo
  altContactName
  altContactPhone
}
    `;
export const AddressFieldsFragmentDoc = gql`
    fragment AddressFields on Address {
  fullAddress
  valid
  validated
  street
  suburb
  state
  postcode
}
    `;
export const ApplicationAddressFieldsFragmentDoc = gql`
    fragment ApplicationAddressFields on ApplicationAddress {
  residentialAddress {
    ...AddressFields
  }
}
    ${AddressFieldsFragmentDoc}`;
export const Signature_ApplicationFragmentDoc = gql`
    fragment Signature_application on Application {
  dateSubmitted
  digitalSignature
  digitalSignatureRequired
  remainingSmsSignatureCount
  customer {
    ...Customer
  }
  address {
    ...ApplicationAddressFields
  }
}
    ${CustomerFragmentDoc}
${ApplicationAddressFieldsFragmentDoc}`;
export const Welcome_ApplicationFragmentDoc = gql`
    fragment Welcome_application on Application {
  inStore {
    storeName
    storeLogo
    salesPersonName
  }
}
    `;
export const Tracking_ApplicationFragmentDoc = gql`
    fragment Tracking_application on Application {
  remarketingScript
  conversionScript
}
    `;
export const App_ApplicationFragmentDoc = gql`
    fragment App_application on Application {
  id
  ...Cart_application
  ...Payment_application
  ...ImportantInfo_application
  ...Agreement_application
  ...Signature_application
  ...Steps_application
  ...Welcome_application
  ...Tracking_application
  testMode {
    application
    bankStatements
  }
}
    ${Cart_ApplicationFragmentDoc}
${Payment_ApplicationFragmentDoc}
${ImportantInfo_ApplicationFragmentDoc}
${Agreement_ApplicationFragmentDoc}
${Signature_ApplicationFragmentDoc}
${Steps_ApplicationFragmentDoc}
${Welcome_ApplicationFragmentDoc}
${Tracking_ApplicationFragmentDoc}`;
export const App_PortalFragmentDoc = gql`
    fragment App_portal on Portal {
  slug
  tradingName
  fullTradingName
  isStudio19
  ...PortalBanner_portal
  ...Footer_portal
  ...Steps_portal
  ...PortalAnalytics_portal
  requireDealer
  dealers {
    ...Dealer
  }
  myLastApplicationId
  myApplication {
    ...App_application
  }
  themeSlug
}
    ${PortalBanner_PortalFragmentDoc}
${Footer_PortalFragmentDoc}
${Steps_PortalFragmentDoc}
${PortalAnalytics_PortalFragmentDoc}
${DealerFragmentDoc}
${App_ApplicationFragmentDoc}`;
export const PrivacyPolicyFragmentDoc = gql`
    fragment PrivacyPolicy on Portal {
  policies {
    privacy {
      title
      content
    }
  }
}
    `;
export const ProtectionPlanInformationFragmentDoc = gql`
    fragment ProtectionPlanInformation on Portal {
  policies {
    protectionPlan {
      title
      content
    }
  }
}
    `;
export const TermsAndConditionsFragmentDoc = gql`
    fragment TermsAndConditions on Portal {
  policies {
    terms {
      title
      content
    }
  }
}
    `;
export const DevFooter_ApplicationFragmentDoc = gql`
    fragment DevFooter_application on Application {
  testMode {
    application
    bankStatements
  }
}
    `;
export const PortalBanner_ApplicationFragmentDoc = gql`
    fragment PortalBanner_application on Application {
  inStore {
    storeName
    storeLogo
  }
}
    `;
export const EnumChoicesTypeInfoFragmentDoc = gql`
    fragment EnumChoicesTypeInfo on __Type {
  kind
  name
  enumValues {
    name
    description
    isDeprecated
  }
}
    `;
export const Agreement_PortalpoliciesFragmentDoc = gql`
    fragment Agreement_portalpolicies on PortalPolicies {
  terms {
    content
  }
}
    `;
export const AltContactFragmentDoc = gql`
    fragment AltContact on Customer {
  altContactName
  altContactPhone
}
    `;
export const BankStatements_ApplicationFragmentDoc = gql`
    fragment BankStatements_application on Application {
  validation {
    bankStatements {
      field
      messages
    }
  }
}
    `;
export const CartChangedApplicationFragmentDoc = gql`
    fragment CartChangedApplication on Application {
  id
  portal {
    slug
  }
  rebateMonths
  hasMixedRates
  ...Cart_application
  ...Steps_application
  ...ProtectionPlan_application
  ...Signature_application
}
    ${Cart_ApplicationFragmentDoc}
${Steps_ApplicationFragmentDoc}
${ProtectionPlan_ApplicationFragmentDoc}
${Signature_ApplicationFragmentDoc}`;
export const ImportantInfoFragmentDoc = gql`
    fragment ImportantInfo on ApplicationImportantInfo {
  freightHeading
  freightHtml
  rentalProcessHtml
  ageAndResidencyHtml
  minPeriodText
  namedApplicantsText
  productTypeInfo {
    title
    content
  }
}
    `;
export const ImportantInfoConditionsFragmentDoc = gql`
    fragment ImportantInfoConditions on Application {
  residentStatus
  importantInfo {
    ...ImportantInfo
  }
}
    ${ImportantInfoFragmentDoc}`;
export const AboutMeForm_CustomerFragmentDoc = gql`
    fragment AboutMeForm_customer on Customer {
  title
  firstName
  lastName
  email
  phoneMobile
  dob
  licenceNo
}
    `;
export const AboutMeForm_ValidationFragmentDoc = gql`
    fragment AboutMeForm_validation on ApplicationValidation {
  customer {
    field
    messages
  }
}
    `;
export const AboutMeFragmentDoc = gql`
    fragment AboutMe on Application {
  id
  inStore {
    storeName
  }
  marketingConsent
  customer {
    ...AboutMeForm_customer
  }
  validation {
    ...AboutMeForm_validation
  }
}
    ${AboutMeForm_CustomerFragmentDoc}
${AboutMeForm_ValidationFragmentDoc}`;
export const AdditionalInfoFragmentDoc = gql`
    fragment AdditionalInfo on Customer {
  workComments
}
    `;
export const ApplicationDeliveryAddressFieldsFragmentDoc = gql`
    fragment ApplicationDeliveryAddressFields on ApplicationAddress {
  deliveryAddressType
  deliveryAddress {
    ...AddressFields
  }
  dealer {
    ...Dealer
  }
}
    ${AddressFieldsFragmentDoc}
${DealerFragmentDoc}`;
export const ApplicationLivingSituationFieldsFragmentDoc = gql`
    fragment ApplicationLivingSituationFields on ApplicationLivingSituation {
  homeType
  homeDurationYears
  homeDurationMonths
}
    `;
export const ApplicationAddressFragmentDoc = gql`
    fragment ApplicationAddress on Application {
  address {
    ...ApplicationAddressFields
    ...ApplicationDeliveryAddressFields
  }
  livingSituation {
    ...ApplicationLivingSituationFields
  }
}
    ${ApplicationAddressFieldsFragmentDoc}
${ApplicationDeliveryAddressFieldsFragmentDoc}
${ApplicationLivingSituationFieldsFragmentDoc}`;
export const IncomeFragmentDoc = gql`
    fragment Income on Income {
  workBasis
  occupation
  employer
  phoneWork
  workTimeYears
  workTimeMonths
  livingExpensesPercentage
}
    `;
export const BankStatementsFragmentDoc = gql`
    fragment BankStatements on BankStatements {
  embedURL
  result
  reference
  errorCode
  errorMessage
}
    `;
export const Summary_ApplicationFragmentDoc = gql`
    fragment Summary_application on Application {
  id
  residentStatus
  ...Cart_application
  ...AboutMe
  alternativeContactRequired
  customer {
    ...Customer
    ...AdditionalInfo
  }
  ...ApplicationAddress
  income {
    ...Income
  }
  bankStatementsRequired
  bankStatements {
    ...BankStatements
  }
  ...Payment_application
  ...Agreement_application
  validation {
    isValid
    cart {
      field
      messages
    }
    customer {
      field
      messages
    }
    address {
      field
      messages
    }
    livingSituation {
      field
      messages
    }
    income {
      field
      messages
    }
    bankStatements {
      field
      messages
    }
    payment {
      field
      messages
    }
    agreement {
      field
      messages
    }
  }
}
    ${Cart_ApplicationFragmentDoc}
${AboutMeFragmentDoc}
${CustomerFragmentDoc}
${AdditionalInfoFragmentDoc}
${ApplicationAddressFragmentDoc}
${IncomeFragmentDoc}
${BankStatementsFragmentDoc}
${Payment_ApplicationFragmentDoc}
${Agreement_ApplicationFragmentDoc}`;
export const Welcome_PortalFragmentDoc = gql`
    fragment Welcome_portal on Portal {
  slug
  title
  portalLogoUrl
  myApplication {
    ...Welcome_application
  }
}
    ${Welcome_ApplicationFragmentDoc}`;
export const NabCrnFragmentDoc = gql`
    fragment NabCrn on NabCrn {
  crn
  timestamp
  fingerprint
  resultUrl
  nabUrl
  action
  merchant
}
    `;
export const NabCustomerRecordFragmentDoc = gql`
    fragment NabCustomerRecord on NabCustomerRecord {
  id
  timestamp
  crn
  fingerprint
  merchant
  resultUrl
  nabUrl
  action
}
    `;
export const AppDocument = gql`
    query App($portalSlug: String!) {
  portal(slug: $portalSlug) {
    ...App_portal
    ...Welcome_portal
    ...Steps_portal
  }
}
    ${App_PortalFragmentDoc}
${Welcome_PortalFragmentDoc}
${Steps_PortalFragmentDoc}`;

export function useAppQuery(options: Omit<Urql.UseQueryArgs<AppQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<AppQuery>({ query: AppDocument, ...options });
};
export const CancelApplicationDocument = gql`
    mutation CancelApplication($input: CancelApplicationInput!) {
  cancelApplication(input: $input) {
    portal {
      slug
      myApplication {
        id
      }
    }
  }
}
    `;

export function useCancelApplicationMutation() {
  return Urql.useMutation<CancelApplicationMutation, CancelApplicationMutationVariables>(CancelApplicationDocument);
};
export const RecordInteractionDocument = gql`
    mutation RecordInteraction($input: RecordInteractionInput!) {
  recordInteraction(input: $input) {
    application {
      id
    }
    errors {
      field
      messages
    }
  }
}
    `;

export function useRecordInteractionMutation() {
  return Urql.useMutation<RecordInteractionMutation, RecordInteractionMutationVariables>(RecordInteractionDocument);
};
export const RestartApplicationDocument = gql`
    mutation RestartApplication($input: RestartApplicationInput!) {
  restartApplication(input: $input) {
    portal {
      slug
      ...App_portal
      myApplication {
        ...CartChangedApplication
        ...App_application
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${App_PortalFragmentDoc}
${CartChangedApplicationFragmentDoc}
${App_ApplicationFragmentDoc}`;

export function useRestartApplicationMutation() {
  return Urql.useMutation<RestartApplicationMutation, RestartApplicationMutationVariables>(RestartApplicationDocument);
};
export const PrivacyPolicyDocument = gql`
    query PrivacyPolicy($portalSlug: String!) {
  portal(slug: $portalSlug) {
    slug
    ...PrivacyPolicy
  }
}
    ${PrivacyPolicyFragmentDoc}`;

export function usePrivacyPolicyQuery(options: Omit<Urql.UseQueryArgs<PrivacyPolicyQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<PrivacyPolicyQuery>({ query: PrivacyPolicyDocument, ...options });
};
export const ProtectionPlanInformationDocument = gql`
    query ProtectionPlanInformation($portalSlug: String!) {
  portal(slug: $portalSlug) {
    slug
    ...ProtectionPlanInformation
  }
}
    ${ProtectionPlanInformationFragmentDoc}`;

export function useProtectionPlanInformationQuery(options: Omit<Urql.UseQueryArgs<ProtectionPlanInformationQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ProtectionPlanInformationQuery>({ query: ProtectionPlanInformationDocument, ...options });
};
export const TermsAndConditionsDocument = gql`
    query TermsAndConditions($portalSlug: String!) {
  portal(slug: $portalSlug) {
    slug
    ...TermsAndConditions
  }
}
    ${TermsAndConditionsFragmentDoc}`;

export function useTermsAndConditionsQuery(options: Omit<Urql.UseQueryArgs<TermsAndConditionsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TermsAndConditionsQuery>({ query: TermsAndConditionsDocument, ...options });
};
export const SetTestDataStateDocument = gql`
    mutation SetTestDataState($input: SetTestDataStateInput!) {
  setTestDataState(input: $input) {
    portal {
      slug
      myApplication {
        ...App_application
        customer {
          ...AboutMeForm_customer
        }
      }
    }
  }
}
    ${App_ApplicationFragmentDoc}
${AboutMeForm_CustomerFragmentDoc}`;

export function useSetTestDataStateMutation() {
  return Urql.useMutation<SetTestDataStateMutation, SetTestDataStateMutationVariables>(SetTestDataStateDocument);
};
export const AboutMeDocument = gql`
    query AboutMe($portalSlug: String!) {
  portal(slug: $portalSlug) {
    slug
    myApplication {
      ...AboutMe
    }
  }
  salutationSourceEnum: __type(name: "SalutationSource") {
    ...EnumChoicesTypeInfo
  }
}
    ${AboutMeFragmentDoc}
${EnumChoicesTypeInfoFragmentDoc}`;

export function useAboutMeQuery(options: Omit<Urql.UseQueryArgs<AboutMeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<AboutMeQuery>({ query: AboutMeDocument, ...options });
};
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($input: UpdateCustomerInput!) {
  updateCustomer(input: $input) {
    application {
      id
      portal {
        slug
      }
      customer {
        ...Customer
      }
      ...AboutMe
    }
    errors {
      field
      messages
    }
  }
}
    ${CustomerFragmentDoc}
${AboutMeFragmentDoc}`;

export function useUpdateCustomerMutation() {
  return Urql.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument);
};
export const AdditionalInfoDocument = gql`
    query AdditionalInfo($portalSlug: String!) {
  portal(slug: $portalSlug) {
    myApplication {
      id
      customer {
        ...AdditionalInfo
      }
    }
  }
}
    ${AdditionalInfoFragmentDoc}`;

export function useAdditionalInfoQuery(options: Omit<Urql.UseQueryArgs<AdditionalInfoQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<AdditionalInfoQuery>({ query: AdditionalInfoDocument, ...options });
};
export const AddressDocument = gql`
    query Address($portalSlug: String!) {
  portal(slug: $portalSlug) {
    slug
    myApplication {
      id
      ...ApplicationAddress
    }
  }
  homeTypeSourceEnum: __type(name: "HomeTypeSource") {
    ...EnumChoicesTypeInfo
  }
}
    ${ApplicationAddressFragmentDoc}
${EnumChoicesTypeInfoFragmentDoc}`;

export function useAddressQuery(options: Omit<Urql.UseQueryArgs<AddressQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<AddressQuery>({ query: AddressDocument, ...options });
};
export const UpdateApplicationAddressDocument = gql`
    mutation UpdateApplicationAddress($residential: SetResidentialAddressInput!, $situation: SetLivingSituationInput!) {
  setResidentialAddress(input: $residential) {
    application {
      id
      portal {
        slug
      }
      address {
        ...ApplicationAddressFields
      }
    }
    errors {
      field
      messages
    }
  }
  setLivingSituation(input: $situation) {
    application {
      id
      portal {
        slug
      }
      livingSituation {
        ...ApplicationLivingSituationFields
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${ApplicationAddressFieldsFragmentDoc}
${ApplicationLivingSituationFieldsFragmentDoc}`;

export function useUpdateApplicationAddressMutation() {
  return Urql.useMutation<UpdateApplicationAddressMutation, UpdateApplicationAddressMutationVariables>(UpdateApplicationAddressDocument);
};
export const UpdateApplicationDeliveryAddressDocument = gql`
    mutation UpdateApplicationDeliveryAddress($delivery: SetDeliveryAddressInput!) {
  setDeliveryAddress(input: $delivery) {
    application {
      id
      portal {
        slug
      }
      address {
        ...ApplicationDeliveryAddressFields
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${ApplicationDeliveryAddressFieldsFragmentDoc}`;

export function useUpdateApplicationDeliveryAddressMutation() {
  return Urql.useMutation<UpdateApplicationDeliveryAddressMutation, UpdateApplicationDeliveryAddressMutationVariables>(UpdateApplicationDeliveryAddressDocument);
};
export const UpdateApplicationDealerDocument = gql`
    mutation UpdateApplicationDealer($dealer: SetDeliveryDealerInput!) {
  setDeliveryDealer(input: $dealer) {
    application {
      id
      portal {
        slug
      }
      address {
        ...ApplicationDeliveryAddressFields
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${ApplicationDeliveryAddressFieldsFragmentDoc}`;

export function useUpdateApplicationDealerMutation() {
  return Urql.useMutation<UpdateApplicationDealerMutation, UpdateApplicationDealerMutationVariables>(UpdateApplicationDealerDocument);
};
export const ValidateAddressDocument = gql`
    mutation ValidateAddress($input: ValidateAddressInput!) {
  validateAddress(input: $input) {
    address {
      fullAddress
      valid
      validated
      street
      suburb
      state
      postcode
    }
  }
}
    `;

export function useValidateAddressMutation() {
  return Urql.useMutation<ValidateAddressMutation, ValidateAddressMutationVariables>(ValidateAddressDocument);
};
export const PortalTermsDocument = gql`
    query PortalTerms($portalSlug: String!) {
  portal(slug: $portalSlug) {
    slug
    policies {
      terms {
        content
      }
    }
  }
}
    `;

export function usePortalTermsQuery(options: Omit<Urql.UseQueryArgs<PortalTermsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<PortalTermsQuery>({ query: PortalTermsDocument, ...options });
};
export const SetAcceptedAgreementDocument = gql`
    mutation SetAcceptedAgreement($input: SetAcceptedAgreementInput!) {
  setAcceptedAgreement(input: $input) {
    application {
      id
      ...Agreement_application
    }
  }
}
    ${Agreement_ApplicationFragmentDoc}`;

export function useSetAcceptedAgreementMutation() {
  return Urql.useMutation<SetAcceptedAgreementMutation, SetAcceptedAgreementMutationVariables>(SetAcceptedAgreementDocument);
};
export const AltContactDocument = gql`
    query AltContact($portalSlug: String!) {
  portal(slug: $portalSlug) {
    myApplication {
      id
      customer {
        ...AltContact
      }
    }
  }
}
    ${AltContactFragmentDoc}`;

export function useAltContactQuery(options: Omit<Urql.UseQueryArgs<AltContactQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<AltContactQuery>({ query: AltContactDocument, ...options });
};
export const BankStatementsDocument = gql`
    query BankStatements($portalSlug: String!) {
  portal(slug: $portalSlug) {
    myApplication {
      id
      bankStatements {
        ...BankStatements
      }
    }
  }
}
    ${BankStatementsFragmentDoc}`;

export function useBankStatementsQuery(options: Omit<Urql.UseQueryArgs<BankStatementsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<BankStatementsQuery>({ query: BankStatementsDocument, ...options });
};
export const SkipBankStatementsDocument = gql`
    mutation SkipBankStatements($input: SkipBankStatementsInput!) {
  skipBankStatements(input: $input) {
    application {
      id
      bankStatements {
        ...BankStatements
      }
      ...BankStatements_application
    }
  }
}
    ${BankStatementsFragmentDoc}
${BankStatements_ApplicationFragmentDoc}`;

export function useSkipBankStatementsMutation() {
  return Urql.useMutation<SkipBankStatementsMutation, SkipBankStatementsMutationVariables>(SkipBankStatementsDocument);
};
export const RecordBankStatementsResultDocument = gql`
    mutation RecordBankStatementsResult($resultInput: RecordBankStatementsResultInput!, $eventInput: LogBankStatementsEventInput!) {
  recordBankStatementsResult(input: $resultInput) {
    application {
      id
      bankStatements {
        ...BankStatements
      }
      ...BankStatements_application
    }
  }
  logBankStatementsEvent(input: $eventInput) {
    eventId
  }
}
    ${BankStatementsFragmentDoc}
${BankStatements_ApplicationFragmentDoc}`;

export function useRecordBankStatementsResultMutation() {
  return Urql.useMutation<RecordBankStatementsResultMutation, RecordBankStatementsResultMutationVariables>(RecordBankStatementsResultDocument);
};
export const LogBankStatementsEventDocument = gql`
    mutation LogBankStatementsEvent($input: LogBankStatementsEventInput!) {
  logBankStatementsEvent(input: $input) {
    eventId
  }
}
    `;

export function useLogBankStatementsEventMutation() {
  return Urql.useMutation<LogBankStatementsEventMutation, LogBankStatementsEventMutationVariables>(LogBankStatementsEventDocument);
};
export const UpdateProtectionPlanDocument = gql`
    mutation UpdateProtectionPlan($portalSlug: String!, $input: UpdateProtectionPlanInput!) {
  updateProtectionPlan(portalSlug: $portalSlug, input: $input) {
    application {
      ...CartChangedApplication
    }
  }
}
    ${CartChangedApplicationFragmentDoc}`;

export function useUpdateProtectionPlanMutation() {
  return Urql.useMutation<UpdateProtectionPlanMutation, UpdateProtectionPlanMutationVariables>(UpdateProtectionPlanDocument);
};
export const UpdateItemDocument = gql`
    mutation UpdateItem($input: UpdateItemInput!) {
  updateItem(input: $input) {
    application {
      ...CartChangedApplication
    }
    errors {
      field
      messages
    }
  }
}
    ${CartChangedApplicationFragmentDoc}`;

export function useUpdateItemMutation() {
  return Urql.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument);
};
export const RemoveItemDocument = gql`
    mutation RemoveItem($input: RemoveItemInput!) {
  removeItem(input: $input) {
    application {
      ...CartChangedApplication
    }
    errors {
      field
      messages
    }
  }
}
    ${CartChangedApplicationFragmentDoc}`;

export function useRemoveItemMutation() {
  return Urql.useMutation<RemoveItemMutation, RemoveItemMutationVariables>(RemoveItemDocument);
};
export const AddSuggestedItemDocument = gql`
    mutation AddSuggestedItem($input: AddSuggestedItemInput!) {
  addSuggestedItem(input: $input) {
    application {
      ...CartChangedApplication
    }
  }
}
    ${CartChangedApplicationFragmentDoc}`;

export function useAddSuggestedItemMutation() {
  return Urql.useMutation<AddSuggestedItemMutation, AddSuggestedItemMutationVariables>(AddSuggestedItemDocument);
};
export const SetRebateMonthsDocument = gql`
    mutation SetRebateMonths($input: SetRebateMonthsInput!) {
  setRebateMonths(input: $input) {
    application {
      ...CartChangedApplication
    }
  }
}
    ${CartChangedApplicationFragmentDoc}`;

export function useSetRebateMonthsMutation() {
  return Urql.useMutation<SetRebateMonthsMutation, SetRebateMonthsMutationVariables>(SetRebateMonthsDocument);
};
export const ClearApplicationWarningsDocument = gql`
    mutation ClearApplicationWarnings($input: ClearApplicationWarningsInput!) {
  clearApplicationWarnings(input: $input) {
    application {
      id
      hasMixedRates
    }
  }
}
    `;

export function useClearApplicationWarningsMutation() {
  return Urql.useMutation<ClearApplicationWarningsMutation, ClearApplicationWarningsMutationVariables>(ClearApplicationWarningsDocument);
};
export const ImportantInfoDocument = gql`
    query ImportantInfo($portalSlug: String!) {
  portal(slug: $portalSlug) {
    slug
    myApplication {
      id
      ...ImportantInfoConditions
    }
  }
  residentStatusEnum: __type(name: "ApplicationResidentStatus") {
    ...EnumChoicesTypeInfo
  }
}
    ${ImportantInfoConditionsFragmentDoc}
${EnumChoicesTypeInfoFragmentDoc}`;

export function useImportantInfoQuery(options: Omit<Urql.UseQueryArgs<ImportantInfoQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ImportantInfoQuery>({ query: ImportantInfoDocument, ...options });
};
export const SetImportantInfoReadDocument = gql`
    mutation SetImportantInfoRead($input: SetImportantInfoReadInput!) {
  setImportantInfoRead(input: $input) {
    application {
      ...ImportantInfo_application
    }
  }
}
    ${ImportantInfo_ApplicationFragmentDoc}`;

export function useSetImportantInfoReadMutation() {
  return Urql.useMutation<SetImportantInfoReadMutation, SetImportantInfoReadMutationVariables>(SetImportantInfoReadDocument);
};
export const SetResidentStatusDocument = gql`
    mutation SetResidentStatus($input: SetResidentStatusInput!) {
  setResidentStatus(input: $input) {
    application {
      id
      residentStatus
    }
  }
}
    `;

export function useSetResidentStatusMutation() {
  return Urql.useMutation<SetResidentStatusMutation, SetResidentStatusMutationVariables>(SetResidentStatusDocument);
};
export const IncomeDocument = gql`
    query Income($portalSlug: String!) {
  portal(slug: $portalSlug) {
    slug
    myApplication {
      id
      income {
        ...Income
      }
      householdFieldsRequired
    }
  }
  workBasisEnum: __type(name: "ApplicationWorkBasis") {
    ...EnumChoicesTypeInfo
  }
  livingExpensesEnum: __type(name: "ApplicationLivingExpensesPercentage") {
    ...EnumChoicesTypeInfo
  }
  occupations
}
    ${IncomeFragmentDoc}
${EnumChoicesTypeInfoFragmentDoc}`;

export function useIncomeQuery(options: Omit<Urql.UseQueryArgs<IncomeQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<IncomeQuery>({ query: IncomeDocument, ...options });
};
export const UpdateIncomeDocument = gql`
    mutation UpdateIncome($input: UpdateIncomeInput!) {
  updateIncome(input: $input) {
    application {
      id
      portal {
        slug
      }
      income {
        ...Income
      }
    }
    errors {
      field
      messages
    }
  }
}
    ${IncomeFragmentDoc}`;

export function useUpdateIncomeMutation() {
  return Urql.useMutation<UpdateIncomeMutation, UpdateIncomeMutationVariables>(UpdateIncomeDocument);
};
export const InitiateRentalDirectPostDocument = gql`
    mutation InitiateRentalDirectPost($input: InitiateRentalDirectPostInput!) {
  initiateRentalDirectPost(input: $input) {
    nabCrn {
      ...NabCrn
    }
    errors {
      field
      messages
    }
  }
}
    ${NabCrnFragmentDoc}`;

export function useInitiateRentalDirectPostMutation() {
  return Urql.useMutation<InitiateRentalDirectPostMutation, InitiateRentalDirectPostMutationVariables>(InitiateRentalDirectPostDocument);
};
export const SkipPaymentDocument = gql`
    mutation SkipPayment($input: SkipPaymentInput!) {
  skipPayment(input: $input) {
    application {
      id
      ...Payment_application
    }
  }
}
    ${Payment_ApplicationFragmentDoc}`;

export function useSkipPaymentMutation() {
  return Urql.useMutation<SkipPaymentMutation, SkipPaymentMutationVariables>(SkipPaymentDocument);
};
export const PortalCreditGuideDocument = gql`
    query PortalCreditGuide($portalSlug: String!) {
  portal(slug: $portalSlug) {
    slug
    policies {
      creditGuideUrl
    }
  }
}
    `;

export function usePortalCreditGuideQuery(options: Omit<Urql.UseQueryArgs<PortalCreditGuideQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<PortalCreditGuideQuery>({ query: PortalCreditGuideDocument, ...options });
};
export const SignatureDocument = gql`
    query Signature($portalSlug: String!) {
  portal(slug: $portalSlug) {
    myApplication {
      id
      ...Signature_application
    }
  }
}
    ${Signature_ApplicationFragmentDoc}`;

export function useSignatureQuery(options: Omit<Urql.UseQueryArgs<SignatureQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SignatureQuery>({ query: SignatureDocument, ...options });
};
export const SubmitApplicationDocument = gql`
    mutation SubmitApplication($input: SubmitApplicationInput!) {
  submitApplication(input: $input) {
    portal {
      slug
      myLastApplicationId
    }
    application {
      id
      dateSubmitted
    }
    errors {
      field
      messages
    }
  }
}
    `;

export function useSubmitApplicationMutation() {
  return Urql.useMutation<SubmitApplicationMutation, SubmitApplicationMutationVariables>(SubmitApplicationDocument);
};
export const RequestSmsCodeDocument = gql`
    mutation RequestSmsCode($input: SendSmsSignatureInput!) {
  sendSmsSignature(input: $input) {
    application {
      id
      ...Signature_application
    }
    errors {
      field
      messages
    }
  }
}
    ${Signature_ApplicationFragmentDoc}`;

export function useRequestSmsCodeMutation() {
  return Urql.useMutation<RequestSmsCodeMutation, RequestSmsCodeMutationVariables>(RequestSmsCodeDocument);
};
export const ApplicationSummaryDocument = gql`
    query ApplicationSummary($portalSlug: String!) {
  portal(slug: $portalSlug) {
    myApplication {
      ...Summary_application
    }
  }
  salutationSourceEnum: __type(name: "SalutationSource") {
    ...EnumChoicesTypeInfo
  }
  residentStatusEnum: __type(name: "ApplicationResidentStatus") {
    ...EnumChoicesTypeInfo
  }
  homeTypeSourceEnum: __type(name: "HomeTypeSource") {
    ...EnumChoicesTypeInfo
  }
  workBasisEnum: __type(name: "ApplicationWorkBasis") {
    ...EnumChoicesTypeInfo
  }
  livingExpensesEnum: __type(name: "ApplicationLivingExpensesPercentage") {
    ...EnumChoicesTypeInfo
  }
  bankStatementsResultEnum: __type(name: "BankStatementsResult") {
    ...EnumChoicesTypeInfo
  }
}
    ${Summary_ApplicationFragmentDoc}
${EnumChoicesTypeInfoFragmentDoc}`;

export function useApplicationSummaryQuery(options: Omit<Urql.UseQueryArgs<ApplicationSummaryQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ApplicationSummaryQuery>({ query: ApplicationSummaryDocument, ...options });
};