import React from 'react';
import { PhoneNumberLink } from 'shared/components/PhoneNumber';


interface Props {
  reporting: boolean
  errorReportId: number | null;
}


export const ErrorBoundaryMessage = (props: Props) => (
  <div className="m-3 px-3 py-3 text-center border border-danger rounded bg-white">
    <div className='text-danger'>
      <h4 className="alert-heading">Something has gone wrong</h4>
      {props.reporting && <p>Please wait while we log this issue...</p>}
      {props.errorReportId && <p>
        <span className="text-nowrap">This issue has been logged </span>{' '}
        <span className="text-nowrap">with our developers as </span>{' '}
        <span className="text-nowrap"><b>Error Report #{props.errorReportId}</b>. </span>
      </p>}
      <hr className=' border-danger'/>
    </div>
    <p><b>You may find that reloading the page fixes the issue.</b></p>
    <p>
      <span className="text-nowrap">If you continue to have issues,</span>{' '}
      <span className="text-nowrap">please contact us on <PhoneNumberLink className='font-weight-bold'/>.</span>
    </p>
    <p><button className="btn btn-danger mt-3" onClick={() => window.location.reload()}>Reload Page</button></p>
  </div>
)
