

export const formatCard = (number: string, gaps: Array<number>) => {
  let n = number.replace(/[^\d]/g, "");

  if ((gaps || []).length < 1) {
    return n;
  }

  let gapRanges: Array<[number, number | undefined]> = [[0, gaps[0]]];
  for (let i = 0; i <= gaps.length - 1; i++) {
    const thisGap = gaps[i];
    const nextGap = i < gaps.length - 1 ? gaps[i + 1] : undefined;
    if (thisGap !== undefined) {
      gapRanges.push([thisGap, nextGap]);
    }
  }

  return gapRanges
    .map(r => n.substring(r[0], r[1]))
    .join(" ")
    .trim();
};

