import { useMedia } from 'react-media';

export const GRID_BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

export const GRID_MEDIA_QUERIES = {
  // Exact queries (enable as needed)
  // xs: `(max-width: ${GRID_BREAKPOINTS.sm - 1}px)`,
  // sm: `(min-width: ${GRID_BREAKPOINTS.sm}px) and (max-width: ${GRID_BREAKPOINTS.md - 1}px)`,
  // md: `(min-width: ${GRID_BREAKPOINTS.md}px) and (max-width: ${GRID_BREAKPOINTS.lg - 1}px)`,
  // lg: `(min-width: ${GRID_BREAKPOINTS.lg}px) and (max-width: ${GRID_BREAKPOINTS.xl - 1}px)`,
  // xl: `(min-width: ${GRID_BREAKPOINTS.xl}px)`,

  // Greater or equal too
  xsGTE: `(min-width: ${GRID_BREAKPOINTS.xs}px)`,
  smGTE: `(min-width: ${GRID_BREAKPOINTS.sm}px)`,
  mdGTE: `(min-width: ${GRID_BREAKPOINTS.md}px)`,
  lgGTE: `(min-width: ${GRID_BREAKPOINTS.lg}px)`,
  xlGTE: `(min-width: ${GRID_BREAKPOINTS.xl}px)`,
};


export const useMediaBreakpoints = () => useMedia({queries: GRID_MEDIA_QUERIES})
