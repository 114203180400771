import React from 'react';
import { AgreementCheckbox } from 'shared/aggreement/AgreementCheckbox';
import { ScrollingTermsAndCond } from 'shared/aggreement/ScrollingTermsAndCond';
import { PageContainer } from 'shared/components/PageContainer';
import { NextButton } from 'shared/components/SubmitButtons';
import { PageHeading } from 'shared/components/typography';
import { TuitionPageTitleHelmet } from 'tuition/components/PageTitleHelmet';
import { useAcceptTuitionAgreementMutation, useSubmitTuitionAppMutation, useTuitionTermsQuery } from 'tuition/generated.graphql';
import { TuitionStepProps } from 'tuition/steps';
import { SubmitActions } from '../../../shared/components/SubmitActions';



export const Agreement = (props: TuitionStepProps) => {
  const [result,] = useTuitionTermsQuery();
  const checked = result.data?.tuition.myApplication.acceptedAgreement || false;
  const terms = result.data?.tuition.tuitionTerms;
  const [acceptStatus, executeAccept] = useAcceptTuitionAgreementMutation();
  const [submitStatus, executeSubmit] = useSubmitTuitionAppMutation();
  const canSubmit = (checked && terms?.content) && !acceptStatus.fetching;

  const handleSubmit = async () => {
    if (canSubmit) {
      const r = await executeSubmit();
      if (r.data?.submitTuitionApplication) {
        props.onSubmitSuccessful();
      }
    }
  };

  return (
    <PageContainer>
      <TuitionPageTitleHelmet>Tuition Agreement</TuitionPageTitleHelmet>
      <PageHeading>Review the Tuition Agreement</PageHeading>
      <p><em>Please read through and agree to the following Terms &amp; Conditions:</em></p>
      
      <ScrollingTermsAndCond html={terms?.content} newWindowLink={terms?.url || undefined}/>
      
      <div className="p-3">
        <AgreementCheckbox 
          checked={checked}
          onChange={e => executeAccept({input: {accepted: e.target.checked}})}
          disabled={acceptStatus.fetching}
        >
          I have read and agree to the Terms and Conditions.
        </AgreementCheckbox>
      </div>
      <SubmitActions
        actions={props.actions}
        submit={handleSubmit}
        right={<NextButton saving={submitStatus.fetching} onClick={handleSubmit}>Submit Application</NextButton>}
        submitting={submitStatus.fetching}
        disabled={!canSubmit}
      />
    </PageContainer>
  );
};
