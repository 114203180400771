import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
};

export type AcceptTuitionAgreementInput = {
  readonly accepted: Scalars['Boolean'];
};

/** Call this mutation to indicate agreement acceptance and marketing consent. */
export type AcceptTuitionAgreementPayload = {
  readonly __typename?: 'AcceptTuitionAgreementPayload';
  readonly application?: Maybe<TuitionApplication>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type AddSuggestedItemInput = {
  /** The ID of the item */
  readonly id: Scalars['Int'];
  readonly portalSlug: Scalars['String'];
};

/** Adds a suggested item into your cart by changing the quantity to 1 if it is 0.  There is no effect if the item is already in your cart.  Although intended to only be used for suggested items, this is not checked. */
export type AddSuggestedItemPayload = {
  readonly __typename?: 'AddSuggestedItemPayload';
  readonly application?: Maybe<Application>;
  readonly item?: Maybe<Item>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type Address = {
  readonly __typename?: 'Address';
  /** The actual stored address. Used to infer street/suburb/state/postcode. */
  readonly fullAddress: Scalars['String'];
  /** Indicates if this address is considered valid */
  readonly valid: Scalars['Boolean'];
  /** Indicates if this address has been validated */
  readonly validated: Scalars['Boolean'];
  readonly street?: Maybe<Scalars['String']>;
  readonly suburb?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly postcode?: Maybe<Scalars['String']>;
};

export type AppVersion = {
  readonly __typename?: 'AppVersion';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  /** Used for the sub-domain of this version and for the code folder. */
  readonly slug: Scalars['String'];
};

export type Application = {
  readonly __typename?: 'Application';
  readonly id: Scalars['Int'];
  readonly portal: Portal;
  readonly appVersion?: Maybe<AppVersion>;
  /** Link to send customers to if they wish to add another item. Use this for portals that have different urls for different customers.  Leave blank to use portal default. */
  readonly storeLink: Scalars['String'];
  readonly residentStatus?: Maybe<ApplicationResidentStatus>;
  readonly marketingConsent: Scalars['Boolean'];
  /** Date to indicate all T&Cs, approval processes, and communication have been accepted by the customer */
  readonly acceptedAgreement?: Maybe<Scalars['DateTime']>;
  readonly importantInfoRead?: Maybe<Scalars['DateTime']>;
  readonly dateSessionExpires?: Maybe<Scalars['DateTime']>;
  readonly pricing: ApplicationPricing;
  readonly items?: Maybe<ReadonlyArray<Item>>;
  readonly suggestedItems?: Maybe<ReadonlyArray<SuggestedItem>>;
  readonly customer: Customer;
  readonly alternativeContactRequired: Scalars['Boolean'];
  readonly address: ApplicationAddress;
  readonly livingSituation: ApplicationLivingSituation;
  readonly income: Income;
  readonly inStore?: Maybe<InStore>;
  readonly includesProtectionPlan: Scalars['Boolean'];
  readonly bankStatements: BankStatements;
  readonly bankStatementsRequired: Scalars['Boolean'];
  readonly payment?: Maybe<CreditCard>;
  readonly hasDirectPostFailed: Scalars['Boolean'];
  readonly paymentSkipped?: Maybe<Scalars['DateTime']>;
  readonly validation: ApplicationValidation;
  readonly rateOptions: ReadonlyArray<RateOption>;
  readonly rebateMonths: Scalars['Int'];
  /** If a value is present the application has been successfully submitted. */
  readonly dateSubmitted?: Maybe<Scalars['DateTime']>;
  readonly remainingSmsSignatureCount: Scalars['Int'];
  readonly digitalSignature: Scalars['String'];
  readonly digitalSignatureRequired: Scalars['Boolean'];
  readonly householdFieldsRequired: Scalars['Boolean'];
  readonly testMode: TestMode;
  readonly importantInfo: ApplicationImportantInfo;
  readonly remarketingScript?: Maybe<Scalars['String']>;
  readonly conversionScript?: Maybe<Scalars['String']>;
  readonly hasMixedRates: Scalars['Boolean'];
};

export type ApplicationAddress = {
  readonly __typename?: 'ApplicationAddress';
  readonly residentialAddress?: Maybe<Address>;
  readonly deliveryAddressType: DeliveryAddressType;
  readonly deliveryAddress?: Maybe<Address>;
  readonly dealer?: Maybe<Dealer>;
  readonly deliveryInstructions?: Maybe<Scalars['String']>;
};

export type ApplicationImportantInfo = {
  readonly __typename?: 'ApplicationImportantInfo';
  readonly freightHeading: Scalars['String'];
  readonly freightHtml: Scalars['String'];
  /** An alternative version to split test on. */
  readonly freightHtmlAlt: Scalars['String'];
  readonly ageAndResidencyHtml: Scalars['String'];
  readonly rentalProcessHtml: Scalars['String'];
  /** An alternative version to split test on. */
  readonly rentalProcessHtmlAlt: Scalars['String'];
  readonly minPeriodText: Scalars['String'];
  readonly minPeriodTextAlt: Scalars['String'];
  readonly namedApplicantsText: Scalars['String'];
  readonly productTypeInfo: ReadonlyArray<ProductTypeInfo>;
};

/** An enumeration. */
export enum ApplicationLivingExpensesPercentage {
  /** Less than 50% */
  A_0_50 = 'A_0_50',
  /** Between 50% and 75% */
  A_50_75 = 'A_50_75',
  /** More than 75% */
  A_75_100 = 'A_75_100',
  /** I don't know */
  Unknown = 'UNKNOWN'
}

export type ApplicationLivingSituation = {
  readonly __typename?: 'ApplicationLivingSituation';
  readonly homeType?: Maybe<HomeTypeSource>;
  readonly homeDurationYears?: Maybe<Scalars['Int']>;
  readonly homeDurationMonths?: Maybe<Scalars['Int']>;
};

export type ApplicationPricing = {
  readonly __typename?: 'ApplicationPricing';
  readonly initialPayment: Scalars['Decimal'];
  readonly ongoingPayment: Scalars['Decimal'];
  readonly setupFee: Scalars['Decimal'];
  readonly totalFreight: Scalars['Decimal'];
  /** How much protection plan would cost.  If selected, this amount is included in the initialPayment and ongoingPayment. */
  readonly protectionPlan: Scalars['Decimal'];
};

/** An enumeration. */
export enum ApplicationResidentStatus {
  /** I am an Australian citizen or permanent resident */
  C = 'C',
  /** I am in Australia on a visa */
  V = 'V'
}

export type ApplicationValidation = {
  readonly __typename?: 'ApplicationValidation';
  readonly isValid: Scalars['Boolean'];
  readonly payment?: Maybe<ReadonlyArray<ErrorType>>;
  readonly cart?: Maybe<ReadonlyArray<ErrorType>>;
  readonly customer?: Maybe<ReadonlyArray<ErrorType>>;
  readonly address?: Maybe<ReadonlyArray<ErrorType>>;
  readonly livingSituation?: Maybe<ReadonlyArray<ErrorType>>;
  readonly income?: Maybe<ReadonlyArray<ErrorType>>;
  readonly bankStatements?: Maybe<ReadonlyArray<ErrorType>>;
  readonly agreement?: Maybe<ReadonlyArray<ErrorType>>;
};

/** An enumeration. */
export enum ApplicationWorkBasis {
  /** Full time employment */
  Full = 'FULL',
  /** Part time employment */
  Part = 'PART',
  /** Casual employment */
  Casual = 'CASUAL',
  /** Government benefits */
  Government = 'GOVERNMENT',
  /** Other */
  Other = 'OTHER',
  /** No income */
  None = 'NONE'
}

export type BankStatements = {
  readonly __typename?: 'BankStatements';
  readonly embedURL: Scalars['String'];
  readonly result?: Maybe<BankStatementsResult>;
  readonly reference?: Maybe<Scalars['String']>;
  readonly errorCode: Scalars['String'];
  readonly errorMessage: Scalars['String'];
};

/** An enumeration. */
export enum BankStatementsResult {
  /** Skipped - Contact me instead */
  Skip = 'SKIP',
  /** Successfully submitted */
  Complete = 'COMPLETE',
  /** Login to bank failed - We may contact you later */
  LoginFail = 'LOGIN_FAIL',
  /** Error - We may contact you later */
  Error = 'ERROR'
}

export type CancelApplicationInput = {
  readonly portalSlug?: Maybe<Scalars['String']>;
};

/** Cancels all current applications by flushing the user session. */
export type CancelApplicationPayload = {
  readonly __typename?: 'CancelApplicationPayload';
  readonly portal?: Maybe<Portal>;
};

/** Cancels the current tuition application by removing the app id from the session. */
export type CancelTuitionApplicationPayload = {
  readonly __typename?: 'CancelTuitionApplicationPayload';
  readonly application: TuitionApplication;
};

export type ClearApplicationWarningsInput = {
  readonly portalSlug: Scalars['String'];
};

export type ClearApplicationWarningsPayload = {
  readonly __typename?: 'ClearApplicationWarningsPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export enum Condition {
  New = 'NEW',
  Preloved = 'PRELOVED'
}

export type CreditCard = {
  readonly __typename?: 'CreditCard';
  readonly maskedCardNumber: Scalars['String'];
  readonly expiryMMYY: Scalars['String'];
  readonly expiryMM: Scalars['String'];
  readonly expiryYY: Scalars['String'];
  readonly expiryYYYY: Scalars['String'];
};

export type Customer = {
  readonly __typename?: 'Customer';
  readonly title?: Maybe<SalutationSource>;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly email: Scalars['String'];
  readonly dob?: Maybe<Scalars['Date']>;
  readonly phoneMobile: Scalars['String'];
  readonly licenceNo: Scalars['String'];
  readonly altContactName: Scalars['String'];
  readonly altContactPhone: Scalars['String'];
  readonly workComments: Scalars['String'];
};



/** Used to populate the dealer selector in an application */
export type Dealer = {
  readonly __typename?: 'Dealer';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
};


export enum DeliveryAddressType {
  Dealer = 'DEALER',
  Residential = 'RESIDENTIAL',
  Custom = 'CUSTOM'
}

export type ErrorType = {
  readonly __typename?: 'ErrorType';
  readonly field: Scalars['String'];
  readonly messages: ReadonlyArray<Scalars['String']>;
};

/** An enumeration. */
export enum HomeTypeSource {
  /** Own Home */
  Own = 'OWN',
  /** Renting */
  Rent = 'RENT',
  /** Boarding */
  Boar = 'BOAR',
  /** Live with Parents */
  Pare = 'PARE',
  /** Government Housing */
  Gove = 'GOVE'
}

export type InStore = {
  readonly __typename?: 'InStore';
  readonly storeName: Scalars['String'];
  /** Polygon store this in our database with the application */
  readonly salesPersonName: Scalars['String'];
  readonly storeLogo?: Maybe<Scalars['String']>;
};

export type Income = {
  readonly __typename?: 'Income';
  readonly occupation: Scalars['String'];
  readonly employer: Scalars['String'];
  readonly phoneWork: Scalars['String'];
  readonly workTimeYears?: Maybe<Scalars['Int']>;
  readonly workTimeMonths?: Maybe<Scalars['Int']>;
  readonly workBasis?: Maybe<ApplicationWorkBasis>;
  readonly livingExpensesPercentage?: Maybe<ApplicationLivingExpensesPercentage>;
};

export type InitiateNabCustomerRecordInput = {
  readonly portalSlug: Scalars['String'];
  /** Whether the user has also submitted the form. */
  readonly submitted?: Maybe<Scalars['Boolean']>;
  readonly nameOnCard: Scalars['String'];
};

export type InitiateNabCustomerRecordPayload = {
  readonly __typename?: 'InitiateNABCustomerRecordPayload';
  readonly application?: Maybe<Application>;
  readonly nabCustomerRecord?: Maybe<NabCustomerRecord>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type InitiateRentalDirectPostInput = {
  /** Whether the user has also submitted the form. */
  readonly submitted?: Maybe<Scalars['Boolean']>;
  readonly nameOnCard: Scalars['String'];
  readonly portalSlug: Scalars['String'];
};

export type InitiateRentalDirectPostPayload = {
  readonly __typename?: 'InitiateRentalDirectPostPayload';
  readonly nabCrn?: Maybe<NabCrn>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type InitiateTuitionDirectPostInput = {
  /** Whether the user has also submitted the form. */
  readonly submitted?: Maybe<Scalars['Boolean']>;
  readonly nameOnCard: Scalars['String'];
  readonly applicationId: Scalars['Int'];
};

export type InitiateTuitionDirectPostPayload = {
  readonly __typename?: 'InitiateTuitionDirectPostPayload';
  readonly nabCrn?: Maybe<NabCrn>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type Item = {
  readonly __typename?: 'Item';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly modelNumber: Scalars['String'];
  readonly url: Scalars['String'];
  readonly maxQty?: Maybe<Scalars['Int']>;
  /** The number the user has selected */
  readonly qty: Scalars['Int'];
  readonly selectedRate: Rate;
  readonly availableRates?: Maybe<ReadonlyArray<Rate>>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  readonly condition: Condition;
  readonly prelovedConditionReport?: Maybe<Scalars['String']>;
  readonly newProductDescription?: Maybe<Scalars['String']>;
  readonly serialNumber?: Maybe<Scalars['String']>;
  /** The main title of the products */
  readonly title: Scalars['String'];
  /** Specific information about the variant or configuration */
  readonly subtitle?: Maybe<Scalars['String']>;
  readonly category?: Maybe<Scalars['String']>;
  readonly startValue: Scalars['Decimal'];
  readonly conversionId: Scalars['String'];
  readonly conversionValue: Scalars['Decimal'];
};

export type LogBankStatementsEventInput = {
  /** Used to link to the customers application (leave null for ad-hoc). */
  readonly portalSlug?: Maybe<Scalars['String']>;
  readonly reference: Scalars['String'];
  readonly event: Scalars['String'];
  readonly status?: Maybe<Scalars['String']>;
  readonly data?: Maybe<Scalars['String']>;
  readonly errorCode?: Maybe<Scalars['String']>;
  readonly errorMessage?: Maybe<Scalars['String']>;
};

/** A log of the users interaction with the bank statements portal. */
export type LogBankStatementsEventPayload = {
  readonly __typename?: 'LogBankStatementsEventPayload';
  readonly eventId?: Maybe<Scalars['Int']>;
};

export type Mutations = {
  readonly __typename?: 'Mutations';
  /** Adds a suggested item into your cart by changing the quantity to 1 if it is 0.  There is no effect if the item is already in your cart.  Although intended to only be used for suggested items, this is not checked. */
  readonly addSuggestedItem?: Maybe<AddSuggestedItemPayload>;
  /** Cancels all current applications by flushing the user session. */
  readonly cancelApplication?: Maybe<CancelApplicationPayload>;
  readonly initiateNabCustomerRecord?: Maybe<InitiateNabCustomerRecordPayload>;
  readonly initiateRentalDirectPost?: Maybe<InitiateRentalDirectPostPayload>;
  /** A log of the users interaction with the bank statements portal. */
  readonly logBankStatementsEvent?: Maybe<LogBankStatementsEventPayload>;
  /** After the user has completed the BankStatements process, the iframe will use window.postMessage to send the result to the parent frame.  Call this mutation with that result. */
  readonly recordBankStatementsResult?: Maybe<RecordBankStatementsResultPayload>;
  /** Remove an item from the cart.  If this item was a suggested item, it will be be reverted to a suggested item by changing the quantity to 0.  Otherwise this item will be deleted. */
  readonly removeItem?: Maybe<RemoveItemPayload>;
  /** Call this mutation to indicate agreement acceptance and marketing consent. */
  readonly setAcceptedAgreement?: Maybe<SetAcceptedAgreementPayload>;
  readonly setImportantInfoRead?: Maybe<SetImportantInfoReadPayload>;
  readonly setRebateMonths?: Maybe<SetRebateMonthsPayload>;
  /** Sets up the application with test data for specific scenarios. */
  readonly setTestDataState?: Maybe<SetTestDataStatePayload>;
  /** Call this mutation if the user does not want to supply their bank statements as part of the application. */
  readonly skipBankStatements?: Maybe<SkipBankStatementsPayload>;
  /** Call this mutation if the user is having trouble completing their payment details. */
  readonly skipPayment?: Maybe<SkipPaymentPayload>;
  readonly submitApplication?: Maybe<SubmitApplicationPayload>;
  readonly updateIncome?: Maybe<UpdateIncomePayload>;
  readonly updateItem?: Maybe<UpdateItemPayload>;
  readonly updateProtectionPlan?: Maybe<UpdateProtectionPlanPayload>;
  readonly updateCustomer?: Maybe<UpdateCustomerPayload>;
  /** Starts a new application with the same cart items updated with the latest prices and availability. All personal information will be discarded. */
  readonly restartApplication?: Maybe<RestartApplicationPayload>;
  readonly setLivingSituation?: Maybe<SetLivingSituationPayload>;
  readonly setResidentialAddress?: Maybe<SetResidentialAddressPayload>;
  readonly setDeliveryAddress?: Maybe<SetDeliveryAddressPayload>;
  readonly setDeliveryDealer?: Maybe<SetDeliveryDealerPayload>;
  readonly setResidentStatus?: Maybe<SetResidentStatusPayload>;
  readonly recordInteraction?: Maybe<RecordInteractionPayload>;
  readonly clearApplicationWarnings?: Maybe<ClearApplicationWarningsPayload>;
  /** Call this mutation to send an SMS signature for an application. */
  readonly sendSmsSignature?: Maybe<SendSmsSignature>;
  readonly validateAddress?: Maybe<ValidateAddressPayload>;
  /** Only used by an ecommerce sites register items that will be added to a customers rental cart. Returns a redirect URL that if navigated to by a customer, will add these items to their cart. */
  readonly registerRentalItems?: Maybe<RegisterRentalItemsPayload>;
  /** Cancels the current tuition application by removing the app id from the session. */
  readonly cancelTuitionApplication?: Maybe<CancelTuitionApplicationPayload>;
  readonly initiateTuitionDirectPost?: Maybe<InitiateTuitionDirectPostPayload>;
  /** Call this mutation if the user is having trouble completing their payment details. */
  readonly skipTuitionPayment?: Maybe<SkipTuitionPaymentPayload>;
  readonly updateTuitionApplication?: Maybe<UpdateTuitionApplicationPayload>;
  /** Call this mutation to indicate agreement acceptance and marketing consent. */
  readonly acceptTuitionAgreement?: Maybe<AcceptTuitionAgreementPayload>;
  readonly submitTuitionApplication?: Maybe<SubmitTuitionApplicationPayload>;
};


export type MutationsAddSuggestedItemArgs = {
  input: AddSuggestedItemInput;
};


export type MutationsCancelApplicationArgs = {
  input: CancelApplicationInput;
};


export type MutationsInitiateNabCustomerRecordArgs = {
  input: InitiateNabCustomerRecordInput;
};


export type MutationsInitiateRentalDirectPostArgs = {
  input: InitiateRentalDirectPostInput;
};


export type MutationsLogBankStatementsEventArgs = {
  input: LogBankStatementsEventInput;
};


export type MutationsRecordBankStatementsResultArgs = {
  input: RecordBankStatementsResultInput;
};


export type MutationsRemoveItemArgs = {
  input: RemoveItemInput;
};


export type MutationsSetAcceptedAgreementArgs = {
  input: SetAcceptedAgreementInput;
};


export type MutationsSetImportantInfoReadArgs = {
  input: SetImportantInfoReadInput;
};


export type MutationsSetRebateMonthsArgs = {
  input: SetRebateMonthsInput;
};


export type MutationsSetTestDataStateArgs = {
  input: SetTestDataStateInput;
};


export type MutationsSkipBankStatementsArgs = {
  input: SkipBankStatementsInput;
};


export type MutationsSkipPaymentArgs = {
  input: SkipPaymentInput;
};


export type MutationsSubmitApplicationArgs = {
  input: SubmitApplicationInput;
};


export type MutationsUpdateIncomeArgs = {
  input: UpdateIncomeInput;
};


export type MutationsUpdateItemArgs = {
  input: UpdateItemInput;
};


export type MutationsUpdateProtectionPlanArgs = {
  input: UpdateProtectionPlanInput;
  portalSlug: Scalars['String'];
};


export type MutationsUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationsRestartApplicationArgs = {
  input: RestartApplicationInput;
};


export type MutationsSetLivingSituationArgs = {
  input: SetLivingSituationInput;
};


export type MutationsSetResidentialAddressArgs = {
  input: SetResidentialAddressInput;
};


export type MutationsSetDeliveryAddressArgs = {
  input: SetDeliveryAddressInput;
};


export type MutationsSetDeliveryDealerArgs = {
  input: SetDeliveryDealerInput;
};


export type MutationsSetResidentStatusArgs = {
  input: SetResidentStatusInput;
};


export type MutationsRecordInteractionArgs = {
  input: RecordInteractionInput;
};


export type MutationsClearApplicationWarningsArgs = {
  input: ClearApplicationWarningsInput;
};


export type MutationsSendSmsSignatureArgs = {
  input: SendSmsSignatureInput;
};


export type MutationsValidateAddressArgs = {
  input: ValidateAddressInput;
};


export type MutationsRegisterRentalItemsArgs = {
  input: RegisterRentalItemsInput;
};


export type MutationsInitiateTuitionDirectPostArgs = {
  input: InitiateTuitionDirectPostInput;
};


export type MutationsUpdateTuitionApplicationArgs = {
  input: UpdateTuitionApplicationInput;
};


export type MutationsAcceptTuitionAgreementArgs = {
  input: AcceptTuitionAgreementInput;
};

export type NabCrn = {
  readonly __typename?: 'NabCrn';
  /** Used by NAB to identify the customer. Field of up to 20 characters. */
  readonly crn: Scalars['ID'];
  /** A timestamp of the format "YYYYMMDDHHMMSS" in GMT. The hour component must be specified in 24-hour format. This same value must be used in both the fingerprint operation request and the subsequent customer management request. */
  readonly timestamp: Scalars['String'];
  /** value returned after submitting information to the fingerprint generation system on NAB Transact. It is a Base64-encoded string and must be included in all transaction attempts. */
  readonly fingerprint: Scalars['String'];
  readonly merchant: Scalars['String'];
  readonly resultUrl: Scalars['String'];
  readonly nabUrl: Scalars['String'];
  readonly action: Scalars['String'];
};

export type NabCustomerRecord = {
  readonly __typename?: 'NabCustomerRecord';
  readonly id: Scalars['ID'];
  /** A timestamp of the format "YYYYMMDDHHMMSS" in GMT. The hour component must be specified in 24-hour format. This same value must be used in both the fingerprint operation request and the subsequent customer management request. */
  readonly timestamp: Scalars['String'];
  /** Used to identify the customer. Field of up to 20 characters. */
  readonly crn: Scalars['String'];
  /** value returned after submitting information to the fingerprint generation system on NAB Transact. It is a Base64-encoded string and must be included in all transaction attempts. */
  readonly fingerprint: Scalars['String'];
  readonly merchant: Scalars['String'];
  readonly resultUrl: Scalars['String'];
  readonly nabUrl: Scalars['String'];
  readonly action: Scalars['String'];
};

export type PolicyContent = {
  readonly __typename?: 'PolicyContent';
  readonly title: Scalars['String'];
  readonly content: Scalars['String'];
  readonly url?: Maybe<Scalars['String']>;
};

export type Portal = {
  readonly __typename?: 'Portal';
  /** Displayed throughout the application process. */
  readonly title: Scalars['String'];
  /** Used in portal URL and API authentication. Only chars, NO DASHES. Eg. https://secure.polygongroup.com.au/apply/PORTAL-SLUG/ */
  readonly slug: Scalars['String'];
  readonly requireDealer: Scalars['Boolean'];
  /** Link to dealer site, Musicorp, etc.  NOTE: Its also critical that this matches studiot19rentals netloc as CORS fails if they differ (http is OK). */
  readonly portalUrl: Scalars['String'];
  /** Link to Polygon, Studio19, etc. */
  readonly companyUrl: Scalars['String'];
  /** Where "Add new item" buttons link to. Defaults to Portal URL */
  readonly addProductUrl: Scalars['String'];
  readonly portalLogoUrl?: Maybe<Scalars['String']>;
  readonly companyLogoUrl?: Maybe<Scalars['String']>;
  readonly tradingName: Scalars['String'];
  /** e.g. Musicorp Australia (a trading name of Polygon Group ABN 00000) */
  readonly fullTradingName: Scalars['String'];
  readonly isStudio19: Scalars['Boolean'];
  readonly policies: PortalPolicies;
  readonly analytics: PortalAnalytics;
  readonly myApplication?: Maybe<Application>;
  /** The last completed application id for this user on this portal */
  readonly myLastApplicationId?: Maybe<Scalars['Int']>;
  readonly dealers?: Maybe<ReadonlyArray<Dealer>>;
  readonly themeSlug: Scalars['String'];
  readonly willRaiseAnUnexpectedError?: Maybe<Scalars['String']>;
  readonly willRaiseAnExpiredError?: Maybe<Scalars['String']>;
};


export type PortalWillRaiseAnUnexpectedErrorArgs = {
  name?: Maybe<Scalars['String']>;
};

export type PortalAnalytics = {
  readonly __typename?: 'PortalAnalytics';
  readonly trackingId?: Maybe<Scalars['String']>;
  readonly linkedDomain?: Maybe<Scalars['String']>;
  readonly sendAllEvents: Scalars['Boolean'];
  readonly adwordsConversionId?: Maybe<Scalars['String']>;
  readonly conversionScript?: Maybe<Scalars['String']>;
};

/** The collection of policies relating to a portal */
export type PortalPolicies = {
  readonly __typename?: 'PortalPolicies';
  readonly terms?: Maybe<PolicyContent>;
  readonly privacy?: Maybe<PolicyContent>;
  readonly protectionPlan?: Maybe<PolicyContent>;
  readonly creditGuideUrl?: Maybe<Scalars['String']>;
  readonly privacyPolicyUrl: Scalars['String'];
  readonly websiteTermsUrl: Scalars['String'];
};

export type ProductTypeInfo = {
  readonly __typename?: 'ProductTypeInfo';
  readonly title: Scalars['String'];
  readonly content: Scalars['String'];
};

/** Represents the estimated price to purchase this item outright after renting for this number of months. */
export type PurchasePrice = {
  readonly __typename?: 'PurchasePrice';
  /** The number of months elapsed on the rental contract. */
  readonly month: Scalars['Int'];
  /** The estimated price to purchase this item outright. */
  readonly amount: Scalars['Decimal'];
};

export type Query = {
  readonly __typename?: 'Query';
  readonly tuition: Tuition;
  readonly portal?: Maybe<Portal>;
  readonly occupations: ReadonlyArray<Scalars['String']>;
};


export type QueryPortalArgs = {
  slug: Scalars['String'];
};

export type Rate = {
  readonly __typename?: 'Rate';
  readonly rentalMonthly?: Maybe<Scalars['Decimal']>;
  readonly protectionPlan?: Maybe<Scalars['Decimal']>;
  readonly minMonths?: Maybe<Scalars['Int']>;
  readonly rebateMonths?: Maybe<Scalars['Int']>;
  readonly purchasePricing: ReadonlyArray<PurchasePrice>;
  readonly freight?: Maybe<Scalars['Decimal']>;
  readonly bond?: Maybe<Scalars['Decimal']>;
  readonly selected?: Maybe<Scalars['Boolean']>;
};

export type RateOption = {
  readonly __typename?: 'RateOption';
  readonly rentalMonthly: Scalars['Decimal'];
  readonly minMonths: Scalars['Int'];
  readonly rebateMonths: Scalars['Int'];
};

export type RecordBankStatementsResultInput = {
  readonly portalSlug: Scalars['String'];
  readonly result: BankStatementsResult;
  readonly reference: Scalars['String'];
  readonly errorCode?: Maybe<Scalars['String']>;
  readonly errorMessage?: Maybe<Scalars['String']>;
};

/** After the user has completed the BankStatements process, the iframe will use window.postMessage to send the result to the parent frame.  Call this mutation with that result. */
export type RecordBankStatementsResultPayload = {
  readonly __typename?: 'RecordBankStatementsResultPayload';
  readonly application?: Maybe<Application>;
};

export type RecordInteractionInput = {
  readonly portalSlug: Scalars['String'];
  readonly stepSlug: Scalars['String'];
  readonly stepName?: Maybe<Scalars['String']>;
};

export type RecordInteractionPayload = {
  readonly __typename?: 'RecordInteractionPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly interactionId?: Maybe<Scalars['Int']>;
};

export type RegisterRentalItemInput = {
  readonly modelNumber?: Maybe<Scalars['String']>;
  readonly serialNo?: Maybe<Scalars['String']>;
  /** Manufacturers Part Number */
  readonly mpn?: Maybe<Scalars['String']>;
  /** Global Trade Item Number (usually a barcode) */
  readonly gtin?: Maybe<Scalars['String']>;
  /** Stock-Keeping Unit */
  readonly sku?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  /** The number the user has selected */
  readonly qty: Scalars['Int'];
  readonly maxQty?: Maybe<Scalars['Int']>;
  readonly isAccessory?: Maybe<Scalars['Boolean']>;
  readonly requireDealer?: Maybe<Scalars['Boolean']>;
  readonly expires?: Maybe<Scalars['DateTime']>;
  /** String that is sent to the backend */
  readonly xmlCategory?: Maybe<Scalars['String']>;
  /** String that is sent to the backend */
  readonly xmlParentCategory?: Maybe<Scalars['String']>;
  /** An optional tag which can be used for reporting.  Eg "PrelovedSale" */
  readonly tag?: Maybe<Scalars['String']>;
  /** The ID of the product in your system/database.  Probably a primary key or SKU.  Something that that won't change and relates to the value specified for 'source'. */
  readonly id: Scalars['String'];
  /** The name of the product. */
  readonly name: Scalars['String'];
  /** The sub-title of the product.  Will be shown in braces after the product name.  Eg. 'Product name (sub name)' */
  readonly subName?: Maybe<Scalars['String']>;
  /** The full starting price value of this item which the rental rates and purchase pricing were calculated from. */
  readonly rrp: Scalars['Decimal'];
  readonly rate: RentalRateInput;
  readonly alternativeRates?: Maybe<ReadonlyArray<RentalRateInput>>;
  readonly condition: Condition;
  /** Only used for new items (but a future update might this for pre-loved). */
  readonly newProductDescription?: Maybe<Scalars['String']>;
  /** Only used for pre-loved items */
  readonly prelovedConditionReport?: Maybe<Scalars['String']>;
  /** Thumbnail image to show next to item in the cart. During the API call, the application server will fetch this, and resize it down to thumbnail size. Therefore, the size & network speed of the image will directly affect the speed of this API call. So, specify a medium size image.  Small enough to be fast, but large enough to not regrade too much if resized again. */
  readonly image?: Maybe<Scalars['String']>;
  readonly suggestions?: Maybe<ReadonlyArray<RentalSuggestedItemInput>>;
  readonly subItems?: Maybe<ReadonlyArray<RentalSubItemInput>>;
};

export type RegisterRentalItemsInput = {
  readonly originatingReferer?: Maybe<Scalars['String']>;
  /** Used to show the "sub-store" of site.  For example, yourgolfpro.net has a lot of different stores on the same domain */
  readonly storeName?: Maybe<Scalars['String']>;
  readonly storeLogo?: Maybe<Scalars['String']>;
  /** An optional UUIDv4 that is generated on the clients browser that is sent with every add items and cart ajax request so that the cart items can be linked without the use of third-party cookies. */
  readonly thirdPartyCartUuid?: Maybe<Scalars['String']>;
  readonly portalSlug: Scalars['String'];
  /** The database/system that the "id" related to.  We will have told you what to set this value to.  If you only have one product database, then you will have a single value for everything.  If you have multiple product databases, then you will one per database, eg. "studio19:mybikeshop".  If we were to give you this "source" value and an items "id" you should theoretically be able to look up the product for us. */
  readonly source: Scalars['String'];
  /** A list of the items to add.  Usually this will be one, but it can be any number. */
  readonly items: ReadonlyArray<RegisterRentalItemInput>;
};

/** Only used by an ecommerce sites register items that will be added to a customers rental cart. Returns a redirect URL that if navigated to by a customer, will add these items to their cart. */
export type RegisterRentalItemsPayload = {
  readonly __typename?: 'RegisterRentalItemsPayload';
  readonly items?: Maybe<ReadonlyArray<Item>>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly redirectUrl?: Maybe<Scalars['String']>;
  readonly redirectUrlHash?: Maybe<Scalars['String']>;
  readonly redirectUrlItemIds?: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type RemoveItemInput = {
  /** The ID of the item */
  readonly id: Scalars['Int'];
};

/** Remove an item from the cart.  If this item was a suggested item, it will be be reverted to a suggested item by changing the quantity to 0.  Otherwise this item will be deleted. */
export type RemoveItemPayload = {
  readonly __typename?: 'RemoveItemPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

/** Represents the estimated price to purchase this item outright after renting for this number of months. */
export type RentalPurchasePriceInput = {
  /** The number of months elapsed on the rental contract. */
  readonly month: Scalars['Int'];
  /** The estimated price to purchase this item outright. */
  readonly amount: Scalars['Decimal'];
};

export type RentalRateInput = {
  readonly rentalMonthly: Scalars['Decimal'];
  readonly minMonths: Scalars['Int'];
  readonly rebateMonths: Scalars['Int'];
  readonly purchasePricing: ReadonlyArray<RentalPurchasePriceInput>;
  /** Admin, handling and/or shipping fee */
  readonly freight: Scalars['Decimal'];
  /** Omit if protection plan is not available for this item. */
  readonly protectionPlan?: Maybe<Scalars['Decimal']>;
  /** Sometimes an item such as an expensive grand piano also has a bond fee. */
  readonly bond?: Maybe<Scalars['Decimal']>;
};

export type RentalSubItemInput = {
  readonly modelNumber?: Maybe<Scalars['String']>;
  readonly serialNo?: Maybe<Scalars['String']>;
  /** Manufacturers Part Number */
  readonly mpn?: Maybe<Scalars['String']>;
  /** Global Trade Item Number (usually a barcode) */
  readonly gtin?: Maybe<Scalars['String']>;
  /** Stock-Keeping Unit */
  readonly sku?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  /** The number the user has selected */
  readonly qty: Scalars['Int'];
  readonly maxQty?: Maybe<Scalars['Int']>;
  readonly isAccessory?: Maybe<Scalars['Boolean']>;
  readonly requireDealer?: Maybe<Scalars['Boolean']>;
  readonly expires?: Maybe<Scalars['DateTime']>;
  /** String that is sent to the backend */
  readonly xmlCategory?: Maybe<Scalars['String']>;
  /** String that is sent to the backend */
  readonly xmlParentCategory?: Maybe<Scalars['String']>;
  /** An optional tag which can be used for reporting.  Eg "PrelovedSale" */
  readonly tag?: Maybe<Scalars['String']>;
  /** The ID of the product in your system/database.  Probably a primary key or SKU.  Something that that won't change and relates to the value specified for 'source'. */
  readonly id: Scalars['String'];
  /** The name of the product. */
  readonly name: Scalars['String'];
  /** The sub-title of the product.  Will be shown in braces after the product name.  Eg. 'Product name (sub name)' */
  readonly subName?: Maybe<Scalars['String']>;
  /** The full starting price value of this item which the rental rates and purchase pricing were calculated from. */
  readonly rrp: Scalars['Decimal'];
  readonly rate: RentalRateInput;
  readonly alternativeRates?: Maybe<ReadonlyArray<RentalRateInput>>;
  readonly condition: Condition;
  /** Only used for new items (but a future update might this for pre-loved). */
  readonly newProductDescription?: Maybe<Scalars['String']>;
  /** Only used for pre-loved items */
  readonly prelovedConditionReport?: Maybe<Scalars['String']>;
  /** Thumbnail image to show next to item in the cart. During the API call, the application server will fetch this, and resize it down to thumbnail size. Therefore, the size & network speed of the image will directly affect the speed of this API call. So, specify a medium size image.  Small enough to be fast, but large enough to not regrade too much if resized again. */
  readonly image?: Maybe<Scalars['String']>;
};

export type RentalSuggestedItemInput = {
  readonly modelNumber?: Maybe<Scalars['String']>;
  readonly serialNo?: Maybe<Scalars['String']>;
  /** Manufacturers Part Number */
  readonly mpn?: Maybe<Scalars['String']>;
  /** Global Trade Item Number (usually a barcode) */
  readonly gtin?: Maybe<Scalars['String']>;
  /** Stock-Keeping Unit */
  readonly sku?: Maybe<Scalars['String']>;
  readonly url?: Maybe<Scalars['String']>;
  readonly maxQty?: Maybe<Scalars['Int']>;
  readonly isAccessory?: Maybe<Scalars['Boolean']>;
  readonly requireDealer?: Maybe<Scalars['Boolean']>;
  readonly expires?: Maybe<Scalars['DateTime']>;
  /** String that is sent to the backend */
  readonly xmlCategory?: Maybe<Scalars['String']>;
  /** String that is sent to the backend */
  readonly xmlParentCategory?: Maybe<Scalars['String']>;
  /** An optional tag which can be used for reporting.  Eg "PrelovedSale" */
  readonly tag?: Maybe<Scalars['String']>;
  /** The ID of the product in your system/database.  Probably a primary key or SKU.  Something that that won't change and relates to the value specified for 'source'. */
  readonly id: Scalars['String'];
  /** The name of the product. */
  readonly name: Scalars['String'];
  /** The sub-title of the product.  Will be shown in braces after the product name.  Eg. 'Product name (sub name)' */
  readonly subName?: Maybe<Scalars['String']>;
  /** The full starting price value of this item which the rental rates and purchase pricing were calculated from. */
  readonly rrp: Scalars['Decimal'];
  readonly rate: RentalRateInput;
  readonly alternativeRates?: Maybe<ReadonlyArray<RentalRateInput>>;
  readonly condition: Condition;
  /** Only used for new items (but a future update might this for pre-loved). */
  readonly newProductDescription?: Maybe<Scalars['String']>;
  /** Only used for pre-loved items */
  readonly prelovedConditionReport?: Maybe<Scalars['String']>;
  /** Thumbnail image to show next to item in the cart. During the API call, the application server will fetch this, and resize it down to thumbnail size. Therefore, the size & network speed of the image will directly affect the speed of this API call. So, specify a medium size image.  Small enough to be fast, but large enough to not regrade too much if resized again. */
  readonly image?: Maybe<Scalars['String']>;
};

export type RestartApplicationInput = {
  readonly portalSlug: Scalars['String'];
};

/** Starts a new application with the same cart items updated with the latest prices and availability. All personal information will be discarded. */
export type RestartApplicationPayload = {
  readonly __typename?: 'RestartApplicationPayload';
  readonly portal?: Maybe<Portal>;
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

/** An enumeration. */
export enum SalutationSource {
  /** Mr. */
  Mr = 'MR',
  /** Mrs. */
  Mrs = 'MRS',
  /** Miss */
  Miss = 'MISS',
  /** Other */
  Other = 'OTHER'
}

/** An enumeration. */
export enum SchoolState {
  /** Australian Capital Territory */
  Act = 'ACT',
  /** New South Wales */
  Nsw = 'NSW',
  /** Northern Territory */
  Nt = 'NT',
  /** Queensland */
  Qld = 'QLD',
  /** South Australia */
  Sa = 'SA',
  /** Tasmania */
  Tas = 'TAS',
  /** Victoria */
  Vic = 'VIC',
  /** Western Australia */
  Wa = 'WA'
}

/** Call this mutation to send an SMS signature for an application. */
export type SendSmsSignature = {
  readonly __typename?: 'SendSmsSignature';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SendSmsSignatureInput = {
  readonly portalSlug: Scalars['String'];
  readonly number: Scalars['String'];
};

export type SetAcceptedAgreementInput = {
  readonly portalSlug: Scalars['String'];
  readonly accepted: Scalars['Boolean'];
  readonly marketingConsent: Scalars['Boolean'];
};

/** Call this mutation to indicate agreement acceptance and marketing consent. */
export type SetAcceptedAgreementPayload = {
  readonly __typename?: 'SetAcceptedAgreementPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SetDeliveryAddressInput = {
  readonly street?: Maybe<Scalars['String']>;
  readonly suburb?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly postcode?: Maybe<Scalars['String']>;
  readonly portalSlug: Scalars['String'];
  readonly fullAddress?: Maybe<Scalars['String']>;
  readonly deliveryUseHomeAddress?: Maybe<Scalars['Boolean']>;
  readonly deliveryInstructions?: Maybe<Scalars['String']>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetDeliveryAddressPayload = {
  readonly __typename?: 'SetDeliveryAddressPayload';
  readonly street?: Maybe<Scalars['String']>;
  readonly suburb?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly postcode?: Maybe<Scalars['String']>;
  readonly portalSlug: Scalars['String'];
  readonly fullAddress?: Maybe<Scalars['String']>;
  readonly deliveryUseHomeAddress?: Maybe<Scalars['Boolean']>;
  readonly deliveryInstructions?: Maybe<Scalars['String']>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly application?: Maybe<Application>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetDeliveryDealerInput = {
  readonly dealer: Scalars['ID'];
  readonly portalSlug: Scalars['String'];
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetDeliveryDealerPayload = {
  readonly __typename?: 'SetDeliveryDealerPayload';
  readonly dealer: Scalars['ID'];
  readonly portalSlug: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly application?: Maybe<Application>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetImportantInfoReadInput = {
  readonly portalSlug: Scalars['String'];
};

export type SetImportantInfoReadPayload = {
  readonly __typename?: 'SetImportantInfoReadPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SetLivingSituationInput = {
  readonly homeType: HomeTypeSource;
  readonly homeDurationYears: Scalars['Int'];
  readonly homeDurationMonths?: Maybe<Scalars['Int']>;
  readonly portalSlug: Scalars['String'];
  readonly id?: Maybe<Scalars['ID']>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetLivingSituationPayload = {
  readonly __typename?: 'SetLivingSituationPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetRebateMonthsInput = {
  readonly portalSlug: Scalars['String'];
  readonly rebateMonths: Scalars['Int'];
};

export type SetRebateMonthsPayload = {
  readonly __typename?: 'SetRebateMonthsPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SetResidentStatusInput = {
  readonly residentStatus?: Maybe<Scalars['String']>;
  readonly portalSlug: Scalars['String'];
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetResidentStatusPayload = {
  readonly __typename?: 'SetResidentStatusPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetResidentialAddressInput = {
  readonly street?: Maybe<Scalars['String']>;
  readonly suburb?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly postcode?: Maybe<Scalars['String']>;
  readonly portalSlug: Scalars['String'];
  readonly fullAddress: Scalars['String'];
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetResidentialAddressPayload = {
  readonly __typename?: 'SetResidentialAddressPayload';
  readonly street?: Maybe<Scalars['String']>;
  readonly suburb?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly postcode?: Maybe<Scalars['String']>;
  readonly portalSlug: Scalars['String'];
  readonly fullAddress: Scalars['String'];
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
  readonly application?: Maybe<Application>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type SetTestDataStateInput = {
  readonly action: TestDataState;
  readonly portalSlug: Scalars['String'];
};

/** Sets up the application with test data for specific scenarios. */
export type SetTestDataStatePayload = {
  readonly __typename?: 'SetTestDataStatePayload';
  readonly application?: Maybe<Application>;
  readonly portal?: Maybe<Portal>;
};

export type SkipBankStatementsInput = {
  readonly portalSlug: Scalars['String'];
};

/** Call this mutation if the user does not want to supply their bank statements as part of the application. */
export type SkipBankStatementsPayload = {
  readonly __typename?: 'SkipBankStatementsPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SkipPaymentInput = {
  readonly portalSlug: Scalars['String'];
};

/** Call this mutation if the user is having trouble completing their payment details. */
export type SkipPaymentPayload = {
  readonly __typename?: 'SkipPaymentPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

/** Call this mutation if the user is having trouble completing their payment details. */
export type SkipTuitionPaymentPayload = {
  readonly __typename?: 'SkipTuitionPaymentPayload';
  readonly application?: Maybe<TuitionApplication>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SubmitApplicationInput = {
  readonly portalSlug: Scalars['String'];
  readonly signature: Scalars['String'];
};

export type SubmitApplicationPayload = {
  readonly __typename?: 'SubmitApplicationPayload';
  readonly portal?: Maybe<Portal>;
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SubmitTuitionApplicationPayload = {
  readonly __typename?: 'SubmitTuitionApplicationPayload';
  readonly application?: Maybe<TuitionApplication>;
  readonly tuition?: Maybe<Tuition>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type SuggestedItem = {
  readonly __typename?: 'SuggestedItem';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly url: Scalars['String'];
  readonly rentalMonthly?: Maybe<Scalars['Decimal']>;
  readonly imageUrl?: Maybe<Scalars['String']>;
  /** The main title of the products */
  readonly title: Scalars['String'];
  /** Specific information about the variant or configuration */
  readonly subtitle?: Maybe<Scalars['String']>;
};

export enum TestDataState {
  AddPersonalDetails = 'ADD_PERSONAL_DETAILS',
  AddSimplePrelovedItem = 'ADD_SIMPLE_PRELOVED_ITEM',
  AddSimpleNewItem = 'ADD_SIMPLE_NEW_ITEM',
  SetupS19InstoreApp = 'SETUP_S19_INSTORE_APP'
}

export type TestMode = {
  readonly __typename?: 'TestMode';
  readonly application: Scalars['Boolean'];
  readonly bankStatements: Scalars['Boolean'];
};

export type Tuition = {
  readonly __typename?: 'Tuition';
  readonly myApplication: TuitionApplication;
  readonly schools?: Maybe<ReadonlyArray<TuitionSchool>>;
  readonly school?: Maybe<TuitionSchool>;
  readonly states: ReadonlyArray<TuitionSchoolState>;
  readonly yearLevels: ReadonlyArray<Scalars['String']>;
  readonly tuitionTerms?: Maybe<PolicyContent>;
};


export type TuitionSchoolsArgs = {
  filter?: Maybe<TuitionSchoolsFilter>;
};


export type TuitionSchoolArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type TuitionAddressInput = {
  /** The full address. Usually the typeahead suggestion or the manual address parts concatenated */
  readonly fullAddress: Scalars['String'];
  /** If address was manually entered, pass these values to assist in debugging issues. */
  readonly manuallyEnteredValues?: Maybe<TuitionManualAddressInput>;
};

export type TuitionApplication = {
  readonly __typename?: 'TuitionApplication';
  /** The pk of the tuition application.  Will be null is there is not an application yet (or it has expired). */
  readonly id?: Maybe<Scalars['Int']>;
  readonly school?: Maybe<TuitionSchool>;
  readonly parentTitle?: Maybe<Scalars['String']>;
  readonly parentFirstName: Scalars['String'];
  readonly parentLastName: Scalars['String'];
  readonly parentEmail: Scalars['String'];
  readonly parentPhoneMobile: Scalars['String'];
  readonly parentDob?: Maybe<Scalars['Date']>;
  readonly notes: Scalars['String'];
  /** Whether the user has agreed to the agreement on the final step of the application. */
  readonly acceptedAgreement?: Maybe<Scalars['DateTime']>;
  readonly submitted?: Maybe<Scalars['DateTime']>;
  readonly validationErrors: ReadonlyArray<TuitionApplicationError>;
  readonly residentialAddress?: Maybe<Address>;
  readonly deliveryAddress?: Maybe<Address>;
  readonly payment?: Maybe<CreditCard>;
  readonly hasDirectPostFailed: Scalars['Boolean'];
  readonly paymentSkipped?: Maybe<Scalars['Boolean']>;
  /** The last completed tuition application id for this user */
  readonly myLastApplicationId?: Maybe<Scalars['Int']>;
  readonly students: ReadonlyArray<TuitionStudent>;
};

export type TuitionApplicationError = {
  readonly message: Scalars['String'];
};

export type TuitionApplicationFieldError = TuitionApplicationError & {
  readonly __typename?: 'TuitionApplicationFieldError';
  readonly message: Scalars['String'];
  readonly field: TuitionErrorField;
  readonly label?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum TuitionErrorField {
  AcceptedAgreement = 'acceptedAgreement',
  DeliveryAddress = 'deliveryAddress',
  FirstName = 'firstName',
  Instrument1 = 'instrument1',
  Instrument2 = 'instrument2',
  LastName = 'lastName',
  Notes = 'notes',
  ParentDob = 'parentDob',
  ParentEmail = 'parentEmail',
  ParentFirstName = 'parentFirstName',
  ParentLastName = 'parentLastName',
  ParentPhoneMobile = 'parentPhoneMobile',
  ParentTitle = 'parentTitle',
  ResidentialAddress = 'residentialAddress',
  School = 'school',
  YearLevel = 'yearLevel'
}

export enum TuitionErrorType {
  NoActiveApplication = 'NO_ACTIVE_APPLICATION',
  PaymentMissing = 'PAYMENT_MISSING',
  PaymentDeclined = 'PAYMENT_DECLINED'
}

export type TuitionInstrument = {
  readonly __typename?: 'TuitionInstrument';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly termFee: Scalars['Decimal'];
};

export type TuitionManualAddressInput = {
  readonly street?: Maybe<Scalars['String']>;
  readonly suburb?: Maybe<Scalars['String']>;
  readonly state?: Maybe<Scalars['String']>;
  readonly postcode?: Maybe<Scalars['String']>;
};

export type TuitionSchool = {
  readonly __typename?: 'TuitionSchool';
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly state: SchoolState;
  readonly isActive: Scalars['Boolean'];
  readonly instruments: ReadonlyArray<TuitionInstrument>;
};

export type TuitionSchoolState = {
  readonly __typename?: 'TuitionSchoolState';
  readonly abbrev: Scalars['String'];
  readonly name: Scalars['String'];
};

export type TuitionSchoolsFilter = {
  readonly state?: Maybe<Scalars['String']>;
  readonly search?: Maybe<Scalars['String']>;
};

export type TuitionStudent = {
  readonly __typename?: 'TuitionStudent';
  readonly id: Scalars['Int'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly yearLevel: Scalars['String'];
  readonly instrument1: TuitionInstrument;
  readonly instrument2: TuitionInstrument;
};

export type TuitionStudentFieldError = TuitionApplicationError & {
  readonly __typename?: 'TuitionStudentFieldError';
  readonly message: Scalars['String'];
  readonly index: Scalars['Int'];
  readonly field: TuitionErrorField;
  readonly label?: Maybe<Scalars['String']>;
};

export type TuitionStudentInput = {
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly yearLevel: Scalars['String'];
  readonly instrument1: Scalars['Int'];
  readonly instrument2: Scalars['Int'];
  /** Set this to update a student.  Leaving it blank will create a new student. */
  readonly id?: Maybe<Scalars['Int']>;
};

export type TuitionTypeError = TuitionApplicationError & {
  readonly __typename?: 'TuitionTypeError';
  readonly message: Scalars['String'];
  readonly type: TuitionErrorType;
};

export type UpdateCustomerInput = {
  readonly title?: Maybe<SalutationSource>;
  readonly firstName?: Maybe<Scalars['String']>;
  readonly lastName?: Maybe<Scalars['String']>;
  readonly email?: Maybe<Scalars['String']>;
  readonly phoneMobile?: Maybe<Scalars['String']>;
  readonly dob?: Maybe<Scalars['Date']>;
  readonly licenceNo?: Maybe<Scalars['String']>;
  readonly altContactName?: Maybe<Scalars['String']>;
  readonly altContactPhone?: Maybe<Scalars['String']>;
  readonly workComments?: Maybe<Scalars['String']>;
  readonly marketingConsent?: Maybe<Scalars['Boolean']>;
  readonly portalSlug: Scalars['ID'];
  /** Only validate the data. No changes will be made. */
  readonly validateOnly?: Maybe<Scalars['Boolean']>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCustomerPayload = {
  readonly __typename?: 'UpdateCustomerPayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<ErrorType>>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateIncomeInput = {
  readonly workBasis?: Maybe<ApplicationWorkBasis>;
  readonly employer?: Maybe<Scalars['String']>;
  readonly phoneWork?: Maybe<Scalars['String']>;
  readonly workTimeYears?: Maybe<Scalars['Int']>;
  readonly workTimeMonths?: Maybe<Scalars['Int']>;
  readonly occupation?: Maybe<Scalars['String']>;
  readonly livingExpensesPercentage?: Maybe<ApplicationLivingExpensesPercentage>;
  readonly portalSlug: Scalars['ID'];
  /** Only validate the data. No changes will be made. */
  readonly validateOnly?: Maybe<Scalars['Boolean']>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateIncomePayload = {
  readonly __typename?: 'UpdateIncomePayload';
  readonly application?: Maybe<Application>;
  readonly errors?: Maybe<ReadonlyArray<ErrorType>>;
  readonly clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateItemInput = {
  /** The ID of the item */
  readonly id: Scalars['Int'];
  /** Quantity */
  readonly qty: Scalars['Int'];
};

export type UpdateItemPayload = {
  readonly __typename?: 'UpdateItemPayload';
  readonly application?: Maybe<Application>;
  readonly item?: Maybe<Item>;
  readonly errors?: Maybe<ReadonlyArray<Maybe<ErrorType>>>;
};

export type UpdateProtectionPlanInput = {
  /** Whether to include the purchase plan on this application. */
  readonly protectionPlan: Scalars['Boolean'];
};

export type UpdateProtectionPlanPayload = {
  readonly __typename?: 'UpdateProtectionPlanPayload';
  readonly application?: Maybe<Application>;
};

export type UpdateTuitionApplicationInput = {
  readonly school?: Maybe<Scalars['Int']>;
  readonly parentTitle?: Maybe<Scalars['String']>;
  readonly parentFirstName?: Maybe<Scalars['String']>;
  readonly parentLastName?: Maybe<Scalars['String']>;
  readonly parentEmail?: Maybe<Scalars['String']>;
  readonly parentPhoneMobile?: Maybe<Scalars['String']>;
  readonly parentDob?: Maybe<Scalars['Date']>;
  readonly notes?: Maybe<Scalars['String']>;
  readonly residentialAddress?: Maybe<TuitionAddressInput>;
  /** Use null to specify delivery to residential address. */
  readonly deliveryAddress?: Maybe<TuitionAddressInput>;
  /** If provided, this is the entire set of students.  Students with id's will be updated, with no id's deleted, and not specified will be deleted. */
  readonly students?: Maybe<ReadonlyArray<TuitionStudentInput>>;
};

export type UpdateTuitionApplicationPayload = {
  readonly __typename?: 'UpdateTuitionApplicationPayload';
  readonly application?: Maybe<TuitionApplication>;
  readonly errors?: Maybe<ReadonlyArray<TuitionApplicationError>>;
};

export type ValidateAddressInput = {
  readonly address: Scalars['String'];
};

export type ValidateAddressPayload = {
  readonly __typename?: 'ValidateAddressPayload';
  readonly address?: Maybe<Address>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  readonly __typename?: '__Type';
  readonly kind: __TypeKind;
  readonly name?: Maybe<Scalars['String']>;
  readonly description?: Maybe<Scalars['String']>;
  readonly specifiedByUrl?: Maybe<Scalars['String']>;
  readonly fields?: Maybe<ReadonlyArray<__Field>>;
  readonly interfaces?: Maybe<ReadonlyArray<__Type>>;
  readonly possibleTypes?: Maybe<ReadonlyArray<__Type>>;
  readonly enumValues?: Maybe<ReadonlyArray<__EnumValue>>;
  readonly inputFields?: Maybe<ReadonlyArray<__InputValue>>;
  readonly ofType?: Maybe<__Type>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByUrl`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  Scalar = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  Object = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  Interface = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  Union = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  Enum = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  InputObject = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  List = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NonNull = 'NON_NULL'
}

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  readonly __typename?: '__Field';
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly args: ReadonlyArray<__InputValue>;
  readonly type: __Type;
  readonly isDeprecated: Scalars['Boolean'];
  readonly deprecationReason?: Maybe<Scalars['String']>;
};


/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  readonly __typename?: '__InputValue';
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly type: __Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  readonly defaultValue?: Maybe<Scalars['String']>;
  readonly isDeprecated: Scalars['Boolean'];
  readonly deprecationReason?: Maybe<Scalars['String']>;
};

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  readonly __typename?: '__EnumValue';
  readonly name: Scalars['String'];
  readonly description?: Maybe<Scalars['String']>;
  readonly isDeprecated: Scalars['Boolean'];
  readonly deprecationReason?: Maybe<Scalars['String']>;
};

export type CreditCardFragment = (
  { readonly __typename?: 'CreditCard' }
  & Pick<CreditCard, 'maskedCardNumber' | 'expiryMM' | 'expiryYYYY'>
);

export type NabCrnFragment = (
  { readonly __typename?: 'NabCrn' }
  & Pick<NabCrn, 'crn' | 'timestamp' | 'fingerprint' | 'resultUrl' | 'nabUrl' | 'action' | 'merchant'>
);

export type NabCustomerRecordFragment = (
  { readonly __typename?: 'NabCustomerRecord' }
  & Pick<NabCustomerRecord, 'id' | 'timestamp' | 'crn' | 'fingerprint' | 'merchant' | 'resultUrl' | 'nabUrl' | 'action'>
);

export type MyTuitionApplicationQueryVariables = Exact<{ [key: string]: never; }>;


export type MyTuitionApplicationQuery = (
  { readonly __typename?: 'Query' }
  & { readonly tuition: (
    { readonly __typename?: 'Tuition' }
    & { readonly myApplication: (
      { readonly __typename?: 'TuitionApplication' }
      & TuitionApplicationFragment
    ) }
  ) }
);

export type TuitionApplicationFragment = (
  { readonly __typename?: 'TuitionApplication' }
  & Pick<TuitionApplication, 'id' | 'submitted' | 'myLastApplicationId'>
  & { readonly school?: Maybe<(
    { readonly __typename?: 'TuitionSchool' }
    & Pick<TuitionSchool, 'id' | 'name' | 'state'>
  )>, readonly students: ReadonlyArray<(
    { readonly __typename?: 'TuitionStudent' }
    & StudentFragment
  )> }
  & ParentFragment
  & AdditionalInfoFragment
  & AddressesFragment
  & TuitionApp_PaymentFragment
  & ValidationErrorsFragment
);

export type ValidationErrorsFragment = (
  { readonly __typename?: 'TuitionApplication' }
  & { readonly validationErrors: ReadonlyArray<(
    { readonly __typename?: 'TuitionApplicationFieldError' }
    & TuitionError_TuitionApplicationFieldError_Fragment
  ) | (
    { readonly __typename?: 'TuitionStudentFieldError' }
    & TuitionError_TuitionStudentFieldError_Fragment
  ) | (
    { readonly __typename?: 'TuitionTypeError' }
    & TuitionError_TuitionTypeError_Fragment
  )> }
);

type TuitionError_TuitionApplicationFieldError_Fragment = (
  { readonly __typename: 'TuitionApplicationFieldError' }
  & Pick<TuitionApplicationFieldError, 'field' | 'label' | 'message'>
);

type TuitionError_TuitionStudentFieldError_Fragment = (
  { readonly __typename: 'TuitionStudentFieldError' }
  & Pick<TuitionStudentFieldError, 'field' | 'label' | 'index' | 'message'>
);

type TuitionError_TuitionTypeError_Fragment = (
  { readonly __typename: 'TuitionTypeError' }
  & Pick<TuitionTypeError, 'type' | 'message'>
);

export type TuitionErrorFragment = TuitionError_TuitionApplicationFieldError_Fragment | TuitionError_TuitionStudentFieldError_Fragment | TuitionError_TuitionTypeError_Fragment;

export type EnumChoicesTypeInfoFragment = (
  { readonly __typename?: '__Type' }
  & Pick<__Type, 'kind' | 'name'>
  & { readonly enumValues?: Maybe<ReadonlyArray<(
    { readonly __typename?: '__EnumValue' }
    & Pick<__EnumValue, 'name' | 'description' | 'isDeprecated'>
  )>> }
);

export type CancelTutionApplicationMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelTutionApplicationMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly cancelTuitionApplication?: Maybe<(
    { readonly __typename?: 'CancelTuitionApplicationPayload' }
    & { readonly application: (
      { readonly __typename?: 'TuitionApplication' }
      & TuitionApplicationFragment
    ) }
  )> }
);

export type UpdateApplicationMutationVariables = Exact<{
  input: UpdateTuitionApplicationInput;
}>;


export type UpdateApplicationMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly updateTuitionApplication?: Maybe<(
    { readonly __typename?: 'UpdateTuitionApplicationPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'TuitionApplication' }
      & TuitionApplicationFragment
    )>, readonly errors?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'TuitionApplicationFieldError' }
      & TuitionError_TuitionApplicationFieldError_Fragment
    ) | (
      { readonly __typename?: 'TuitionStudentFieldError' }
      & TuitionError_TuitionStudentFieldError_Fragment
    ) | (
      { readonly __typename?: 'TuitionTypeError' }
      & TuitionError_TuitionTypeError_Fragment
    )>> }
  )> }
);

export type AdditionalInfoFragment = (
  { readonly __typename?: 'TuitionApplication' }
  & Pick<TuitionApplication, 'notes'>
);

export type AddressQueryVariables = Exact<{ [key: string]: never; }>;


export type AddressQuery = (
  { readonly __typename?: 'Query' }
  & { readonly tuition: (
    { readonly __typename?: 'Tuition' }
    & { readonly myApplication: (
      { readonly __typename?: 'TuitionApplication' }
      & Pick<TuitionApplication, 'id'>
      & AddressesFragment
    ) }
  ) }
);

export type AddressesFragment = (
  { readonly __typename?: 'TuitionApplication' }
  & Pick<TuitionApplication, 'id'>
  & { readonly residentialAddress?: Maybe<(
    { readonly __typename?: 'Address' }
    & AddressFragment
  )>, readonly deliveryAddress?: Maybe<(
    { readonly __typename?: 'Address' }
    & AddressFragment
  )> }
);

export type AddressFragment = (
  { readonly __typename?: 'Address' }
  & Pick<Address, 'fullAddress' | 'valid' | 'validated' | 'street' | 'suburb' | 'state' | 'postcode'>
);

export type ParentQueryVariables = Exact<{ [key: string]: never; }>;


export type ParentQuery = (
  { readonly __typename?: 'Query' }
  & { readonly tuition: (
    { readonly __typename?: 'Tuition' }
    & { readonly myApplication: (
      { readonly __typename?: 'TuitionApplication' }
      & ParentFragment
    ) }
  ), readonly salutationSourceEnum?: Maybe<(
    { readonly __typename?: '__Type' }
    & EnumChoicesTypeInfoFragment
  )> }
);

export type ParentFragment = (
  { readonly __typename?: 'TuitionApplication' }
  & Pick<TuitionApplication, 'id' | 'parentTitle' | 'parentFirstName' | 'parentLastName' | 'parentEmail' | 'parentPhoneMobile' | 'parentDob'>
);

export type TuitionApp_PaymentFragment = (
  { readonly __typename?: 'TuitionApplication' }
  & Pick<TuitionApplication, 'id' | 'hasDirectPostFailed' | 'paymentSkipped'>
  & { readonly payment?: Maybe<(
    { readonly __typename?: 'CreditCard' }
    & CreditCardFragment
  )> }
);

export type SkipTuitionPaymentMutationVariables = Exact<{ [key: string]: never; }>;


export type SkipTuitionPaymentMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly skipTuitionPayment?: Maybe<(
    { readonly __typename?: 'SkipTuitionPaymentPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'TuitionApplication' }
      & TuitionApplicationFragment
    )>, readonly errors?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type InitiateTuitionDirectPostMutationVariables = Exact<{
  input: InitiateTuitionDirectPostInput;
}>;


export type InitiateTuitionDirectPostMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly initiateTuitionDirectPost?: Maybe<(
    { readonly __typename?: 'InitiateTuitionDirectPostPayload' }
    & { readonly nabCrn?: Maybe<(
      { readonly __typename?: 'NabCrn' }
      & NabCrnFragment
    )>, readonly errors?: Maybe<ReadonlyArray<Maybe<(
      { readonly __typename?: 'ErrorType' }
      & Pick<ErrorType, 'field' | 'messages'>
    )>>> }
  )> }
);

export type SchoolStatesAndYearLevelsQueryVariables = Exact<{ [key: string]: never; }>;


export type SchoolStatesAndYearLevelsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly tuition: (
    { readonly __typename?: 'Tuition' }
    & Pick<Tuition, 'yearLevels'>
    & { readonly states: ReadonlyArray<(
      { readonly __typename?: 'TuitionSchoolState' }
      & Pick<TuitionSchoolState, 'abbrev' | 'name'>
    )> }
  ) }
);

export type SchoolsQueryVariables = Exact<{
  filter: TuitionSchoolsFilter;
}>;


export type SchoolsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly tuition: (
    { readonly __typename?: 'Tuition' }
    & { readonly schools?: Maybe<ReadonlyArray<(
      { readonly __typename?: 'TuitionSchool' }
      & SchoolOptionFragment
    )>> }
  ) }
);

export type SchoolOptionFragment = (
  { readonly __typename?: 'TuitionSchool' }
  & Pick<TuitionSchool, 'name' | 'state' | 'id'>
);

export type SchoolQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SchoolQuery = (
  { readonly __typename?: 'Query' }
  & { readonly tuition: (
    { readonly __typename?: 'Tuition' }
    & { readonly school?: Maybe<(
      { readonly __typename: 'TuitionSchool' }
      & Pick<TuitionSchool, 'name' | 'state' | 'id'>
      & { readonly instruments: ReadonlyArray<(
        { readonly __typename?: 'TuitionInstrument' }
        & Pick<TuitionInstrument, 'id' | 'name' | 'termFee'>
      )> }
    )> }
  ) }
);

export type StudentFragment = (
  { readonly __typename?: 'TuitionStudent' }
  & Pick<TuitionStudent, 'id' | 'firstName' | 'lastName' | 'yearLevel'>
  & { readonly instrument1: (
    { readonly __typename?: 'TuitionInstrument' }
    & Pick<TuitionInstrument, 'id' | 'name' | 'termFee'>
  ), readonly instrument2: (
    { readonly __typename?: 'TuitionInstrument' }
    & Pick<TuitionInstrument, 'id' | 'name' | 'termFee'>
  ) }
);

export type StudentsFragment = (
  { readonly __typename?: 'TuitionApplication' }
  & Pick<TuitionApplication, 'id'>
  & { readonly school?: Maybe<(
    { readonly __typename?: 'TuitionSchool' }
    & Pick<TuitionSchool, 'id' | 'name' | 'state'>
  )>, readonly students: ReadonlyArray<(
    { readonly __typename?: 'TuitionStudent' }
    & StudentFragment
  )> }
);

export type TuitionTermsQueryVariables = Exact<{ [key: string]: never; }>;


export type TuitionTermsQuery = (
  { readonly __typename?: 'Query' }
  & { readonly tuition: (
    { readonly __typename?: 'Tuition' }
    & { readonly tuitionTerms?: Maybe<(
      { readonly __typename?: 'PolicyContent' }
      & Pick<PolicyContent, 'content' | 'title' | 'url'>
    )>, readonly myApplication: (
      { readonly __typename?: 'TuitionApplication' }
      & TuitionAgreementFragment
    ) }
  ) }
);

export type AcceptTuitionAgreementMutationVariables = Exact<{
  input: AcceptTuitionAgreementInput;
}>;


export type AcceptTuitionAgreementMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly acceptTuitionAgreement?: Maybe<(
    { readonly __typename?: 'AcceptTuitionAgreementPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'TuitionApplication' }
      & TuitionAgreementFragment
    )> }
  )> }
);

export type TuitionAgreementFragment = (
  { readonly __typename?: 'TuitionApplication' }
  & Pick<TuitionApplication, 'id' | 'acceptedAgreement'>
);

export type SubmitTuitionAppMutationVariables = Exact<{ [key: string]: never; }>;


export type SubmitTuitionAppMutation = (
  { readonly __typename?: 'Mutations' }
  & { readonly submitTuitionApplication?: Maybe<(
    { readonly __typename?: 'SubmitTuitionApplicationPayload' }
    & { readonly application?: Maybe<(
      { readonly __typename?: 'TuitionApplication' }
      & Pick<TuitionApplication, 'id'>
      & TuitionApplicationFragment
    )> }
  )> }
);

export const NabCrnFragmentDoc = gql`
    fragment NabCrn on NabCrn {
  crn
  timestamp
  fingerprint
  resultUrl
  nabUrl
  action
  merchant
}
    `;
export const NabCustomerRecordFragmentDoc = gql`
    fragment NabCustomerRecord on NabCustomerRecord {
  id
  timestamp
  crn
  fingerprint
  merchant
  resultUrl
  nabUrl
  action
}
    `;
export const StudentFragmentDoc = gql`
    fragment Student on TuitionStudent {
  id
  firstName
  lastName
  yearLevel
  instrument1 {
    id
    name
    termFee
  }
  instrument2 {
    id
    name
    termFee
  }
}
    `;
export const ParentFragmentDoc = gql`
    fragment Parent on TuitionApplication {
  id
  parentTitle
  parentFirstName
  parentLastName
  parentEmail
  parentPhoneMobile
  parentDob
}
    `;
export const AdditionalInfoFragmentDoc = gql`
    fragment AdditionalInfo on TuitionApplication {
  notes
}
    `;
export const AddressFragmentDoc = gql`
    fragment Address on Address {
  fullAddress
  valid
  validated
  street
  suburb
  state
  postcode
}
    `;
export const AddressesFragmentDoc = gql`
    fragment Addresses on TuitionApplication {
  id
  residentialAddress {
    ...Address
  }
  deliveryAddress {
    ...Address
  }
}
    ${AddressFragmentDoc}`;
export const CreditCardFragmentDoc = gql`
    fragment CreditCard on CreditCard {
  maskedCardNumber
  expiryMM
  expiryYYYY
}
    `;
export const TuitionApp_PaymentFragmentDoc = gql`
    fragment TuitionApp_Payment on TuitionApplication {
  id
  hasDirectPostFailed
  payment {
    ...CreditCard
  }
  paymentSkipped
}
    ${CreditCardFragmentDoc}`;
export const TuitionErrorFragmentDoc = gql`
    fragment TuitionError on TuitionApplicationError {
  __typename
  message
  ... on TuitionTypeError {
    type
  }
  ... on TuitionApplicationFieldError {
    field
    label
  }
  ... on TuitionStudentFieldError {
    field
    label
    index
  }
}
    `;
export const ValidationErrorsFragmentDoc = gql`
    fragment ValidationErrors on TuitionApplication {
  validationErrors {
    ...TuitionError
  }
}
    ${TuitionErrorFragmentDoc}`;
export const TuitionApplicationFragmentDoc = gql`
    fragment TuitionApplication on TuitionApplication {
  id
  submitted
  myLastApplicationId
  school {
    id
    name
    state
  }
  students {
    ...Student
  }
  ...Parent
  ...AdditionalInfo
  ...Addresses
  ...TuitionApp_Payment
  ...ValidationErrors
}
    ${StudentFragmentDoc}
${ParentFragmentDoc}
${AdditionalInfoFragmentDoc}
${AddressesFragmentDoc}
${TuitionApp_PaymentFragmentDoc}
${ValidationErrorsFragmentDoc}`;
export const EnumChoicesTypeInfoFragmentDoc = gql`
    fragment EnumChoicesTypeInfo on __Type {
  kind
  name
  enumValues {
    name
    description
    isDeprecated
  }
}
    `;
export const SchoolOptionFragmentDoc = gql`
    fragment SchoolOption on TuitionSchool {
  name
  state
  id
}
    `;
export const StudentsFragmentDoc = gql`
    fragment Students on TuitionApplication {
  id
  school {
    id
    name
    state
  }
  students {
    ...Student
  }
}
    ${StudentFragmentDoc}`;
export const TuitionAgreementFragmentDoc = gql`
    fragment TuitionAgreement on TuitionApplication {
  id
  acceptedAgreement
}
    `;
export const MyTuitionApplicationDocument = gql`
    query MyTuitionApplication {
  tuition {
    myApplication {
      ...TuitionApplication
    }
  }
}
    ${TuitionApplicationFragmentDoc}`;

export function useMyTuitionApplicationQuery(options: Omit<Urql.UseQueryArgs<MyTuitionApplicationQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<MyTuitionApplicationQuery>({ query: MyTuitionApplicationDocument, ...options });
};
export const CancelTutionApplicationDocument = gql`
    mutation CancelTutionApplication {
  cancelTuitionApplication {
    application {
      ...TuitionApplication
    }
  }
}
    ${TuitionApplicationFragmentDoc}`;

export function useCancelTutionApplicationMutation() {
  return Urql.useMutation<CancelTutionApplicationMutation, CancelTutionApplicationMutationVariables>(CancelTutionApplicationDocument);
};
export const UpdateApplicationDocument = gql`
    mutation UpdateApplication($input: UpdateTuitionApplicationInput!) {
  updateTuitionApplication(input: $input) {
    application {
      ...TuitionApplication
    }
    errors {
      ...TuitionError
    }
  }
}
    ${TuitionApplicationFragmentDoc}
${TuitionErrorFragmentDoc}`;

export function useUpdateApplicationMutation() {
  return Urql.useMutation<UpdateApplicationMutation, UpdateApplicationMutationVariables>(UpdateApplicationDocument);
};
export const AddressDocument = gql`
    query Address {
  tuition {
    myApplication {
      id
      ...Addresses
    }
  }
}
    ${AddressesFragmentDoc}`;

export function useAddressQuery(options: Omit<Urql.UseQueryArgs<AddressQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<AddressQuery>({ query: AddressDocument, ...options });
};
export const ParentDocument = gql`
    query Parent {
  tuition {
    myApplication {
      ...Parent
    }
  }
  salutationSourceEnum: __type(name: "SalutationSource") {
    ...EnumChoicesTypeInfo
  }
}
    ${ParentFragmentDoc}
${EnumChoicesTypeInfoFragmentDoc}`;

export function useParentQuery(options: Omit<Urql.UseQueryArgs<ParentQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<ParentQuery>({ query: ParentDocument, ...options });
};
export const SkipTuitionPaymentDocument = gql`
    mutation SkipTuitionPayment {
  skipTuitionPayment {
    application {
      ...TuitionApplication
    }
    errors {
      field
      messages
    }
  }
}
    ${TuitionApplicationFragmentDoc}`;

export function useSkipTuitionPaymentMutation() {
  return Urql.useMutation<SkipTuitionPaymentMutation, SkipTuitionPaymentMutationVariables>(SkipTuitionPaymentDocument);
};
export const InitiateTuitionDirectPostDocument = gql`
    mutation InitiateTuitionDirectPost($input: InitiateTuitionDirectPostInput!) {
  initiateTuitionDirectPost(input: $input) {
    nabCrn {
      ...NabCrn
    }
    errors {
      field
      messages
    }
  }
}
    ${NabCrnFragmentDoc}`;

export function useInitiateTuitionDirectPostMutation() {
  return Urql.useMutation<InitiateTuitionDirectPostMutation, InitiateTuitionDirectPostMutationVariables>(InitiateTuitionDirectPostDocument);
};
export const SchoolStatesAndYearLevelsDocument = gql`
    query SchoolStatesAndYearLevels {
  tuition {
    states {
      abbrev
      name
    }
    yearLevels
  }
}
    `;

export function useSchoolStatesAndYearLevelsQuery(options: Omit<Urql.UseQueryArgs<SchoolStatesAndYearLevelsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SchoolStatesAndYearLevelsQuery>({ query: SchoolStatesAndYearLevelsDocument, ...options });
};
export const SchoolsDocument = gql`
    query Schools($filter: TuitionSchoolsFilter!) {
  tuition {
    schools(filter: $filter) {
      ...SchoolOption
    }
  }
}
    ${SchoolOptionFragmentDoc}`;

export function useSchoolsQuery(options: Omit<Urql.UseQueryArgs<SchoolsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SchoolsQuery>({ query: SchoolsDocument, ...options });
};
export const SchoolDocument = gql`
    query School($id: Int!) {
  tuition {
    school(id: $id) {
      name
      state
      id
      __typename
      instruments {
        id
        name
        termFee
      }
    }
  }
}
    `;

export function useSchoolQuery(options: Omit<Urql.UseQueryArgs<SchoolQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<SchoolQuery>({ query: SchoolDocument, ...options });
};
export const TuitionTermsDocument = gql`
    query TuitionTerms {
  tuition {
    tuitionTerms {
      content
      title
      url
    }
    myApplication {
      ...TuitionAgreement
    }
  }
}
    ${TuitionAgreementFragmentDoc}`;

export function useTuitionTermsQuery(options: Omit<Urql.UseQueryArgs<TuitionTermsQueryVariables>, 'query'> = {}) {
  return Urql.useQuery<TuitionTermsQuery>({ query: TuitionTermsDocument, ...options });
};
export const AcceptTuitionAgreementDocument = gql`
    mutation AcceptTuitionAgreement($input: AcceptTuitionAgreementInput!) {
  acceptTuitionAgreement(input: $input) {
    application {
      ...TuitionAgreement
    }
  }
}
    ${TuitionAgreementFragmentDoc}`;

export function useAcceptTuitionAgreementMutation() {
  return Urql.useMutation<AcceptTuitionAgreementMutation, AcceptTuitionAgreementMutationVariables>(AcceptTuitionAgreementDocument);
};
export const SubmitTuitionAppDocument = gql`
    mutation SubmitTuitionApp {
  submitTuitionApplication {
    application {
      id
      ...TuitionApplication
    }
  }
}
    ${TuitionApplicationFragmentDoc}`;

export function useSubmitTuitionAppMutation() {
  return Urql.useMutation<SubmitTuitionAppMutation, SubmitTuitionAppMutationVariables>(SubmitTuitionAppDocument);
};