import { Form, FormikContext, FormikContextType, FormikHelpers, useFormik } from 'formik';
import React from 'react';
import { SelectFieldGroup } from 'shared/formik/SelectFieldGroup';
import { TextFieldGroup } from 'shared/formik/TextFieldGroup';
import { yupRequiredMsg } from 'shared/yup';
import * as Yup from 'yup';
import { StateOptions } from './utils';


interface Values {
  street: string
  suburb: string
  state: string
  postcode: string
}

export const defaultValues = {street: '', suburb: '', state: '', postcode: ''};


const validationSchema = Yup.object({
  street: Yup.string()
             .min(5, 'Must be a full street address.  Eg. 123 Example Road')
             .max(255)
             .required(yupRequiredMsg),
  suburb: Yup.string()
             .max(100)
             .required(yupRequiredMsg),
  state: Yup.string()
            .oneOf(StateOptions.map(o => o[0]))
            .required(yupRequiredMsg),
  postcode: Yup.string()
               .matches(/\d{4}/, 'Must be a 4 digit postcode')
               .required(yupRequiredMsg),
});


export const useFourPartAddress = () => {
  const formik = useFormik<Values>({
    initialValues: defaultValues,
    validationSchema,
    onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => {

    },
  });

  return {
    // Pass this through to <FormikFourParkAddress/>
    formik,
    // Returns valid data if valid, or null if invalid
    trySubmit: async () => {
      if (Object.keys(await formik.validateForm()).length === 0) {
        await formik.submitForm()
        return formik.values;
      }
      await formik.submitForm()
      return null;
    },
    // Use this to set the values when switching to this component
    setValues: (vals: Values | null) => formik.setValues(vals || defaultValues),
  }
}


interface BoundAddressProps  {
  formik: FormikContextType<Values>,
  children: React.ReactNode
}


export const FormikFourParkAddress = (props: BoundAddressProps) =>
  <FormikContext.Provider value={props.formik}>
    <FourPartAddressForm>{props.children}</FourPartAddressForm>
  </FormikContext.Provider>;


const FourPartAddressForm = (props: React.PropsWithChildren<{}>) => (
  <Form noValidate>
    <div className="form-row">
      <div className="col-md-12">
        <TextFieldGroup
          label="Street No &amp; name"
          name="street"
          maxLength={255}
        />
      </div>
    </div>
    <div className="form-row">
      <div className="col-md-3">
        <TextFieldGroup
          label="Suburb / Town"
          name='suburb'
          maxLength={100}
        />
      </div>
      <div className="col-md-2">
        <SelectFieldGroup label="State" name={'state'} blank={true} custom>
          {StateOptions.map(s=> <option key={s[0]} value={s[0]}>{s[1]}</option>)}
        </SelectFieldGroup>
      </div>
      <div className="col-md-2">
        <TextFieldGroup
          label="Postcode"
          name='postcode'
          maxLength={4}
          pattern="\d*"
          inputMode="numeric"
        />
      </div>
      {props.children}
    </div>
  </Form>
);
