import React from 'react';
import { LoadingLabel } from 'shared/components/Loading';
import { useAddressQuery } from 'tuition/generated.graphql';
import { TuitionStepProps } from 'tuition/steps';
import { AddressForm } from './AddressForm';


interface Props  extends TuitionStepProps {

}

export const Address = (passThruProps: Props) => {
  const [result] = useAddressQuery();
  if (result.data?.tuition.myApplication) {
    return <AddressForm {...passThruProps} application={result.data?.tuition.myApplication}/>;
  }
  return <LoadingLabel/>
};
