import { Footer, FooterInfo } from 'shared/chrome/Footer';
import React from 'react';
import styles from './AppLayout.module.scss';


export interface Props {
  children: React.ReactNode
  footer: FooterInfo
  devFooter?: React.ReactNode
}


export const AppLayout = (props: Props) => (
  <div className={styles.Wrapper}>
    <div className={styles.App}>
      {props.children}
    </div>
    <Footer {...props.footer}>{props.devFooter}</Footer>
  </div>
);

