/* 
Shared functionality that would normally be in src/index.tsx.
We've used patch package to allow react-scripts (create-react-app) to use a different entrypoint using env var.  eg.

ENTRY_POINT=src/rental/index react-scripts start
or
ENTRY_POINT=src/tuition/index react-scripts start

*/

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from '../serviceWorker';
import { getRootElement } from 'shared/utils/rootEl';

// I am still not sure the best way to do BootStrap JS
import 'jquery';
import 'popper.js';
import 'bootstrap';
import { ErrorBoundary } from 'shared/components/ErrorBoundary';


export const renderYourApp = (YourAppComponent: React.ComponentType) => {

    ReactDOM.render(
      <React.StrictMode>
        <ErrorBoundary title="Index">
          <YourAppComponent />
        </ErrorBoundary>
      </React.StrictMode>,
      getRootElement()
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
    
};
