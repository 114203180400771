
import Modal from 'react-modal';
import { rootElementSelector } from 'shared/utils/rootEl';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Overriding the default styles since these are now defined in /src/styles/modal.scss
Modal.defaultStyles = {
  content: {},
  overlay: {},
}
Modal.setAppElement(rootElementSelector);

export { Modal };

export const defaultModalProps = {
  closeTimeoutMS: 300,
  shouldCloseOnEsc: true,
  shouldCloseOnOverlayClick: true,
};

interface Props extends ReactModal.Props {
  title?: string | React.ReactNode;
  children?: React.ReactNode
  bodyContainerClass?: string
}

export const ReactBootstrapModal = (props: Props) => (
  <Modal {...{...defaultModalProps, ...props}}>
    <div className="modal-content h-100" style={{}}>
      <div className="border-bottom">
        <div className="container d-flex align-items-center">
          <h4 className="modal-title mr-auto">{props.title}</h4>
          {props.onRequestClose && 
            <button onClick={props.onRequestClose} type="button" className="close p-3" data-dismiss="modal" aria-label="Close">
              {/* <span aria-hidden="true">&times;</span> */}
              <FontAwesomeIcon icon={faTimes}/>
            </button>
          }
        </div>
      </div>
      <div className="modal-body py-0" style={{overflowY: 'auto', flex: '1 1 auto', padding: '1rem 0'}}>
        <div className={props.bodyContainerClass || "container"}>
          {/* <div className="container"> */}
            {props.children}
          {/* </div> */}
        </div>
      </div>
    </div>
  </Modal>
)
