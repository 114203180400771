import cs from 'classnames';
import React from 'react';
import { SummaryHeader } from 'shared/summary/SummaryHeader';
import { SummarySection } from 'shared/summary/SummarySection';
import { slugs, useTuitionSteps } from 'tuition/steps';
import { TuitionApplicationFragment, TuitionErrorField } from '../../generated.graphql';
import { parentErrorFilter } from '../parent/ParentForm';
import { TuitionSummaryField } from './TuitionSummaryField';


interface Props {
  application: TuitionApplicationFragment
}


export const ParentSummary = ({ application }: Props) => {
  const steps = useTuitionSteps({slug: slugs.parent});
  const errors = application?.validationErrors.filter(parentErrorFilter) || [];
  const isInvalid = errors.length > 0;
  return (
    <SummarySection>
      <SummaryHeader step={steps.this} className={cs(isInvalid && 'text-danger')}>Parent Details</SummaryHeader>
      <TuitionSummaryField data={application} fields={[TuitionErrorField.ParentTitle, TuitionErrorField.ParentFirstName,  TuitionErrorField.ParentLastName]} label="Name" errors={errors} uppercase />
      <TuitionSummaryField data={application} field={TuitionErrorField.ParentEmail} errors={errors} label="Email" />
      <TuitionSummaryField data={application} field={TuitionErrorField.ParentPhoneMobile} errors={errors} label="Mobile" />
    </SummarySection>
  );
}
