import React from 'react';

interface Props {
  title?: React.ReactNode
  errors: {
    label?: string
    message: React.ReactNode
  }[]
}


export const FormErrors = (props: Props) => (
  <div className="alert alert-danger">
    <h6 className="alert-heading">{props.title || 'We encountered an issue saving your data.'}</h6>
    {props.errors.map((error, index) => error && (
      <div key={index}>{error.label && <b className="mr-2">{error.label}:</b> }{error.message}</div>
    ))}
  </div>
);
