import React from 'react';
import { reportError } from 'shared/errorReporting';
import { ErrorBoundaryMessage } from 'shared/components/ErrorBoundaryMessage';


interface Props {
  title: string;
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  errorReportId?: number;
}


export class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false
  };

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {    
    // Update state so the next render will show the fallback UI.    
    return { hasError: true };  
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {   
    // Log the error
    const data = {
      errorMessage: error.message,
      errorName: error.name,
      errorStack: error.stack || null,
      componentStack: errorInfo.componentStack
    }
    reportError('ErrorBoundary: ' + this.props.title, data)
    .then(errorReportId => {
      this.setState({...this.state, hasError: true, errorReportId})
    })
  }

  render() {
    if (this.state.hasError) {      
      // You can render any custom fallback UI      
      return (
        <ErrorBoundaryMessage 
          reporting={!this.state.errorReportId} 
          errorReportId={this.state.errorReportId || null} 
        />
      );   
    }
    return this.props.children; 
  }
}

