import React, { useEffect } from 'react';
import cs from 'classnames';
import { useElementId } from 'shared/utils/elementIds';
import styles from './HelpPanel.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { PhoneNumberLink } from 'shared/components/PhoneNumber';

interface Props {
  // portal?: App_PortalFragment;
  children: React.ReactNode
  onDismiss?: () => void;
}

export const HelpPanel = (props: Props) => {
  const helpPanelId = useElementId('HelpPanel');

  useEffect(() => {
    const dismiss = (e: Event) => {
      const helpPanelElement = document.getElementById(helpPanelId);
      if (!(e.target instanceof Node && helpPanelElement?.contains(e.target))) {
        props.onDismiss?.();
      }
    };

    document.addEventListener('click', dismiss);
    return () => {
      document.removeEventListener('click', dismiss);
    }
  });

  return (
    <div id={helpPanelId} className={cs(styles.HelpPanel, 'px-4 pt-4 pb-3')}>
      <button type="button" className={cs(styles.CloseButton, "btn btn-link d-none d-md-block")} onClick={() => props.onDismiss?.()}>
        <FontAwesomeIcon icon={faTimes} size={"lg"} />
      </button>
      <h4 className="d-block d-md-none">Do you need assistance?</h4>
      <p className="text-muted">If you need assistance, then call our FREE HELPLINE on <PhoneNumberLink className="text-body text-decoration-none font-weight-bold" />. Mon-Fri 9.00-5.30 Eastern Time.</p>
      {props.children && <p className="text-muted">{props.children}</p>}
    </div>
  )
}