import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './ScrollingTermsAndCond.module.scss';
import { TermsPlaceholder } from './TermsPlaceholder';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';

interface Props {
  html?: string | null
  newWindowLink?: string
}

export const ScrollingTermsAndCond = (props: Props) => (
  <>
    <div className={styles.TermsAndConditions}>
      {props.html 
        ? <div dangerouslySetInnerHTML={{ __html: props.html }}/>
        : <TermsPlaceholder/>
      }
    </div>
    
    {props.newWindowLink && 
    <div className="text-right">
        <a href={props.newWindowLink} target="_blank" rel="noreferrer" className="btn btn-sm btn-link text-decoration-none">
          Open in new window <FontAwesomeIcon icon={faExternalLink} />
        </a>
      </div>
    }
  </>
);
