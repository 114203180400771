import React from 'react';
import cardValidator from 'card-validator';
import { CardIcon } from 'shared/payment/AcceptedCards';
import { CreditCardFragment } from 'shared/generated.graphql';

interface Props {
  card: CreditCardFragment;
}


const cardTypeFromMaskedNumber = (masked: string) => {
  const bin = masked.replace(/[^\d].*$/g, '');
  const v = cardValidator.number(bin);
  return v.card;
}


export const CreditCard = ({card}: Props) => {
  const c = cardTypeFromMaskedNumber(card.maskedCardNumber);
  return (
    <div className="border bg-white shadow d-flex p-2 align-items-center">
      <div className="px-2"><CardIcon type={c?.type} active/></div>
      <div className="px-2">Card number<br/><b>{card.maskedCardNumber}</b></div>
      <div className="px-2">Expires <br/><b>{card.expiryMM}/{card.expiryYYYY}</b></div>
    </div>
  )
};

