import cs from 'classnames';
import { useField } from 'formik';
import React from 'react';
import { useElementId } from 'shared/utils/elementIds';


interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: Array<[string,string]>;
  custom?: boolean;
}

interface FieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
  option: string;
  custom?: boolean;
}

export const RadioField = ({label, option, custom, className, ...props}: FieldProps) => {
  const [field, meta] = useField(props);
  const newId = useElementId('RadioField');

  if (!custom) {
    return (
      <div className={cs("form-group", className)}>
        <label className="mb-0" htmlFor={newId}>
          <input
            type="radio"
            className={cs(meta.touched && meta.error && 'is-invalid')}
            checked={option === field.value}
            {...field}
            {...props}
            value={option}
            id={newId}
          /> {label}
        </label>
      </div>
    );
  }

  return (
    <div className={cs("form-group", className)}>
      <div className={cs(`custom-control custom-radio`)}>
        <input 
          type="radio"
          className={cs('custom-control-input', meta.touched && meta.error && 'is-invalid')}
          checked={option === field.value}
          {...field}
          {...props}
          value={option}
          id={newId}
        />
        <label className="custom-control-label" htmlFor={newId}>{label}</label>
      </div>
    </div>
  );
}

export const RadioFieldGroup = ({className, options, ...props}: Props) => {
  return (
    <>
      {options.map(([value, label]) => (
        <RadioField label={label} option={value} {...props} />
      ))}
        
      {/*meta.touched && meta.error && 
        <div className="text-danger small mt-1">{meta.error}</div>
      */}
    </>
  );
}
