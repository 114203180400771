import React from 'react';

export const TermsPlaceholder = () => (
  <div>
    <div className="placeholder-line-25 mb-4"></div>
    <div className="placeholder-line"></div>
    <div className="placeholder-line"></div>
    <div className="placeholder-line"></div>
    <div className="placeholder-line"></div>
    <div className="placeholder-line-25 mb-4"></div>
    <div className="placeholder-line"></div>
    <div className="placeholder-line"></div>
    <div className="placeholder-line-75"></div>
  </div>
);
