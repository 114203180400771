import React from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { AppLayout } from 'shared/app/AppLayout';
import { AppWrapper } from 'shared/app/AppWrapper';
import { LoadingLabel } from 'shared/components/Loading';
import { AppExpiredInfo } from 'shared/urql';
import { AppBanner } from './AppBanner';
import { AppContent } from './AppContent';
import { AppContext } from './AppContext';
import { useMyTuitionApplicationQuery } from './generated.graphql';
import { buildTuitionLink, slugs } from './steps';
import { Paid } from './steps/payment/Paid';
import { Finished } from './steps/submit/finished/Finished';
import { tuitionUrqlOptions } from './urql';


export const App = () => <AppWrapper urql={tuitionUrqlOptions}>{AppInner}</AppWrapper>;


interface Props {
  expiryInfo?: AppExpiredInfo
}

const AppInner = (props: Props) => {
  const [result] = useMyTuitionApplicationQuery(); 
  const application = result.data?.tuition.myApplication;


  // Redirect / to /student
  const m = useRouteMatch('/')
  const history = useHistory();
  if (m?.isExact) {
    history.replace(slugs.student)
  }

  const appContext = {
    application: application ?? null, 
    // myLastApplicationId: application?.myLastApplicationId ?? null,
  };

  return (
    <AppContext.Provider value={appContext}>
      <AppLayout
        footer={{
          tradingName: "Musicorp Australia",
          privacyUrl: 'https://secure.polygongroup.com.au/polygongroup/privacy/',
          termsOfUseUrl: 'https://www.polygongroup.com.au/privacy/websiteterms.html',
          // showDevFooter: application?.testMode.application || false,
        }}
      >
        <AppBanner/>

        <div className='container-xl container-xl mb-3'>
          <h5 className='m-0 font-weight-light'>Application for Tuition</h5>
        </div>

        <Switch>
          {/* Handle post-direct-post landing page.  It's here because we don't want to include it in our list of steps. */}
          <Route path={buildTuitionLink(slugs.paid)}>
            {application && <Paid application={application}/>}
          </Route> 
          {/* Confirmation page */}
          {(application?.myLastApplicationId || appContext.application?.submitted) && 
            <Route path={buildTuitionLink(slugs.finished)}>
              <Finished/>
            </Route>
          }
          <Route>
            {application ? <AppContent application={application || null}/> : <LoadingLabel/>}
          </Route>
        </Switch>

        
      </AppLayout>
    </AppContext.Provider>
  )
};
