export interface FieldNames {
  addressComponent: string;
  fullAddress: string;
  street: string;
  suburb: string;
  state: string;
  postcode: string;
}

export function getAddressFieldNames(prefix: string): FieldNames {
  const result: FieldNames = {
    addressComponent: '',
    fullAddress: '',
    street: '',
    suburb: '',
    state: '',
    postcode: '',
  };

  Object.keys(result)
    .map<keyof FieldNames>(name => name as keyof FieldNames)
    .forEach((name) => result[name] = `${prefix}${name}`);

  return result;
}

export const StateOptions: readonly string[][] = [
  ["ACT", 'Australian Capital Territory'],
  ["NSW", 'New South Wales'],
  ["NT", 'Northern Territory'],
  ["QLD", 'Queensland'],
  ["SA", 'South Australia'],
  ["TAS", 'Tasmania'],
  ["VIC", 'Victoria'],
  ["WA", 'Western Australia'],
];
