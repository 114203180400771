import React from 'react';
import { useHistory } from 'react-router-dom';
import { NextButton, PrevButton, SubmitButtons } from 'shared/components/SubmitButtons';
import { StepNotAccessible } from 'shared/steps/StepNotAccessible';
import { Step, UseSteps } from 'shared/steps/steps';
import { BaseStepProps, StepActionParams } from 'shared/steps/types';


interface Props<TSlug extends string> {
  onStepForward?: (curStep: Step<TSlug>, nextStep: Step<TSlug>) => void
  steps:  UseSteps<TSlug> & {this: Step<TSlug>}
  children: (stepProps: BaseStepProps<TSlug>) => JSX.Element
}

export const StepController = <TSlug extends string>({onStepForward, steps, children, ...passThruProps}: Props<TSlug>): JSX.Element => { 

  const history = useHistory();
  const [forwards, setForwards] = React.useState(true);

  if (steps.this.enabled) {

    const actions = (p: StepActionParams) => (
      <SubmitButtons
        left={steps.prev && 
          <PrevButton disabled={p.submitting} onClick={e => {
            e.preventDefault();
            if (p.dirty) {
              setForwards(false);
              if (p.submit) {
                p.submit()
              }
            }
            else if (steps.prev) {
              history.push(steps.prev?.link)
            }
          }}/>
        }
        centre={p.centre}
        right={
          p.right || 
          (steps.next && 
            <NextButton 
              onClick={e => {
                e.preventDefault(); 
                // If no data has changed, do not bother submitting
                // if (p.dirty === false && steps.next) {
                //   history.push(steps.next?.link)
                //   return;
                // }
                if (p.submit) 
                  p.submit();
              }} 
              disabled={p.disabled || p.submit === undefined || p.submitting}
            >
              {p.submitting ? "Saving" : "Next"}
            </NextButton>
          )
        }
      />
    );

    const stepProps = {
      ...passThruProps, 
      actions,
      steps,
      onSubmitSuccessful: () => {
        if (forwards) {
          if (steps.next) {
            if (onStepForward) {
              onStepForward(steps.this, steps.next);
            }
            history.push(steps.next?.link)
          }
        }
        else {
          if (steps.prev) {
            history.push(steps.prev?.link)
          }
          setForwards(true);
        }
      },
      onSubmitFailure: () => {
        if (!forwards) {
          if (window.confirm('We tried to save your info, but it was not valid.  Do you want to go to the previous step and loose your changes on this screen?')) {
            if (steps.prev) {
              history.push(steps.prev?.link)
            }
          }
          setForwards(true);
        }
      },
      onBack: () => {
        if (steps.prev) {
          history.push(steps.prev?.link)
        }
      }
    }

    return children(stepProps);
  }

  // const enabledSteps = steps.items.filter(s => s.enabled);
  // const upToStep = enabledSteps[Math.max(0, enabledSteps.length - 2)]
  // upToStep.
  return <StepNotAccessible to={steps.upTo?.link}/>
}

