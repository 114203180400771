import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange, KeyingConfig, OptimisticMutationConfig } from '@urql/exchange-graphcache';
import { IntrospectionQuery } from 'graphql';
import { ClientOptions, dedupExchange, errorExchange, fetchExchange } from 'urql';
import { protectionPlanOptimisticResolver } from '../rental/steps/cart/resolvers';
import schema from './generated.schema.json';
import { getCsrfToken } from 'shared/utils/csrf';


export interface ApiRequestOptions {
  ExtendSession: boolean;   // Default is true
}

export function getInit(init: RequestInit = {}, headers: {[k: string]: string} = {}, options?: ApiRequestOptions): RequestInit {
  // Parse headers

  const csrfToken = getCsrfToken();
  if (csrfToken) {
    headers = {
      "x-csrftoken": csrfToken,
      ...headers
    };
  }

  if (options && !options.ExtendSession) {
    headers = { "X-Extend-Session": "0", ...headers };
  }

  headers = {
    Accept: "application/json",
    'X-GraphQL-Client': "PolygonAppWeb/" + process.env.REACT_APP_VERSION,
    ...headers
  };

  // using a headers object doesn't appear to work, but a primitive dict works just fine
  // init.headers = new Headers();
  // for (var key in headers) {
  //   let value = headers[key]
  //   console.log(key, value);
  //   if (value !== undefined) {
  //     init.headers.set(key, value);
  //   }
  // }
  headers = Object.keys(headers)
    .filter(k => headers[k])
    .reduce<{ [key: string]: string }>((o, k) => {
      o[k] = headers[k] ?? '';
      return o;
    }, {});

  return {
    credentials: 'include',
    mode: 'cors',
    ...init,
    headers,
  };
}


export interface AppExpiredInfo {
  expired: boolean;
  newId: number | null;
}


export interface UrqlOptionOverrides {
  cacheKeys?: KeyingConfig
  optimistic?: OptimisticMutationConfig
}

interface Opts extends UrqlOptionOverrides {
  onAppExpired: (info: AppExpiredInfo) => void
}


export const getClientOptions = (opts: Opts): ClientOptions => ({
  url: process.env.REACT_APP_GRAPH_URL as string,
  // url: `/graphql`,
  fetchOptions: () => getInit(),
  requestPolicy: 'cache-first',

  // Customise the flow of operations specific to our project
  exchanges: [
    devtoolsExchange,
    // A default exchange which prevent duplicate operations
    dedupExchange, 

    // Upgrades cache-first and cache-only operations to cache-and-network after 30 seconds
    // This means that we can set cache-first for most operations so that the API
    // is not hammered if users click around frequently, but still reload if longer than 30 seconds
    // requestPolicyExchange({ttl: 1000}),  

    // A default exchange that provides simple query based caching 
    // (will probably update this to graphcache at some point)
    // cacheExchange,
    cacheExchange({
      schema: schema as any as IntrospectionQuery,
      keys: {
        ErrorType: () => null,
        PolicyContent: () => null,
        // Rental/tuition specific keys
        ...opts.cacheKeys,
      },
      optimistic: opts.optimistic,
    }),
    
    // Catching errors
    errorExchange({
      onError(error) {
        // Catch not-authenticated errors and set our authenticated state to false
        // so that the Aoo can popup the login prompt
        for (let e of error.graphQLErrors) {
          if (e.extensions?.applicationExpiry?.expired) {
            opts.onAppExpired(e.extensions.applicationExpiry);
          }
        }
      },
    }),

    // A default exchange that does the actual http request
    fetchExchange,
  ],
});