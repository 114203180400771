import fetchJsonp from 'fetch-jsonp';

export const fetchSuggestions = (query: string) => {
  // Allow mocking of this api, incase it's down, or we are developing using an unwhitelisted dev domain
  const baseUrl = process.env.REACT_APP_MOCK_ADDRESS_AUTOCOMPLETE_URL 
    || 'https://services.inivio.com.au/autocomplete/address/address';
  const url = `${baseUrl}?address=${encodeURIComponent(query)}`;
  return fetchJsonp(url).then((r) => r.json())
    .then((j) => {
      return j['Result'] as (Array<string> | null) || [];
    })
    // If api is offline, return an empty list so everything still works nicely.
    .catch(() => []); 
}
