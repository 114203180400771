import React from 'react';
import { fetchSuggestions } from '../utils/equifax';
import { AddressTypeahead, ExternalProps } from './AddressTypeahead';

interface Props extends ExternalProps {
  border?: boolean;
}

export const AddressTypeaheadEquifax = (props: Props) => (
  <div style={props.border ? { padding: "20px", border: "1px solid #cdf" } : {}}>
    <AddressTypeahead fetchSuggestions={fetchSuggestions} {...props}/>
  </div>
);
