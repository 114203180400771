import { faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React from 'react';


type CardIconProps = {active?: boolean} & Omit<FontAwesomeIconProps, 'icon'>


export const VisaIcon = ({size, style, active, ...passThru}: CardIconProps) => 
  <FontAwesomeIcon 
    size={size || "3x"} 
    icon={faCcVisa} 
    title="Visa"
    style={{color: active ? '#005797' : '#a0abaf', ...style}}
    {...passThru}
  />;

export const MastercardIcon = ({size, style, active, ...passThru}: CardIconProps) => 
  <FontAwesomeIcon 
    size={size || "3x"} 
    icon={faCcMastercard} 
    style={{color: active ? '#f79a00' : '#a0abaf', ...style}}
    title="Mastercard"
    {...passThru}
  />;

export const CreditCardIcon = ({size, style, active, ...passThru}: CardIconProps) => 
  <FontAwesomeIcon 
    size={size || "3x"} 
    icon={faCreditCard} 
    style={{color: active ? '#2ab57b' : '#a0abaf', ...style}}
    {...passThru}
  />;


export const CardIcon = ({type, ...props}: CardIconProps & {type?: string}) => {
  const Component = type === "visa" ? VisaIcon : (type === "mastercard" ? MastercardIcon : CreditCardIcon);
  return <Component {...props}/>
}


export const AcceptedCards = ({active}: {active?: string}) => (
  <div className="d-flex align-items-center">
    {/* <span className="mr-2">We accept</span> */}
    <VisaIcon active={active === "visa"} className="mr-2"/>
    <MastercardIcon active={active === "mastercard"} className="mr-2"/>
    {/* <span className="mr-2">and</span> */}
  </div>
);

