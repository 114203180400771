
import { ErrorType, TuitionErrorField, TuitionErrorType, TuitionApplicationFieldError, TuitionTypeError, TuitionStudentFieldError } from "../generated.graphql";


export const labels = {
  // Student
  [TuitionErrorField.FirstName]: "Student First Name",
  [TuitionErrorField.LastName]: "Student Last Name",
  schoolState: "Select School's State",
  [TuitionErrorField.School]: "Select your School",
  [TuitionErrorField.YearLevel]: "Current year level",
  [TuitionErrorField.Instrument1]: "First choice instrument",
  [TuitionErrorField.Instrument2]: "Second choice instrument",

  // Parent 
  [TuitionErrorField.ParentTitle]: "Parent Title",
  [TuitionErrorField.ParentFirstName]: "Parent first name",
  [TuitionErrorField.ParentLastName]: "Parent last name",
  [TuitionErrorField.ParentEmail]: "Parent email",
  [TuitionErrorField.ParentPhoneMobile]: "Parent mobile number",

  // Address
  [TuitionErrorField.ResidentialAddress]: "Residential address",

  // Additional Info
  [TuitionErrorField.Notes]: "Optional additional comments",

  // Payment

  // Agreement
  [TuitionErrorField.AcceptedAgreement]: 'I have read and agree to the Terms and Conditions.',
}


export const createErrorFilter = 
  (fields: TuitionErrorField[], types: TuitionErrorType[])  => 
    (error: TuitionApplicationFieldError | TuitionStudentFieldError | TuitionTypeError) => 
      (error.__typename === 'TuitionApplicationFieldError' && fields.includes(error.field))
      || (error.__typename === 'TuitionStudentFieldError' && fields.includes(error.field))
      || (error.__typename === 'TuitionTypeError' && types.includes(error.type));


export const parseErrors = (tuitionErrors: ReadonlyArray<TuitionApplicationFieldError | TuitionStudentFieldError | TuitionTypeError>) : ReadonlyArray<ErrorType> => 
  tuitionErrors.map(e => ({messages: [e.message], field:e.__typename === 'TuitionApplicationFieldError' || e.__typename === 'TuitionStudentFieldError' ? e.field : '' }));
    
    