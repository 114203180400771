import CardValidator from 'card-validator';
import { CardNumberVerification } from 'card-validator/dist/card-number';
import cs from 'classnames';
import { FormikErrors, useFormik } from 'formik';
import React from 'react';
import { FormErrors } from 'shared/components/FormErrors';
import { NextButton, PrevLink, SubmitButtons } from 'shared/components/SubmitButtons';
import { AcceptedCards } from 'shared/payment/AcceptedCards';
import { useDirectPostForm } from 'shared/payment/useDirectPostForm';
import { formatCard } from 'shared/payment/utils';
import { range, rangeForCount } from 'shared/utils/numbers';
import { SkipPaymentButton } from './SkipPaymentButton';
import { NabCrnFragment } from 'shared/generated.graphql';


interface Props {
  prevStepLink: string | null
  skip: {
    execute: () => Promise<void>
    executing: boolean
  }
  getFingerprint: {
    execute: (data: {nameOnCard: string, submitted: boolean}) => Promise<NabCrnFragment | null>
    executing: boolean
  }
  children?: React.ReactNode
}

interface Values {
  number: string
  month: string
  year: string
  name: string
}


export const CreditCardForm = (props: Props) => {

  // Hooks (lots of them for this one)
  const [verification, setVerification] = React.useState<CardNumberVerification | null>(null);
  const [isDirectlyPosting, setIsDirectlyPosting] = React.useState(false);
  const [fingerprintError, setFingerprintError] = React.useState(false)
  const directPostForm = useDirectPostForm({ visible: false });
  // const [initCustomerRecordResult, initCustomerRecord] = useInitiateNabCustomerRecordMutation();

  // NB. We are using a custom handleChange for the card number so that we can format the number with spaces.
  const formik = useFormik<Values>({
    initialValues: {
      number: "",
      month: "",
      year: "",
      name: "",
    },
    validate: values => {
      const errors: FormikErrors<Values> = {};

      // Validate Card Name
      if (values.name.trim().length === 0) {
        errors.name = "Required";
      }
      else if (values.name.length < 3) {
        errors.name = "Must be a valid name longer than 3 characters";
      }

      // Validate Card Number
      const cleanedNumber = values.number.replace(/[^\d]/g, "");
      const v = CardValidator.number(cleanedNumber);
      if (v.card?.type === 'american-express') {
        errors.number = "We do not accept American Express cards";
      }
      else if (!v.isValid) {
        errors.number = "Must be a valid visa or mastercard number";
      }

      // Validate expiry
      if (values.month && values.year) {
        const r = CardValidator.expirationDate(values);
        if (!r.isValid) {
          errors.month = "This date has already expired";
          errors.year = "This date has already expired";
        }
      }
      else {
        if (!values.month) 
          errors.month = "Required";
        if (!values.year) 
          errors.year = "Required";
      }

      return errors;
    },

    // Called once all card data is validated.
    // Asks our server for a NAB fingerprint, updates the hidden form, and submits it.
    onSubmit: async (values) => {

      setFingerprintError(false);
      
      // Record will be null if unsuccessful
      const record = await props.getFingerprint.execute({ submitted: true, nameOnCard: values.name });
        
      // If we got a fingerprint
      if (record && record.fingerprint && record.fingerprint.length > 50) {
        directPostForm.update(record.nabUrl, {
          ...record,
          cardNumber: values.number,
          expiryMonth: values.month,
          expiryYear: values.year,
        })
        setIsDirectlyPosting(true);
        directPostForm.submit();
      }
      // Fallback to error
      else {
        setFingerprintError(true);
        setIsDirectlyPosting(false);
      }
        
    },
  })

  // Custom handler for card number so that we can format the number
  // And store the validation result in state for things like card types
  const handleNumberChange = (ev: React.ChangeEvent<HTMLInputElement>) => {

    // Remove spaces so we can test the number
    const number = ev.target.value.replace(/[^\d]/g, "");

    // Check whether the number is valid or partially valid
    const v = CardValidator.number(ev.target.value.replace(/[^\d]/g, ""));

    // If there are enough digits to determined the card type, format the card with gaps
    const formatted = v.card ? formatCard(ev.target.value, v.card.gaps) : number;

    // Set the formatted value into the DOM element manually so that formik treats the 
    // formatted number as if that's how the user typed it
    ev.target.value = formatted;
    formik.handleChange(ev);

    // Set the card type state, so that we can display what type of card this is
    setVerification(v);
  }

  const monthChoices = range(1, 12);
  const yearChoices = rangeForCount(new Date().getFullYear(), 12).map(yyyy => [
    yyyy.toString().substring(2),
    yyyy
  ]);

  // Show a "processing..." loader if something is happening.
  const isProcessing = props.getFingerprint.executing || isDirectlyPosting;

  // Scroll to top after any errors
  const formRef = React.useRef<HTMLFormElement>(null);
  React.useEffect(() => {
    // window.scrollTo(0, 0);
    if (fingerprintError && formRef.current) {
      window.scrollTo(0, 0);
    }
  }, [fingerprintError]);

  return (
    <>
      {/* Include the direct post form from the hook  */}
      {directPostForm.element}

      {/* Standard form for the card details */}
      <form
        // className={state.wasValidated ? "was-validated" : "needs-validation"}
        noValidate
        onSubmit={formik.handleSubmit}
        ref={formRef}
      >
        {fingerprintError && <FormErrors title="There was an issue connecting with our bank." errors={[{message: (
          <>
            There was an issue saving your payment details with our bank.<br/>
            Please try again below or skip to the next step and we will contact you for your card details later.<br/><br/>
            <SkipPaymentButton {...props.skip} />
          </>
        )}]}/>}


        <div className="row mb-3">
          <div className="col-lg-6">
            <label>Name on card</label>
            <input
              type="text"
              name="name"
              className={cs(
                "form-control", 
                formik.touched.name && formik.errors.name && 'is-invalid'
              )}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              maxLength={200}
              required
            />
            {formik.touched.name && formik.errors.name && <div className="invalid-feedback">{formik.errors.name}</div>}
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-lg-6">

            <div>
              <>
                <div className="">
                  <div className="">
                    <label>Card number</label>
                    <input
                      type="text"
                      name="number"
                      className={cs(
                        "form-control", 
                        formik.touched.number && formik.errors.number && 'is-invalid',
                        formik.values.number && !formik.errors.number && 'is-valid'
                      )}
                      value={formik.values.number}
                      onChange={handleNumberChange}  // Custom handler so that we can format the number
                      onBlur={formik.handleBlur}
                      maxLength={22} // 19 digits + 3 spaces + 1 extra for good measure
                      required
                      pattern="[0-9]*" 
                      inputMode="numeric"
                    />
                    {formik.touched.number && formik.errors.number && <div className="invalid-feedback">{formik.errors.number}</div>}
                  </div>
                </div>

                <div className="d-md-flex">
                  <div className="my-3 mr-md-5">
                    <label>Expiry</label>
                    <div className="d-flex">
                      <div className="">
                        <select
                          name="month"
                          className={cs("form-control", formik.touched.month && formik.errors.month && 'is-invalid')}
                          placeholder="MM"
                          style={{ minWidth: "4.5em" }}
                          value={formik.values.month}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                        >
                          <option value="">MM</option>
                          {monthChoices.map(m => (
                            <option key={m} value={m.toString().padStart(2, "0")}>
                              {m.toString().padStart(2, "0")}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="mx-3 my-2">/</div>
                      <div className="">
                        <select
                          name="year"
                          className={cs("form-control", formik.touched.year && formik.errors.year && 'is-invalid')}
                          placeholder="YY"
                          style={{ minWidth: "5.5em" }}
                          value={formik.values.year}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                        >
                          <option value="">YYYY</option>
                          {yearChoices.map(i => (
                            <option key={i[0]} value={i[0]}>
                              {i[1]}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {formik.touched.month && formik.errors.month && <div className="text-danger small mt-1">{formik.errors.month}</div>}
                  </div>
                </div>
              </>
            </div>

          </div>
          <div className="col-lg-6">
            <label>&nbsp;</label>
            <AcceptedCards active={verification?.card?.type}/>
          </div>
        </div>
        
        {props.children}
        
        <SubmitButtons
          left={props.prevStepLink && <PrevLink to={props.prevStepLink}/>}
          right={<NextButton saving={isProcessing}/>}
        />
      </form>
    </>
  );
}
