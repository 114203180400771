import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Step } from 'shared/steps/steps';
import styles from './SummaryHeader.module.scss';


interface SummaryHeaderProps<TSlug extends string> {
  step?: Step<TSlug> | null;
}

type HTMLHeadingProps<TSlug extends string> = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & SummaryHeaderProps<TSlug>;

export const SummaryHeader = <TSlug extends string>(props: HTMLHeadingProps<TSlug>) => {
  const nodeProps = {...props};
  if (nodeProps.step) {
    delete nodeProps.step;
  }

  const linkClassName = /(text-(?:danger|warning|primary))/.exec(props.className ?? '')?.[1];

  /* eslint-disable jsx-a11y/heading-has-content */
  return (
    <div {...nodeProps} className={cs("d-flex align-items-center mb-2", props.className)}>
      <h3 {...nodeProps} className={cs(styles.header, "mb-0 h4")} />
      {props.step ? (
        <Link to={props.step.link} className={cs("ml-3", linkClassName)}>
          <FontAwesomeIcon icon={faPencil} />
        </Link>
      ) : null}
    </div>
  );
  /* eslint-enable */
}
