import { FormikProps } from 'formik';
import { BaseStepProps } from 'shared/steps/types';
import { MutationErrorArray } from 'shared/utils/mutations';


export const getFormikFieldServerErrors = (formikStatus: any, fieldName: string) => {
  if (!formikStatus) return null;
  const messages: string[] = []
  for (let e of formikStatus as MutationErrorArray) {
    if (e?.field === fieldName) {
      for (let msg of e.messages) {
        messages.push(msg)
      }
    }
  }
  if (messages.length > 0)
    return messages
  return null;
}

export const formikSubmitStep = <T, TSlug extends string>(formik: FormikProps<T>, props: BaseStepProps<TSlug>) => async () => {
  await formik.submitForm(); 
  if (!formik.isValid) {
    props.onSubmitFailure()
  }
}

export const getSubmitActionsProps = <T, TSlug extends string>(formik: FormikProps<T>, props: BaseStepProps<TSlug>) => ({
  actions: props.actions,
  submit: formikSubmitStep(formik, props),
  submitting: formik.isSubmitting,
  dirty: formik.dirty,
})