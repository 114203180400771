

export const reportError = async (title: string, data: any) => {
  data.title = title;
  console.log("ERROR REPORTING", process.env.REACT_APP_REPORT_ERROR_URL, title);
  if (process.env.REACT_APP_REPORT_ERROR_URL) {
    const r = await fetch(process.env.REACT_APP_REPORT_ERROR_URL, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "content-type": 'application/json',
      },
      credentials: 'include',
      mode: 'cors',
    });
    const j = await r.json();
    return j.errorReportId || null;
  }
};
