import { Menu, setupSteps, UseSteps } from 'shared/steps/steps';
import { BaseStepProps } from 'shared/steps/types';
import { AppContextData, useAppContext } from './AppContext';


interface TuitionStepsState extends AppContextData { }

export const slugs = {
  student: 'student',
  parent: 'parent',
  address: 'address',
  additionalInfo: 'additional',
  payment: 'payment',
  paid: 'paid',
  review: 'review',
  submit: 'submit',
  finished: 'finished',
} as const;

export const buildTuitionLink = (slug: Slug) => `/${slug}`;

export const defaultSlug = slugs.student;
export const defaultPath = buildTuitionLink(slugs.student) as "/student";


type SlugKey = keyof typeof slugs;
type Slug = typeof slugs[SlugKey]

export const {isSlug, ...steps} = setupSteps<Slug, TuitionStepsState>({

  slugs: Object.values(slugs),

  mkLink: (slug, state) => `/${slug}`,

  getAppSteps: ({application: app}) => [
    {slug: slugs.student},
    {slug: slugs.parent,          enabled: app?.myLastApplicationId == null && (app?.students.length || 0) > 0},
    {slug: slugs.address,         enabled: app?.myLastApplicationId == null && !!app?.parentLastName},
    {slug: slugs.additionalInfo,  enabled: app?.myLastApplicationId == null && app?.residentialAddress !== null},
    {slug: slugs.payment,         enabled: app?.myLastApplicationId == null && app?.residentialAddress !== null},
    {slug: slugs.review,          enabled: app?.myLastApplicationId == null && !!app?.payment || app?.paymentSkipped || false},
    {slug: slugs.submit,          enabled: app?.myLastApplicationId == null && (app?.validationErrors.filter(e => !(e.__typename === 'TuitionApplicationFieldError' && e.field === 'acceptedAgreement')).length || 0) === 0},
    {slug: slugs.finished,        enabled: app?.myLastApplicationId !== null || app?.submitted !== null},
  ],
  
  mobileMenu: [
    {title: 'Student Details',  slugs: [slugs.student], hide: false, omit: state => state === null},
    {title: 'Parent Details',   slugs: [slugs.parent], hide: state => state === null},
    {title: 'Current Address',  slugs: [slugs.address]},
    {title: 'Additional Info',  slugs: [slugs.additionalInfo], omit: state => state === null},
    {title: 'Payment',          slugs: [slugs.payment, slugs.paid]},
    {title: 'Review',           slugs: [slugs.review]},
    {title: 'Submit',           slugs: [slugs.submit]},
    {title: 'Finished',         slugs: [slugs.finished], omit: state => state === null},
  ],

  desktopMenu: [
    {title: 'Student Details',  slugs: [slugs.student]},
    {title: 'Parent Details',   slugs: [slugs.parent, slugs.address, slugs.additionalInfo], hide: state => state === null},
    {title: 'Payment',          slugs: [slugs.payment, slugs.paid]},
    {title: 'Submit',           slugs: [slugs.review, slugs.submit, slugs.finished]},
  ],

});


interface UseTuitionStepsConfig {
  slug?: Slug
}


// USED EXTENSIVELY
export const useTuitionSteps = ({slug}: UseTuitionStepsConfig): UseSteps<Slug> => {
  const state = useAppContext();
  return steps.getNextAndPrevSteps(state, slug || null);
};


// USED 3x
export const useTuitionMenu = (): Menu<Slug> | null => {
  const state = useAppContext();
  const menu = steps.useMenu(state, '/:slug([-a-z]+)',);
  return menu;
}


export type TuitionStepProps = BaseStepProps<Slug>;
