import React from 'react';
import { LoadingLabel } from 'shared/components/Loading';
import { useParentQuery } from 'tuition/generated.graphql';
import { TuitionStepProps } from 'tuition/steps';
import { ParentForm } from './ParentForm';


export const Parent = (passThruProps: TuitionStepProps) => {
  const [result] = useParentQuery();
  if (result.data?.salutationSourceEnum && result.data?.tuition.myApplication) {
    return <ParentForm 
      {...passThruProps} 
      titleEnum={result.data?.salutationSourceEnum} 
      application={result.data?.tuition.myApplication}
    />
  }
  return <LoadingLabel/>
};
