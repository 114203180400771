import React from 'react';
import { FormErrors } from 'shared/components/FormErrors';
import { NextLink, PrevLink, SubmitButtons } from 'shared/components/SubmitButtons';
import { CreditCardFragment, NabCrnFragment, NabCustomerRecordFragment } from 'shared/generated.graphql';
import { CreditCard } from 'shared/payment/CreditCard';
import { CreditCardForm } from 'shared/payment/CreditCardForm';
import { SkipPaymentButton } from 'shared/payment/SkipPaymentButton';


interface Props {
  hasFailed: boolean
  card: CreditCardFragment | null
  prevStepLink: string | null
  nextStepLink: string | null
  skip: {
    execute: () => Promise<void>
    executing: boolean
  }
  getFingerprint: {
    execute: (data: {nameOnCard: string, submitted: boolean}) => Promise<NabCrnFragment | null>
    executing: boolean
  }
  children?: React.ReactNode
}


export const PaymentStep = (props: Props) => {
  const [showForm, setShowForm] = React.useState(props.card === null);

  return (
    <>

      {props.card && props.hasFailed && <>
        <FormErrors 
          title="Did something go wrong?" 
          errors={[{message: (
            <>
              There was an issue saving your payment details with our bank.<br/>
              Please try again below or skip to the next step and we will contact you for your card details later.<br/><br/>
              <SkipPaymentButton {...props.skip} />
            </>
          )}]}
        />
      </>}

      {showForm ? 
        <>
          {props.card && (
            <p className="">
              <button type="button" className="px-0 mx-0 btn btn-link" onClick={() => setShowForm(false)}>
                Use the card I already entered.
              </button>
            </p>
          )}
          <CreditCardForm 
            // Providing the rental specific code here, so that the <CreditCardForm/> can be reused
            prevStepLink={props.prevStepLink} 
            skip={props.skip}
            getFingerprint={props.getFingerprint}
          >
            {props.children}
          </CreditCardForm>
        </> 
      : 
        <>
          {props.card && <>
            <p>Payments will be taken from the following card</p>
            <CreditCard card={props.card}/>
          </>}
          <div>
            <button type="button" className="btn btn-link my-5" onClick={() => setShowForm(true)}>Use a different card</button>
            <SubmitButtons
              left={props.prevStepLink && <PrevLink to={props.prevStepLink}/>}
              right={props.nextStepLink && <NextLink to={props.nextStepLink}/>}
            />
          </div> 
        </>
      }

    </>
  )
};
