import React from 'react';
import { PageContainer } from 'shared/components/PageContainer';
import { defaultPath } from 'tuition/steps';
import { TuitionPageTitleHelmet } from './PageTitleHelmet';
// import { App_PortalFragment } from '../generated.graphql';


interface Props {
  // portal?: App_PortalFragment
}

export const PageNotFound = (props: Props) => (
  <PageContainer>
    <TuitionPageTitleHelmet>Page not found (404)</TuitionPageTitleHelmet>
    <div className="py-5 text-center">
      <div className="pb-3">Page not found (404)</div>
      <div className="">
        <a href={defaultPath} className="btn btn-primary">Start new application</a>
      </div>
    </div>
  </PageContainer>
);


