import React from 'react';


interface Props {
  execute: () => Promise<void>
  executing: boolean
}

export const SkipPaymentButton = (props: Props) => (
  <button 
    className="btn btn-light" 
    disabled={props.executing}
    onClick={props.execute}
  >
    {props.executing ? 'Skipping...' : 'Skip, contact me later'}
  </button>
);

