import cs from 'classnames';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { getFormikFieldServerErrors } from 'shared/forms/helpers';
import styles from './TextField.module.scss';


interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: string;
  blank?: boolean;
  valid?: boolean;
  custom?: boolean;
}
export const SelectFieldGroup = ({label, className, children, blank, custom, ...props}: Props) => {

  const [field, meta] = useField(props)

  const context = useFormikContext();
  const serverErrors = getFormikFieldServerErrors(context.status, field.name)
  
  return (
    <div className={cs("form-group", className, styles.FormField)}>
      <select 
        placeholder={label}
        className={cs(
          'form-control', 
          custom && 'custom-select', 
          meta.touched && (meta.error || serverErrors) && 'is-invalid', 
          props.valid && 'is-valid', 
          field.value ? styles.HasValue : styles.NoValue
        )}
        {...field}
        {...props}
        value={field.value || ""}
      >
        {blank && <option value="" disabled>{label}</option>}
        {children}
      </select>
      <label>{label}</label>
      {meta.touched && meta.error && 
        <div className="text-danger small mt-1">{meta.error}</div>
      }
    </div>
  )
}


interface EnumOptionsProps {
  enum?: null | {
    enumValues?: null | ReadonlyArray<{
      name: string
      description?: string | null
    }>
  }
  // options: ReadonlyArray<Pick<__EnumValue, 'name' | 'description' | 'isDeprecated'>>
}


export const EnumOptions = (props: EnumOptionsProps) => (
  <>
    {props.enum?.enumValues && props.enum.enumValues.map(choice => (
      <option key={choice.name} value={choice.name}>{choice.description}</option>
    ))}
  </>
)