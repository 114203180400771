import cs from 'classnames';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { getFormikFieldServerErrors } from 'shared/forms/helpers';
import styles from './TextField.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  whenNull?: string
  // Manually specify an error outside of Formik, eg, from a server query
  error?: string
  // Set this to true to show green "is-valid" feedback
  valid?: boolean
  prepend?: React.ReactChild | React.ReactChildren;
  append?: React.ReactChild | React.ReactChildren;
}

export const TextFieldGroup = ({label, className, whenNull, valid, prepend, append, ...props}: Props) => {

  const [field, meta] = useField(props)

  const showError = props.error || !!(meta.touched && meta.error);
  const context = useFormikContext();
  const serverErrors = getFormikFieldServerErrors(context.status, field.name)
  
  let input = (
    <>
      <input 
        placeholder={label}
        // className={cs('form-control', meta.touched && (meta.error ? 'is-invalid' : ('is-valid')))}
        className={cs(
          'form-control', 
          (showError || serverErrors) && 'is-invalid', 
          // TODO: Set 'is-valid' className based on what we want to do
          //       Currently this is once they have submitted the errors and server side errors have come back
          // meta.touched && !meta.error && field.value && !props.disabled && 'is-valid'
          valid && 'is-valid', 
          
        )}
        {...field}
        {...props}
        value={(whenNull !== undefined && field.value === null) ? whenNull: field.value}
      />
      <label>{label}</label>
    </>
  );
  
  if (prepend || append) {
    input = (
      <div className="input-group">
        {prepend && (
          <div className="input-group-prepend">
            {prepend}
          </div>
        )}
        {input}
        {append && (
          <div className="input-group-append">
            {append}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={cs("form-group", className, styles.FormField)}>
      {input}
      {showError && 
        <div className="text-danger small mt-1">{props.error || meta.error}</div>
      }
    </div>
  )
}
