import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageContainer } from 'shared/components/PageContainer';
import { NextLink, PrevLink, SubmitButtons } from 'shared/components/SubmitButtons';
import { PageHeading } from 'shared/components/typography';
import { slugs, useTuitionSteps } from 'tuition/steps';
import { TuitionApplicationFragment } from '../../generated.graphql';


interface Props {
  application: TuitionApplicationFragment;
}

export const Paid = (props: Props) => {
  // Using payment slug, since this is just a sub page of the payment step
  const steps = useTuitionSteps({slug: slugs.payment})
  const history = useHistory();

  // Auto Redirect to next step
  // In useEffect since react complains if it redirects before it finishes the render
  React.useEffect(() => {
    if (props.application.payment !== null && steps.next) {
      history.replace(steps.next?.link)
    }
  }, [props.application.payment, steps.next?.link])
  
  return (
    <PageContainer>
      <PageHeading>Checking payment details</PageHeading>
      {props.application.payment?.maskedCardNumber && (
        <>
          <div>Card details successfully stored </div>
          <SubmitButtons
            // Using steps.this, because the prev link needs to link to the this payment step, not the prev
            left={steps.this && <PrevLink to={steps.this.link}/>}
            right={steps.next && <NextLink to={steps.next.link}/>}
          />
        </>
      )}
    </PageContainer>
  )
};
