import React from 'react';
import { PageContainer } from 'shared/components/PageContainer';
import { PageHeading } from 'shared/components/typography';
import { PaymentStep } from 'shared/payment/PaymentStep';
import { slugs, TuitionStepProps, useTuitionSteps } from 'tuition/steps';
import { TuitionPageTitleHelmet } from 'tuition/components/PageTitleHelmet';
import { useSkipTuitionPaymentMutation, TuitionApp_PaymentFragment, useInitiateTuitionDirectPostMutation, TuitionErrorType } from '../../generated.graphql';
import { createErrorFilter } from '../fields';


interface Props extends TuitionStepProps {
  application: TuitionApp_PaymentFragment;
}

export const paymentErrorFilter = createErrorFilter([], [
  TuitionErrorType.PaymentDeclined, 
  TuitionErrorType.PaymentMissing,
]);

export const Payment = (props: Props) => {
  const step = useTuitionSteps({slug: slugs.payment});
  const [skipPaymentStatus, skipPayment] = useSkipTuitionPaymentMutation();
  const [getFingerprintStatus, getFingerprint] = useInitiateTuitionDirectPostMutation();

  return (
    <PageContainer>
      <TuitionPageTitleHelmet>Payment</TuitionPageTitleHelmet>
      <PageHeading>Payment</PageHeading>
      <p className='mb-4'>
      Please provide your credit card details below. 
      Payment dates are dependent on the date your child is enrolled and can differ between states and schools. 
      Your credit card will usually be charged the week your child commences lessons. 
      If you cancel during the two-week trial period, you will receive a refund. 
      Once you have enrolled, you will receive an email confirming the date your payment will be processed.
      </p>
      {props.application.id && 
        <PaymentStep
          {...props}
          nextStepLink={step.next?.link || null}
          prevStepLink={step.prev?.link || null}
          card={props.application.payment || null}
          skip={{
            execute: async () => {
              await skipPayment();
              props.onSubmitSuccessful();
            }, 
            executing: skipPaymentStatus.fetching,
          }}
          getFingerprint={{
            execute: async input => {
              if (props.application.id) {
                const r = await getFingerprint({input: {...input, applicationId: props.application.id}}); 
                return r.data?.initiateTuitionDirectPost?.nabCrn || null;
              }
              return null;
            },
            executing: getFingerprintStatus.fetching,
          }}
          hasFailed={props.application.hasDirectPostFailed}
        />
      }
    </PageContainer>
  )
};
