import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { PageContainer } from 'shared/components/PageContainer';
import { PageHeading } from 'shared/components/typography';
import { TuitionPageTitleHelmet } from 'tuition/components/PageTitleHelmet';

interface Props {
}

export const Finished = (props: Props) => (
  <PageContainer>
    <TuitionPageTitleHelmet>Submitted Successfully</TuitionPageTitleHelmet>
    <PageHeading className="text-center mt-5">Thank You!</PageHeading>
    <p className="text-center my-5">Your application has been successfully submitted.</p>
    <p className="text-center">
      <span className="fa-stack fa-4x">
        <FontAwesomeIcon icon={faCircle} className="fa-stack-2x" color="#28a745" />
        <FontAwesomeIcon icon={faCheck} className="fa-stack-1x" inverse={true} />
      </span>
    </p>
  </PageContainer>
);
