import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { StepController } from 'shared/steps/StepController';
import { PageNotFound } from './components/PageNotFound';
import { TuitionApplicationFragment } from './generated.graphql';
import { isSlug, slugs, useTuitionSteps } from './steps';
import { AdditionalInfo } from './steps/additional/AdditionalInfo';
import { Address } from './steps/address/Address';
import { Parent } from './steps/parent/Parent';
import { Payment } from './steps/payment/Payment';
import { Review } from './steps/review/Review';
import { StudentsForm } from './steps/student/StudentsForm';
import { Agreement } from './steps/submit/Agreement';


interface Props {
  application: TuitionApplicationFragment
}

export const AppContent = (props: Props) => {

  const match = useRouteMatch<{slug: string}>('/:slug')
  const matchedSlug = match?.params.slug || '';
  const steps = useTuitionSteps({slug: isSlug(matchedSlug) ? matchedSlug : slugs.student});
  
  if (!steps.this) 
    return <PageNotFound/>;
  
  return (
    <StepController
      steps={{...steps, this: steps.this}}  // Being explicit since this cannot be null
      {...props}
    >

      {stepProps => (
        <Switch>
          <Route path={steps.bySlug.student.link}><StudentsForm {...{...props, ...stepProps}}/></Route>
          <Route path={steps.bySlug.parent.link}><Parent {...{...props, ...stepProps}}/></Route>
          <Route path={steps.bySlug.address.link}><Address {...{...props, ...stepProps}}/></Route>
          <Route path={steps.bySlug.additional.link}><AdditionalInfo {...{...props, ...stepProps}}/></Route>
          <Route path={steps.bySlug.payment.link}><Payment {...{...props, ...stepProps}}/></Route>
          <Route path={steps.bySlug.review.link}><Review {...{...props, ...stepProps}}/></Route>
          <Route path={steps.bySlug.submit.link}><Agreement {...{...props, ...stepProps}}/></Route>
          <Route>
            <PageNotFound/>
          </Route>
        </Switch>
      )}
    </StepController>
  )
};