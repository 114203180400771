import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import React from 'react';
import { useMedia } from 'react-media';
import { bsClasses } from 'shared/bootstrap';
import { GRID_MEDIA_QUERIES } from 'shared/media';
import { Menu } from 'shared/steps/steps';
import styles from './Banner.module.scss';
import { HelpPanel } from './HelpPanel';
import { StepsMenu } from './StepsMenu';


interface Props<TSlug extends string> {
  logoLink: string
  logoSrc: string | null
  logoTitle: string
  children?: React.ReactNode
  processingTimeMessage: string
  menu: Menu<TSlug> | null
}


export const Banner = <TSlug extends string>(props: Props<TSlug>) => {
  const [helpOpen, setHelpOpen] = React.useState<boolean>(false);
  const isDesktop = useMedia({query: GRID_MEDIA_QUERIES.mdGTE});

  const img = props.logoSrc ? 
    <img src={props.logoSrc} alt={props.logoTitle} title={props.logoTitle} className="img-fluid"/>
      :
      props.logoTitle;

  return (
    <header>
      <div className="navbar navbar-light fixed-top p-0">
        <div className="bg-light border-bottom shadow w-100">
          {props.children}
          
          <div className={cs(bsClasses.container, "pt-3 pb-3 pl-3 pr-0")}>
            <div className={cs(styles.PortalLogo, "mr-auto")}>
              {props.logoLink ? <a href={props.logoLink} title={props.logoTitle} className="d-block">{img}</a> : img }
            </div>
            <div className={styles.NavItemsContainer}>
              <StepsMenu menu={props.menu}/>
              <div className="border-left">
                <button type="button" className="btn btn-link text-decoration-none px-2 mr-1 px-sm-4" aria-haspopup="true" aria-expanded="false" onClick={() => setHelpOpen(!helpOpen)}>
                  <FontAwesomeIcon icon={faQuestionCircle} className="d-sm-none" />
                  <span className="d-none d-sm-inline">Help</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {helpOpen &&
          <div className={cs(bsClasses.container, "p-0 justify-content-end")}>
            <div className={cs("bg-light shadow", isDesktop && "w-50")}>
              <HelpPanel onDismiss={() => setHelpOpen(false)}>{props.processingTimeMessage}</HelpPanel>
            </div>
          </div>
        }
      </div>
    </header>
  )
};
