import { Form, FormikContext, useFormik } from 'formik';
import React from 'react';
import { MutationErrors } from 'shared/components/MutationErrors';
import { PageContainer } from 'shared/components/PageContainer';
import { SubmitActions } from 'shared/components/SubmitActions';
import { PageHeading } from 'shared/components/typography';
import { DateTimeField } from 'shared/formik/DateTimeField';
import { EnumOptions, SelectFieldGroup } from 'shared/formik/SelectFieldGroup';
import { TextFieldGroup } from 'shared/formik/TextFieldGroup';
import { getSubmitActionsProps } from 'shared/forms/helpers';
import { yupMobilePhoneNumber, yupRequiredMsg } from 'shared/yup';
import { EnumChoicesTypeInfoFragment, ParentFragment, TuitionErrorField, UpdateTuitionApplicationInput, useUpdateApplicationMutation } from 'tuition/generated.graphql';
import { TuitionStepProps } from 'tuition/steps';
import * as Yup from 'yup';
import { TuitionPageTitleHelmet } from '../../components/PageTitleHelmet';
import { createErrorFilter, parseErrors } from '../fields';

interface Props extends TuitionStepProps {
  application: ParentFragment
  titleEnum: EnumChoicesTypeInfoFragment
}

interface Values extends Partial<UpdateTuitionApplicationInput> {
}

const getYearsAgoDate = (nYears: number) => {
  const d = new Date();
  d.setFullYear(d.getFullYear()-nYears);
  return d;
}

const validationSchema = Yup.object({
  parentTitle: Yup.string().nullable().required(yupRequiredMsg),
  parentFirstName: Yup.string().required(yupRequiredMsg),
  parentLastName: Yup.string().required(yupRequiredMsg),
  parentEmail: Yup.string().email('Enter a valid email address.').required(yupRequiredMsg),
  parentPhoneMobile: yupMobilePhoneNumber.required(yupRequiredMsg),
  parentDob: Yup.date().required("Date of birth must be a valid date").max(getYearsAgoDate(14), 'This should be the parents date of birth.'),
});

export const parentErrorFilter = createErrorFilter([
  TuitionErrorField.ParentTitle,
  TuitionErrorField.ParentFirstName,
  TuitionErrorField.ParentLastName,
  TuitionErrorField.ParentEmail,
  TuitionErrorField.ParentPhoneMobile,
  TuitionErrorField.ParentDob,
], []);


export const ParentForm = (props: Props) => {

  const [, executeMutation] = useUpdateApplicationMutation();

  const a = props.application;
  const initialValues = {
    parentTitle: a.parentTitle || '',
    parentFirstName: a.parentFirstName || '',
    parentLastName: a.parentLastName || '',
    parentEmail: a.parentEmail || '',
    parentPhoneMobile: a.parentPhoneMobile || '',
    parentDob: a.parentDob || '',
  };

  const formik = useFormik<Values>({
    initialValues,
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: async (values, formikHelpers) => {
      const r = await executeMutation({
        input: {
          parentTitle: values.parentTitle || '',
          parentFirstName: values.parentFirstName || '',
          parentLastName: values.parentLastName || '',
          parentEmail: values.parentEmail || '',
          parentPhoneMobile: values.parentPhoneMobile || '',
          parentDob: values.parentDob || '',
        }
      });
      const m = r.data?.updateTuitionApplication;
      if (m?.application) {
        props.onSubmitSuccessful();
      }
      else {
        const status = parseErrors(
          [
            ...m?.errors || [],
            ...m?.application?.validationErrors.filter(parentErrorFilter) || []
          ] || null);
        formikHelpers.setStatus(status);
          
        props.onSubmitFailure();
        window.scrollTo(0, 0);
      }
      
    }
  });

  return (
  
    <PageContainer>
      <TuitionPageTitleHelmet>Parent Details</TuitionPageTitleHelmet>
      <PageHeading>Parent Details</PageHeading>

      <FormikContext.Provider value={formik}>

        <Form noValidate>

          {formik.status && <MutationErrors errors={formik.status}/>}
          
          <div className="my-4">

            <div className="form-row">
              <div className="col-sm">
                <div className="form-row">
                  <SelectFieldGroup name="parentTitle" label="Parent Title" blank={true} custom className="col-auto">
                    <EnumOptions enum={props.titleEnum} />
                  </SelectFieldGroup>
                  <TextFieldGroup label="Parent first name" name="parentFirstName" className="col col-sm"/>
                </div>
              </div>
              <TextFieldGroup label="Parent last name" name="parentLastName" className="col-sm"/>
            </div>

            <div className="form-row">
              <TextFieldGroup label="Parent email" name="parentEmail" type="email" className="col-sm" formNoValidate autoComplete="off" />
              <TextFieldGroup label="Parent mobile number" name="parentPhoneMobile" type="tel" pattern="\d*" formNoValidate className="col-sm" />
            </div>

            <div className="form-row">
              <div className="col-md-6">
                <DateTimeField label="Parent date of birth." name="parentDob" whenNull="" />
                <div className='text-muted small'>Date of birth will only be used to verify your identify if you opt to use our online account portal.</div>
              </div>
            </div>

          </div> 

          <SubmitActions {...getSubmitActionsProps(formik, props)}/>

        </Form>
      </FormikContext.Provider>
      
    </PageContainer>
  )
};